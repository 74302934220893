import {
  __,
  constants,
  EventTrack,
  LENGTH_UNIT,
  modalActions,
  notificationsActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  WEIGHT_UNIT,
} from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';

import * as userWebActions from '../../../actions/user';
import { Ribbon, Select } from '../../atoms';
import { IFontIconKeys } from '../../atoms/FontIcon';
import { FormContainer, FormMenu, FormSection, InputWithLabel } from '../../molecules';
import { highlightFormSection, Sections } from '../../molecules/FormMenu/FormMenu.component';
import Workspace from '../Workspace/Workspace.component';
import {
  AddressPreferences,
  CurrencyPreferences,
  OrderPreferences,
  ProductPreferences,
  ShippingPreferences,
  ShopOnline,
  TeamSection,
} from './Fragments';
import OfferPreferences from './Fragments/OfferPreferences';
import * as S from './WorkspaceSettingsSeller.styled';

export interface IStateProps {
  addresses: Array<IAddress>;
  catalog: IWorkspace;
  contacts: { [contactId: number]: IContact };
  countries: ICountries;
  me: IUser;
}

export interface IDispatchProps {
  catalogUpdate: typeof sellerWorkspaceActions.catalogUpdate;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  supportAction: typeof userWebActions.supportAction;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<{ workspaceId: string; tab?: string }>;

interface IState {
  errors: Map<string, string | (() => JSX.Element)>;
  hasChanges: boolean;
  newCatalog: IWorkspace;
  newRole: ICatalogRole;
  viewing?: string;
}

class WorkspaceSettings extends React.PureComponent<IProps, IState> {
  private t: number;
  private sectionsRef: React.RefObject<HTMLDivElement> = React.createRef();
  private teamRef: React.RefObject<HTMLDivElement> = React.createRef();
  private shopOnlineRef: React.RefObject<HTMLDivElement> = React.createRef();
  private currencyRef: React.RefObject<HTMLDivElement> = React.createRef();
  private productRef: React.RefObject<HTMLDivElement> = React.createRef();
  private addressesRef: React.RefObject<HTMLDivElement> = React.createRef();
  private shippingRef: React.RefObject<HTMLDivElement> = React.createRef();
  private orderRef: React.RefObject<HTMLDivElement> = React.createRef();
  private offerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    this.state = {
      errors: new Map(),
      newCatalog: props.catalog,
      hasChanges: false,
      viewing: props.match.params?.tab || 'team',
      newRole: 'viewer',
    };
  }

  public componentDidMount() {
    RenderTrack.track('WorkspaceSettingsSeller', { renderTime: this.t });
    const offset = 200;
    // Manage scroll
    if (this.sectionsRef.current) {
      this.sectionsRef.current.addEventListener('scroll', () => {
        if (this.sectionsRef.current?.scrollTop + offset >= this.offerRef?.current?.offsetTop) {
          this.setState({ viewing: 'offer' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.orderRef?.current?.offsetTop) {
          this.setState({ viewing: 'order' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.shippingRef?.current?.offsetTop) {
          this.setState({ viewing: 'shipping' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.addressesRef?.current?.offsetTop) {
          this.setState({ viewing: 'addresses' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.productRef?.current?.offsetTop) {
          this.setState({ viewing: 'product' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.currencyRef?.current?.offsetTop) {
          this.setState({ viewing: 'currency' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.shopOnlineRef?.current?.offsetTop) {
          this.setState({ viewing: 'shop_online' });
        } else if (this.sectionsRef.current?.scrollTop + offset >= this.teamRef?.current?.offsetTop) {
          this.setState({ viewing: 'team' });
        }
      });

      const { viewing } = this.state;
      if (viewing !== 'team') {
        const el = document.getElementById(viewing);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        highlightFormSection(viewing);
      }
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { match, catalog } = this.props;
    const { viewing } = this.state;

    if (!prevProps.catalog && catalog) {
      this.setState({ newCatalog: catalog });
    }

    // If URL changes, we scroll to the correct section
    if (match !== prevProps.match) {
      const newViewing = match.params.tab || 'team';
      if (newViewing !== viewing) {
        this.setState({ viewing: newViewing });
        const el = document.getElementById(newViewing);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
        highlightFormSection(newViewing);
      }
    }
  }

  public render() {
    const { addresses, catalog, contacts, countries, history, location, match, me } = this.props;
    const { errors, hasChanges, newCatalog, viewing } = this.state;
    const role = sellerWorkspaceService.getRole(catalog, me.id);
    const amEditor = catalog && role !== 'viewer';
    const isPro = catalog ? sellerWorkspaceService.isProPlan(catalog.plan) : false;
    const sections: Sections = [
      {
        id: 'team',
        title: __('WorkspaceSettings.Menu.Team.title'),
        icon: 'Catalog',
      },
      {
        id: 'shop_online',
        title: __('WorkspaceSettings.Menu.ShopOnline.title'),
        icon: 'Shop-online',
      },
      {
        id: 'currency',
        title: __('WorkspaceSettings.Menu.Currency.title'),
        icon: 'Currency',
      },
      {
        id: 'product',
        title: __('WorkspaceSettings.Menu.Product.title'),
        icon: 'Piece' as IFontIconKeys,
      },
      {
        id: 'addresses',
        title: __('WorkspaceSettings.Menu.PickupAddresses.title'),
        icon: 'Address',
      },
      {
        id: 'shipping',
        title: __('WorkspaceSettings.Menu.ShippingOrder.title'),
        icon: 'Pickup-truck',
        alertIcon:
          newCatalog && ((newCatalog.cutoffTime !== null && newCatalog.estimatedTransportTime !== null) || !amEditor)
            ? undefined
            : 'Error',
      },
      {
        id: 'order',
        title: __('WorkspaceSettings.Menu.Order.title'),
        icon: 'Unread-orders',
      },
      {
        id: 'offer',
        title: __('WorkspaceSettings.Menu.Offer.title'),
        icon: 'Unread-orders',
      },
    ];

    return (
      <>
        <Workspace
          subtitle={''}
          title={__('Components.Header.WorkspaceSettings')}
          tabSelected="settings-seller"
          workspaceId={catalog?.id}
        >
          <Prompt when={hasChanges} message={__('Components.ProductDetails.confirm_exit_changes')} />
          {!amEditor ? <Ribbon type="info" text={__('WorkspaceClientEdit.read_only')} /> : null}
          <S.Container>
            <FormMenu sections={sections} selected={viewing} />
            <FormContainer
              className="TeamSetting-FormContainer"
              canSave={hasChanges && !errors.size}
              save={this.onCTAClick}
              sectionsRef={this.sectionsRef}
            >
              {!newCatalog ? null : (
                <>
                  <TeamSection
                    sectionRef={this.teamRef}
                    editMode={amEditor}
                    catalog={newCatalog}
                    catalogName={sellerWorkspaceService.getCatalogName(newCatalog, contacts, me)}
                    errors={errors}
                    updateCatalog={this.updateCatalog}
                    setError={this.setError}
                    contacts={contacts}
                    me={me}
                    role={role}
                  />
                  <ShopOnline
                    contacts={contacts}
                    sectionRef={this.shopOnlineRef}
                    editMode={amEditor}
                    me={me}
                    catalog={newCatalog}
                    updateCatalog={this.updateCatalog}
                  />
                  <CurrencyPreferences
                    catalog={newCatalog}
                    sectionRef={this.currencyRef}
                    updateCatalog={this.updateCatalog}
                    editMode={amEditor}
                    errors={errors}
                    setError={this.setError}
                  />
                  <ProductPreferences
                    workspace={newCatalog}
                    myId={me.id}
                    sectionRef={this.productRef}
                    updateCatalog={this.updateCatalog}
                    editMode={amEditor}
                    errors={errors}
                  />
                  <AddressPreferences
                    addresses={addresses}
                    addressesRef={this.addressesRef}
                    amEditor={amEditor}
                    me={me}
                    countries={countries}
                    workspace={newCatalog}
                  />
                  <ShippingPreferences
                    shippingRef={this.shippingRef}
                    amEditor={amEditor}
                    updateCatalog={this.updateCatalog}
                    workspace={newCatalog}
                    me={me}
                  />
                  <OrderPreferences
                    isPro={isPro}
                    catalog={newCatalog}
                    editMode={amEditor}
                    isLast={false}
                    match={match}
                    myId={me.id}
                    sectionRef={this.orderRef}
                    updateCatalog={this.updateCatalog}
                    workspaceId={newCatalog?.id}
                    supportAction={this.props.supportAction}
                  />
                  <OfferPreferences
                    isPro={isPro}
                    catalog={newCatalog}
                    editMode={amEditor}
                    isLast={true}
                    sectionRef={this.offerRef}
                    updateCatalog={this.updateCatalog}
                  />
                </>
              )}
            </FormContainer>
          </S.Container>
        </Workspace>
      </>
    );
  }

  /**
   * Set error for a particular field represented by key
   */
  private setError = (key: string, error: string | (() => JSX.Element)) => {
    const cloneErrors = new Map(this.state.errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors });
  };

  /**
   * Change the local state of user with the changes in the form.
   */
  private updateCatalog = (catalog: IWorkspace, hasChanges = true) => {
    this.setState({
      hasChanges,
      newCatalog: catalog,
    });
  };

  /**
   * On CTA click
   */
  private onCTAClick = () => {
    const { catalogUpdate, me, notificationShow } = this.props;
    const { newCatalog } = this.state;
    EventTrack.track('workspace_settings_update', { type: 'seller', workspace_id: newCatalog.id });
    catalogUpdate(
      me.id,
      {
        ...newCatalog,
      },
      error => {
        if (error) {
          return notificationShow({
            title: __('WorkspaceSettings.error'),
            subtitle: __('WorkspaceSettings.error_description'),
            closable: true,
            style: 'error',
          });
        }
        return notificationShow({
          title: __('WorkspaceSettings.success'),
          subtitle: __('WorkspaceSettings.success_description'),
          closable: true,
          style: 'info',
        });
      },
    );
    this.setState({ hasChanges: false });
  };
}

export default WorkspaceSettings;

const Measures = React.memo(
  ({
    catalog,
    editMode,
    errors,
    sectionRef,
    updateCatalog,
  }: {
    catalog: IWorkspace;
    editMode: boolean;
    errors: Map<string, string | (() => JSX.Element)>;
    sectionRef: React.RefObject<HTMLDivElement>;
    updateCatalog: (catalog: IWorkspace) => void;
  }) => {
    const dispatch = useDispatch();
    const { defaultWeightUnit, lengthUnit } = catalog;
    return (
      <FormSection sectionRef={sectionRef} id="measures" title={__('WorkspaceSettings.Menu.Measures.title')}>
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.weight_unit.label')}
          description={__('Components.Settings.weight_unit.description')}
          disabled={!editMode}
        >
          <Select
            name="default_weight_unit"
            value={defaultWeightUnit}
            options={Object.values(constants.WEIGHT).map(l => ({
              value: l.code,
              label: l.code + ' - ' + l.name(),
            }))}
            onChange={onChangeDefaultWeightUnit}
            disabled={!editMode}
            containerMargin="4px 0"
            width="40%"
            hasError={!!errors.get('default_weight_unit')}
          />
        </InputWithLabel>
        <InputWithLabel
          isRequired={true}
          label={__('Components.Settings.length_unit.label')}
          description={__('Components.Settings.length_unit.description')}
          disabled={!editMode}
        >
          <Select
            name="length_unit"
            value={lengthUnit}
            options={Object.values(constants.LENGTH).map(l => ({
              value: l.code,
              label: l.code + ' - ' + l.name(),
            }))}
            onChange={onChangeDefaultLengthUnit}
            disabled={!editMode}
            containerMargin="4px 0"
            width="40%"
            hasError={!!errors.get('length_unit')}
          />
        </InputWithLabel>
      </FormSection>
    );

    // onChangeDefaultWeightUnit manage the main weight unit change
    // A modal will be show to the user in order to let them understand the consequences
    function onChangeDefaultWeightUnit(n: string, v: string): void {
      if (v !== defaultWeightUnit) {
        dispatch(
          modalActions.modalOpen(
            __('Components.Settings.weight_unit.modal.title'),
            () => {
              updateCatalog({
                ...catalog,
                defaultWeightUnit: v as WEIGHT_UNIT,
              });
              dispatch(modalActions.modalClose());
            },
            {
              text2: __('Components.Settings.weight_unit.modal.description'),
              buttonText: __('Components.Settings.weight_unit.modal.cta'),
              showCancelButton: true,
              buttonCancelText: __('Components.Settings.weight_unit.modal.cancel'),
              closeable: false,
            },
            'nice',
          ),
        );
      }
    }

    // onChangeDefaultLengthUnit manage the main weight unit change
    // A modal will be show to the user in order to let them understand the consequences
    function onChangeDefaultLengthUnit(n: string, v: string): void {
      if (v !== lengthUnit) {
        dispatch(
          modalActions.modalOpen(
            __('Components.Settings.length_unit.modal.title'),
            () => {
              updateCatalog({
                ...catalog,
                lengthUnit: v as LENGTH_UNIT,
              });
              dispatch(modalActions.modalClose());
            },
            {
              text2: __('Components.Settings.length_unit.modal.description'),
              buttonText: __('Components.Settings.length_unit.modal.cta'),
              showCancelButton: true,
              buttonCancelText: __('Components.Settings.length_unit.modal.cancel'),
              closeable: false,
            },
            'nice',
          ),
        );
      }
    }
  },
);

const Prices = React.memo(
  ({
    catalog,
    editMode,
    sectionRef,
    updateCatalog,
  }: {
    catalog: IWorkspace;
    editMode: boolean;
    sectionRef: React.RefObject<HTMLDivElement>;
    updateCatalog: (catalog: IWorkspace) => void;
  }) => {
    const { numberOfDecimalsShowed } = catalog;
    return (
      <FormSection sectionRef={sectionRef} id="prices" title={__('WorkspaceSettings.Menu.Prices.title')}>
        <InputWithLabel
          isRequired={true}
          label={__('WorkspaceSettings.Decimals.label')}
          description={__('WorkspaceSettings.Decimals.description')}
          disabled={!editMode}
          footerText={getFooterText(numberOfDecimalsShowed)}
        >
          <Select
            name="number_of_decimals_showed"
            value={numberOfDecimalsShowed.toString()}
            options={['2', '3'].map(l => ({
              value: l,
              label: `${l} ${__('WorkspaceSettings.Decimals.decimal', { count: Number(l) })}`,
            }))}
            onChange={(n, v) => onChangeNumOfDecimals(v)}
            disabled={!editMode}
            containerMargin="4px 0"
            width="40%"
          />
        </InputWithLabel>
      </FormSection>
    );

    // onChangeNumOfDecimals manage the number of decimals showed setting
    function onChangeNumOfDecimals(v: string): void {
      if (v !== numberOfDecimalsShowed.toString()) {
        updateCatalog({
          ...catalog,
          numberOfDecimalsShowed: Number(v),
        });
      }
    }
    // getFooterText returns the example test according to num of decimals in settings
    function getFooterText(v: number): string {
      if (v === 2) {
        return __('WorkspaceSettings.Decimals.footer2');
      } else {
        return __('WorkspaceSettings.Decimals.footer3');
      }
    }
  },
);
