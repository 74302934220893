import { __, EventTrack, RenderTrack, UserExistsCode, utils, validations } from 'common-services';
import { AsYouType } from 'libphonenumber-js/mobile';
import * as React from 'react';

import { auth, signInWithFacebook, signInWithGoogle } from '../../../../../../setupFcm';
import { IMAGES } from '../../../../../assets';
import {
  getCountriesSelect,
  getTermsLink,
  getTermsLinkByLanguage,
  REGISTER_INFO_STEPS,
  REGISTER_STEPS,
} from '../../../../../constants';
import theme from '../../../../../theme';
import { Error, Input, Select } from '../../../../atoms';
import { PasswordInput } from '../../../../molecules';

import * as S from './WelcomeLoginRegister.styled';

import type * as navActions from '../../../../../actions/nav';
import type * as webUserActions from '../../../../../actions/user';
import type { ILoginData } from '../../../../../constants';
import type { contactActions, IIsEmailValidAPIResponse, ICountry, IGlobalWeb, userActions } from 'common-services';
import type { CountryCode } from 'libphonenumber-js/mobile';

declare let global: IGlobalWeb;

interface IProps {
  addContactByPricelistUrl: typeof contactActions.addContactByPricelistUrl;
  contactUrl?: string;
  countries: { [key: string]: ICountry };
  email?: string;
  getTwoFactorVerificationCode: (lastFourDigitsPhone?: string) => void;
  inModal: boolean;
  isOauth: boolean;
  isRegister: boolean;
  lang?: LOCALE;
  loading: boolean;
  loginData: ILoginData;
  mode: 'phone' | 'email';
  navAuthAction: typeof navActions.navAuthAction;
  redirect?: (myId?: number) => void;
  resetLoginData: () => void;
  resetUser: typeof webUserActions.resetUser;
  setParentState: (state: any) => void;
  setRegisterInfo: typeof webUserActions.setRegisterInfo;
  updateLoginData: (name: string, value: string | number) => void;
  userExists: typeof userActions.userExists;
  isEmailValid: typeof userActions.isEmailValid;
  verifyEmailCode: typeof webUserActions.verifyEmailCode;
  verifyPhone: typeof webUserActions.verifyPhone;
  webloginOauth: typeof webUserActions.webloginOauth;
  webloginPassword: typeof webUserActions.webloginPassword;
}

interface IState {
  countriesSelect: Array<{ label: string; value: string }>;
  errors: Map<string, string>;
  textError: string;
}

/**
 * Login phone or email and redirect to the next step according if:
 * 1. User doesn't exist
 * 2. User exists and doesn't have password
 * 3. User exists and have password
 */
export default class WelcomeLoginRegister extends React.PureComponent<IProps, IState> {
  private constructedAt: number;

  constructor(props: IProps) {
    super(props);
    this.state = {
      countriesSelect: getCountriesSelect(props.countries),
      errors: new Map(),
      textError: '',
    };
    this.constructedAt = Date.now();
  }

  public componentDidMount() {
    const { setParentState } = this.props;
    RenderTrack.track('WelcomeLoginRegister', { renderTime: this.constructedAt });

    // logout oauth if previously logged in
    if (auth?.currentUser?.email) {
      setParentState({ oauthtoken: '' });
      auth.signOut();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { countries, email, isRegister, loginData, mode, isOauth } = this.props;
    if (prevProps.countries !== countries) {
      this.setState({ countriesSelect: getCountriesSelect(countries) });
    }
    if (
      prevProps.loginData.countryCode !== loginData.countryCode ||
      prevProps.loginData.phoneNumber !== loginData.phoneNumber
    ) {
      if (loginData.countryCode && loginData.phoneNumber)
        this.setState({ ...this.validatePhoneLogin(loginData, isRegister) });
    }
    if (prevProps.mode !== mode || prevProps.isRegister !== isRegister) {
      this.setState(
        mode === 'phone'
          ? this.validatePhoneLogin(loginData, isRegister)
          : this.validateEmailForm(email, loginData.password, isRegister, isOauth),
      );
    }
  }

  public render() {
    const { email, isRegister, lang, loading, loginData, mode, resetUser, setParentState, updateLoginData } =
      this.props;
    const { errors, textError } = this.state;

    return (
      <S.Container>
        <form
          id="login_phone_email"
          onSubmit={e => {
            e.preventDefault();
            this.onSubmit();
          }}
        >
          <S.Card id="onboarding-welcome-login-register">
            {isRegister ? <S.PreTitle>{__('WelcomeRegister.pretitle', { locale: lang })}</S.PreTitle> : null}
            <S.Title>
              {isRegister ? __('WelcomeRegister.title', { locale: lang }) : __('WelcomeLogin.title', { locale: lang })}
            </S.Title>
            <S.SubTitle>
              {
                utils.formatText(
                  __(isRegister ? 'WelcomeRegister.subtitle' : 'WelcomeLogin.subtitle', { locale: lang }),
                  s => (
                    <S.Link
                      key={s}
                      onClick={() => {
                        setParentState({ isRegister: !isRegister });
                        if (!isRegister) resetUser();
                      }}
                    >
                      {s}
                    </S.Link>
                  ),
                ) as unknown as JSX.Element
              }
            </S.SubTitle>
            <S.Tabs
              selected={mode}
              tabs={[
                {
                  id: 'phone',
                  label: __('WelcomeRegister.phone', { locale: lang }),
                  action: () => setParentState({ mode: 'phone' }),
                },
                {
                  id: 'email',
                  label: __('WelcomeRegister.email', { locale: lang }),
                  action: () => setParentState({ mode: 'email' }),
                },
              ]}
              history={{ push: () => null }}
              tabTextStyle={{ fontSize: theme.fontSize.normal }}
              tabStyle={{ fontSize: theme.fontSize.normal, minHeight: 28, maxHeight: 28, marginLeft: 0 }}
            />
            <S.InputContainer>{mode === 'phone' ? this.renderPhoneInput() : this.renderEmailInput()}</S.InputContainer>
            {errors.size > 0 && textError ? (
              <Error
                text={textError}
                action={() => setParentState({ isRegister: !isRegister })}
                link={
                  errors.get('phoneNumber') === 'user_not_exists'
                    ? __('WelcomeLogin.user_not_exists_link', { locale: lang })
                    : null
                }
              />
            ) : null}
            <S.InputContainer>
              <S.Label>
                {isRegister
                  ? __('WelcomeRegister.password_label', { locale: lang })
                  : __('WelcomeLogin.password_label', { locale: lang })}
              </S.Label>
              <PasswordInput
                className="reset-password-input"
                hasError={!!(errors.get('password') || textError)}
                lang={lang}
                isRegister={isRegister}
                password={loginData.password}
                setError={err => {
                  if (isRegister) this.setError('password', err);
                }}
                updatePassword={val => updateLoginData('password', val || '')}
              />
            </S.InputContainer>
            {isRegister ? null : this.renderForgetPassword()}
            <S.SubmitButton
              id="next"
              disabled={
                loading ||
                (mode === 'phone' &&
                  !!(!loginData.phoneNumber || errors.get('phoneNumber') || errors.get('country'))) ||
                (mode === 'email' && (!email || !!errors.get('email'))) ||
                (isRegister && !!errors.get('password')) ||
                !loginData?.password.length
              }
              loading={loading}
              type="principal"
              isRegister={isRegister}
            >
              {isRegister
                ? __('WelcomeRegister.createAccount', { locale: lang })
                : __('WelcomeLogin.logIn', { locale: lang })}
            </S.SubmitButton>
            {isRegister ? (
              <S.SubTitle>
                {
                  utils.formatText(__('WelcomeRegister.description', { locale: lang }), (s, idx) => {
                    return (
                      <S.Link key={s} href={getTermsLinkByLanguage(Math.floor(idx / 2), lang)} target="_blank">
                        {s}
                      </S.Link>
                    );
                  }) as Array<string>
                }
              </S.SubTitle>
            ) : null}
            <S.DividerContainer>
              <S.DividerBar />
              <S.TextGrey>{__('Components.Onboarding.LoginPhoneEmail.or', { locale: lang })}</S.TextGrey>
              <S.DividerBar />
            </S.DividerContainer>

            <S.OauthButton
              id="google"
              type="secondary"
              iconUrl={IMAGES.googleLogo}
              onClick={e => {
                e.preventDefault();
                return isRegister ? this.googleRegister() : this.googleSignIn();
              }}
            >
              {isRegister
                ? __('WelcomeRegister.google', { locale: lang })
                : __('WelcomeLogin.google', { locale: lang })}
            </S.OauthButton>

            <S.OauthButton
              id="facebook"
              type="secondary"
              iconUrl={IMAGES.facebookLogo}
              onClick={e => {
                e.preventDefault();
                return isRegister ? this.facebookRegister() : this.facebookSignIn();
              }}
            >
              {isRegister
                ? __('WelcomeRegister.facebook', { locale: lang })
                : __('WelcomeLogin.facebook', { locale: lang })}
            </S.OauthButton>
            {/*<S.OauthButton
              id="microsoft"
              type="secondary"
              iconUrl={IMAGES.microsoftLogo}
              onClick={e => e.preventDefault()}
            >
              {isRegister ? __('WelcomeRegister.microsoft', { locale: lang }) : __('WelcomeLogin.microsoft', { locale: lang })}
            </S.OauthButton> */}
          </S.Card>
        </form>
      </S.Container>
    );
  }

  /**
   * Render forget password link
   */
  private renderForgetPassword() {
    const { lang, loginData, email, mode } = this.props;
    const { errors } = this.state;
    const shouldDisplayForPhone = mode === 'phone' && loginData.phoneNumber && !errors.get('phoneNumber');
    const shouldDisplayForEmail = mode === 'email' && email && !errors.get('email');
    return shouldDisplayForPhone || shouldDisplayForEmail ? (
      <S.ForgetPasswordContainer>
        <S.SubTitle>
          {__('Components.Onboarding.LoginPassword.forget_password', { locale: lang })}{' '}
          <S.Link onClick={() => this.onForgetPassword()}>
            {mode === 'phone'
              ? __('Components.Onboarding.LoginPassword.forget_password_link_sms', { locale: lang })
              : __('Components.Onboarding.LoginPassword.forget_password_link_email', { locale: lang })}
          </S.Link>
          {__('Components.Onboarding.LoginPassword.forget_password_description', { locale: lang })}
        </S.SubTitle>
      </S.ForgetPasswordContainer>
    ) : null;
  }

  /**
   * Render phone input.
   * Read only if user has already logged previously.
   */
  private renderPhoneInput() {
    const { lang, loginData, updateLoginData } = this.props;
    const { countriesSelect, errors, textError } = this.state;
    const { country, phoneNumber, countryCode } = loginData;
    return (
      <S.PhoneInputContainer>
        <Select
          containerMargin="0"
          hasError={!!(errors.get('login_country') || textError)}
          menuWidth="max-content"
          name="country"
          onChange={(name, val) => {
            this.setError(name, val ? '' : 'empty');
            updateLoginData(name, val);
          }}
          options={countriesSelect}
          placeholder={__('Components.Onboarding.LoginPhoneEmail.country_placeholder', { locale: lang })}
          value={country}
        />
        <Input
          containerMargin="0 0 0 5px"
          hasError={!!(errors.get('phoneNumber') || textError)}
          isRequired={true}
          name="phoneNumber"
          onChange={(name, value) => {
            this.setError(name, value ? '' : 'empty');
            updateLoginData(name, new AsYouType(countryCode as CountryCode).input(value.toString().replace(/\D/g, '')));
          }}
          placeholder={__('Components.Onboarding.LoginPhoneEmail.phoneNumber_placeholder', { locale: lang })}
          type="text"
          value={phoneNumber}
          width="60%"
          startIcon="Phone"
        />
      </S.PhoneInputContainer>
    );
  }

  /**
   * Render email input.
   * Read only if user has already logged previously.
   */
  private renderEmailInput() {
    const { errors, textError } = this.state;
    const { email, lang, setParentState } = this.props;
    return (
      <Input
        isRequired={true}
        onChange={(name, value) => {
          this.setError(name, value ? '' : 'empty');
          setParentState({ email: value + '' });
        }}
        containerMargin="0"
        hasError={!!(errors.get('email') || textError)}
        type="text"
        width="100%"
        name="email"
        placeholder={__('Components.Onboarding.LoginPhoneEmail.email_placeholder', { locale: lang })}
        value={email}
        startIcon="Read"
      />
    );
  }

  /**
   * Set error for a particular field represented by key
   */
  private setError(key: string, error: string) {
    const { errors, textError } = this.state;
    const cloneErrors = new Map(errors);
    const keyWithPrefix = key;
    if (error) {
      cloneErrors.set(keyWithPrefix, error);
    } else {
      cloneErrors.delete(keyWithPrefix);
    }
    this.setState({ errors: cloneErrors, textError: cloneErrors.size === 0 ? '' : textError });
  }

  /**
   * Handle on submit click for Login phone
   * Validate form and exit if errors.
   */
  private onSubmit(fromOauth?: boolean) {
    const { mode, setParentState } = this.props;
    if (!fromOauth) {
      setParentState({
        emailVerificationNeeded: true,
        recoverPassword: false,
        registerInfoStep: REGISTER_INFO_STEPS.VERIFY,
        oauthToken: '',
      });
    }
    if (mode === 'email') this.onSubmitWithEmail();
    else if (mode === 'phone') this.onSubmitWithPhone();
  }

  private signInWithOauthCallback() {
    const { webloginOauth, redirect, lang, addContactByPricelistUrl, isRegister, contactUrl, setParentState } =
      this.props;
    return auth.currentUser.getIdToken().then(idToken =>
      webloginOauth({ oauth_token: idToken }, (result, respMe) => {
        const onError = this.onErrorCallback;

        const user = auth.currentUser;
        let email = user.email;
        let method: 'facebook' | 'google' = 'google';

        if (user.providerData.length > 0) {
          const providerData = user.providerData[0];
          email = email ? email : providerData.email;
          method = providerData.providerId.split('.')[0];
        }

        const baseTrack: { mode: 'signup' | 'login'; method: typeof method; device: 'web'; userStatus: 'registered' } =
          {
            method,
            mode: isRegister ? 'signup' : 'login',
            device: 'web',
            userStatus: 'registered',
          };

        if (!email) {
          setParentState({ mode: 'email' });
          EventTrack.onboardUserLoginSignup({
            ...baseTrack,
            result: 'error-no-email-provided',
          });
          return onError(__('Components.Onboarding.LoginPhoneEmail.error_login_email_oauth_empty', { locale: lang }));
        }

        if (result.startsWith('error')) {
          let textError = '';
          switch (result) {
            case 'error_login_attempts':
              textError = __('Components.Onboarding.error_login_password_attempts', { locale: lang });
              break;
            case 'error_blocked':
              textError = __('Components.Onboarding.error_login_password_blocked', { locale: lang });
              break;
            case 'error_user_not_found':
              textError = __('Components.Onboarding.LoginPhoneEmail.error_login_email_not_existent', { locale: lang });
              break;
            default:
              textError = __('Components.Onboarding.error_login_password_email', { locale: lang });
          }
          onError(textError);
          EventTrack.onboardUserLoginSignup({
            ...baseTrack,
            result: 'error',
          });
          EventTrack.track('user_login', {
            email,
            device: 'web',
            result: 'error',
            error: 'oauth_google',
          });
          return;
        }
        global.localStorage.setItem('email', email);

        EventTrack.onboardUserLoginSignup({
          ...baseTrack,
          result: 'success',
        });
        EventTrack.track('user_login', { email, device: 'web', result: 'success', error: '' });
        if (contactUrl) addContactByPricelistUrl(respMe, contactUrl, () => redirect && redirect(respMe.id));
        else if (redirect) redirect(respMe.id);
      }),
    );
  }

  private googleSignIn() {
    return signInWithGoogle()
      .then(() => this.signInWithOauthCallback())
      .catch(() => {
        EventTrack.onboardUserLoginSignup({
          device: 'web',
          method: 'google',
          mode: 'login',
          result: 'error-external-oauth-flow',
          userStatus: 'error',
        });
      });
  }
  private facebookSignIn() {
    return signInWithFacebook()
      .then(() => this.signInWithOauthCallback())
      .catch(() => {
        EventTrack.onboardUserLoginSignup({
          device: 'web',
          method: 'facebook',
          mode: 'login',
          result: 'error-external-oauth-flow',
          userStatus: 'error',
        });
      });
  }

  private googleRegister() {
    return signInWithGoogle()
      .then(() => {
        const { setParentState, setRegisterInfo } = this.props;
        const user = auth.currentUser;
        let email = user.email;
        const { displayName, emailVerified } = user;

        if (!email) {
          email = user.providerData[0].email;
        }

        return auth.currentUser.getIdToken().then(oauthToken => {
          setParentState({
            oauthToken,
            emailVerificationNeeded: !emailVerified,
            registerInfoStep: REGISTER_INFO_STEPS.NAME,
            email,
            mode: 'email',
          });
          setRegisterInfo({ name: displayName });
          this.onSubmit(true);
        });
      })
      .catch(() => {
        EventTrack.onboardUserLoginSignup({
          device: 'web',
          method: 'google',
          mode: 'signup',
          result: 'error-external-oauth-flow',
          userStatus: 'error',
        });
      });
  }

  private facebookRegister() {
    return signInWithFacebook()
      .then(() => {
        const { lang, setParentState, setRegisterInfo } = this.props;
        const { providerData } = auth.currentUser;
        const { email, displayName } = providerData[0];
        if (!email) {
          setParentState({ mode: 'email' });
          return this.onErrorCallback(
            __('Components.Onboarding.LoginPhoneEmail.error_login_email_oauth_empty', { locale: lang }),
          );
        }
        return auth.currentUser.getIdToken(true).then(oauthToken => {
          setParentState({
            oauthToken,
            emailVerificationNeeded: false, // for google we use !emailVerified, but facebook doesn't fill it
            registerInfoStep: REGISTER_INFO_STEPS.NAME,
            email,
            mode: 'email',
          });
          setRegisterInfo({ name: displayName });
          this.onSubmit(true);
        });
      })
      .catch(() => {
        EventTrack.onboardUserLoginSignup({
          device: 'web',
          method: 'facebook',
          mode: 'signup',
          result: 'error-external-oauth-flow',
          userStatus: 'error',
        });
      });
  }

  /**
   * On submit phone form
   */
  private onSubmitWithPhone() {
    const { isRegister, lang, loginData, setParentState, userExists } = this.props;
    const { errors, textError } = this.validatePhoneLogin(loginData, isRegister);
    this.setState({ errors, textError });

    const { phoneParsed, countryPrefix } = loginData;
    const phoneWithoutPrefix = phoneParsed?.number.replace(`+${countryPrefix}`, '');
    const phone = phoneParsed?.number.replace('+', '');

    // Tracking variables
    const mode = isRegister ? 'signup' : 'login';
    const baseTrack: { mode: 'signup' | 'login'; method: 'phone'; device: 'web' } = {
      method: 'phone',
      mode,
      device: 'web',
    };

    if (phoneWithoutPrefix && errors.size === 0) {
      EventTrack.onboardPhoneValidation({ phone, valid: 'success' });
      userExists({ phone }, (result, code) => {
        if (result === 'error') {
          this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
          return;
        }
        setParentState({ mode: 'phone' });
        global.localStorage.setItem('phone', loginData.phoneNumber);
        global.localStorage.setItem('country', loginData.country);
        global.localStorage.setItem('mode', 'phone');
        switch (code) {
          case UserExistsCode.UserExistsUnregistered:
          case UserExistsCode.UserNotExists:
            EventTrack.userStatus({ status: 'unregistered' });
            if (isRegister) {
              EventTrack.onboardUserLoginSignup({
                ...baseTrack,
                userStatus: 'user-not-exists',
                result: 'go-to-verification-step',
              });
              this.goToVerifyCodeStep({
                phoneNumber: phoneWithoutPrefix,
                countryPrefix: Number(loginData.countryPrefix),
              });
            } else {
              EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'user-not-exists', result: 'error' });
              errors.set('phoneNumber', 'user_not_exists');
              this.setState({
                textError: __('WelcomeLogin.user_not_exists_phone', { locale: lang }),
                errors,
              });
            }
            break;
          case UserExistsCode.UserExistsWithoutPassword:
            EventTrack.userStatus({ status: 'registered-no-password' });
            EventTrack.onboardUserLoginSignup({
              ...baseTrack,
              userStatus: 'registered-no-password',
              result: 'go-to-verification-step',
            });
            this.goToVerifyCodeStep(
              {
                phoneNumber: phoneWithoutPrefix,
                countryPrefix: Number(loginData.countryPrefix),
              },
              undefined,
              true,
            );
            break;
          case UserExistsCode.UserExistsWithPassword:
            EventTrack.userStatus({ status: 'registered' });
            this.onLogin(this.onErrorCallback);
            break;
          default:
            EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'error', result: 'error' });
            this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
            return;
        }
      });
    } else {
      EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'invalid-form', result: 'error' });
      EventTrack.onboardPhoneValidation({ phone, valid: 'invalid' });
    }
  }

  /**
   * On valid email submit
   */
  private onSubmitValidEmail(errors: Map<string, string>, baseTrack) {
    const { email, lang, isOauth, isRegister, setParentState, userExists } = this.props;

    EventTrack.onboardEmailValidation({ email, valid: 'valid' });

    userExists({ email }, (result, code) => {
      if (result === 'error') {
        this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
        return;
      }
      setParentState({ mode: 'email', email });
      global.localStorage.setItem('email', email);
      global.localStorage.setItem('mode', 'email');

      switch (code) {
        case UserExistsCode.UserExistsEmailNotValidated:
          EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'unvalidated-email', result: 'error' });

          this.onErrorCallback(__('Components.Onboarding.LoginPhoneEmail.error_email_not_validated', { locale: lang }));
          break;
        case UserExistsCode.UserExistsUnregistered:
        case UserExistsCode.UserNotExists:
          EventTrack.userStatus({ status: 'unregistered' });

          if (isRegister) {
            EventTrack.onboardUserLoginSignup({
              ...baseTrack,
              userStatus: 'user-not-exists',
              result: 'go-to-verification-step',
            });
            this.goToVerifyCodeStep(undefined, email);
          } else {
            EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'user-not-exists', result: 'error' });
            errors.set('email', 'user_not_exists');
            this.setState({
              textError: __('WelcomeLogin.user_not_exists_email', { locale: lang }),
              errors,
            });
          }
          break;
        case UserExistsCode.UserExistsWithoutPassword:
          if (isRegister && isOauth) {
            EventTrack.userStatus({ status: 'registered' });
            this.onLogin(this.onErrorCallback);
            break;
          }

          EventTrack.onboardUserLoginSignup({
            ...baseTrack,
            userStatus: 'registered-no-password',
            result: 'go-to-verification-step',
          });
          EventTrack.userStatus({ status: 'registered-no-password' });
          this.goToVerifyCodeStep(undefined, email, true);
          break;
        case UserExistsCode.UserExistsWithPassword:
          EventTrack.userStatus({ status: 'registered' });
          this.onLogin(this.onErrorCallback);
          break;

        default:
          EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'error', result: 'error' });
          this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
          return;
      }
    });
  }

  /**
   * On submit email form
   */
  private onSubmitWithEmail() {
    const { email, lang, loginData, isOauth, isRegister, isEmailValid } = this.props;
    const { errors, textError } = this.validateEmailForm(email, loginData.password, isRegister, isOauth);
    this.setState({ errors, textError });

    // Tracking variables
    let method: 'email' | 'facebook' | 'google' = 'email';
    const mode = isRegister ? 'signup' : 'login';
    const device = 'web';
    if (isOauth) {
      method = auth.currentUser.providerData[0].providerId.split('.')[0];
    }
    const baseTrack: { mode: 'signup' | 'login'; method: typeof method; device: 'web' } = { method, mode, device };

    if (errors.size === 0) {
      isEmailValid({ email }, (data: IIsEmailValidAPIResponse, error: Error) => {
        if (error || !data.is_valid) {
          this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
          return;
        }
        this.onSubmitValidEmail(errors, baseTrack);
      });
    } else {
      EventTrack.onboardUserLoginSignup({ ...baseTrack, userStatus: 'invalid-form', result: 'error' });
      EventTrack.onboardEmailValidation({ email, valid: 'invalid' });
    }
  }

  /**
   * Set phone number info + go to verify the phone number step
   */
  private goToVerifyCodeStep(
    phoneData?: { phoneNumber: string; countryPrefix: number },
    email?: string,
    recoverPwd?: boolean,
  ) {
    const { lang, isRegister, navAuthAction, setParentState, verifyPhone, verifyEmailCode } = this.props;
    if (phoneData) {
      verifyPhone(phoneData.phoneNumber, Number(phoneData.countryPrefix), true, resultVerify => {
        if (resultVerify === 'error') {
          this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
          return;
        }
        setParentState({ recoverPassword: !!recoverPwd });
        navAuthAction(isRegister ? REGISTER_STEPS.REGISTER : REGISTER_STEPS.VERIFY_CODE);
      });
    } else if (email) {
      verifyEmailCode({ email }, (result: 'success' | 'error') => {
        if (result === 'success') {
          setParentState({ recoverPassword: !!recoverPwd });
          navAuthAction(isRegister ? REGISTER_STEPS.REGISTER : REGISTER_STEPS.VERIFY_CODE);
        }
      });
    }
  }

  /**
   * Validate the phone login data (fields not empty, phone number validation)
   */
  private validatePhoneLogin(
    data: ILoginData,
    isRegister: boolean,
  ): { errors: Map<string, string>; textError: string } {
    const { lang } = this.props;
    const errors = new Map();
    let textError = '';
    if (!data.country) {
      errors.set('login_country', 'empty');
      textError = __('Components.Onboarding.LoginPhoneEmail.country_empty', { locale: lang });
      return { errors, textError };
    }
    if (!data.phoneNumber) {
      errors.set('phoneNumber', 'empty');
      textError = __('Components.Onboarding.LoginPhoneEmail.phone_empty', { locale: lang });
      return { errors, textError };
    }
    if (!data.phoneValid) {
      errors.set('phoneNumber', 'validation');
      textError = __('Components.Onboarding.LoginPhoneEmail.phone_validation', { locale: lang });
    }
    if (isRegister && !validations.validatePassword(data.password)) {
      errors.set('password', 'validation');
    }

    return { errors, textError };
  }

  /**
   * Validate email login data (fields not empty, email validation)
   */
  private validateEmailForm(
    email: string,
    password: string,
    isRegister: boolean,
    isOauth: boolean,
  ): { errors: Map<string, string>; textError: string } {
    const { lang } = this.props;
    const errors = new Map();
    let textError = '';

    if (!email) {
      EventTrack.onboardEmailValidation({ email, valid: 'invalid' });
      errors.set('email', 'empty');
      textError = __('Components.Onboarding.LoginPhoneEmail.email_empty', { locale: lang });
      return { errors, textError };
    }
    const isEmailValid = validations.validateEmail(email);
    if (!isEmailValid) {
      errors.set('email', 'validation');
      textError = __('Components.Onboarding.LoginPhoneEmail.email_validation', { locale: lang });
    }
    if (isRegister && !isOauth && !validations.validatePassword(password)) {
      errors.set('password', 'validation');
    }
    return { errors, textError };
  }

  /**
   * On error callback, print an error message
   */
  private onErrorCallback = (textError: string) => {
    this.setState({ textError }, () => this.setError('callback', textError));
  };

  /**
   * Login user
   */
  private onLogin = (onError: (text: string) => void) => {
    const {
      addContactByPricelistUrl,
      contactUrl,
      email,
      getTwoFactorVerificationCode,
      lang,
      loginData,
      isRegister,
      isOauth,
      mode,
      redirect,
      webloginPassword,
    } = this.props;
    const { phoneParsed, countryPrefix, password } = loginData;
    const finalNumber = mode === 'phone' && phoneParsed ? phoneParsed.number.replace(`+${countryPrefix}`, '') : '';
    const params = {
      phone: mode === 'phone' ? `${countryPrefix}${finalNumber}` : undefined,
      email: mode === 'phone' ? undefined : email,
      password,
    };
    let method: 'email' | 'phone' | 'facebook' | 'google' = mode;
    if (isOauth) {
      method = auth.currentUser.providerData[0].providerId.split('.')[0];
    }

    const onEnd = (result, respMe, lastFourDigitsPhone) => {
      if (result.startsWith('error')) {
        let textError = '';
        switch (result) {
          case 'error_login_attempts':
            textError = __('Components.Onboarding.error_login_password_attempts', { locale: lang });
            break;
          case 'error_blocked':
            textError = __('Components.Onboarding.error_login_password_blocked', { locale: lang });
            break;
          default:
            textError =
              mode === 'phone'
                ? __('Components.Onboarding.error_login_password_phone', { locale: lang })
                : __('Components.Onboarding.error_login_password_email', { locale: lang });
        }

        EventTrack.onboardUserLoginSignup({
          method,
          mode: isRegister ? 'signup' : 'login',
          device: 'web',
          userStatus: 'registered',
          result: 'wrong-password',
        });
        onError(textError);
        EventTrack.track('user_login', { phone: finalNumber, device: 'web', result: 'error', error: 'password' });
        return;
      }

      EventTrack.onboardUserLoginSignup({
        method,
        mode: isRegister ? 'signup' : 'login',
        device: 'web',
        userStatus: 'registered',
        result: 'success',
      });
      if (mode === 'phone') {
        global.localStorage.setItem('phone', loginData.phoneNumber);
        global.localStorage.setItem('country', loginData.country);
      } else if (mode === 'email') {
        global.localStorage.setItem('email', email);
      }
      if (result === '2fa_required') {
        EventTrack.track('user_login', { phone: finalNumber, device: 'web', result: '2fa_required', error: '' });
        getTwoFactorVerificationCode(lastFourDigitsPhone);
        return;
      }
      EventTrack.track('user_login', { phone: finalNumber, device: 'web', result: 'success', error: '' });
      if (contactUrl) addContactByPricelistUrl(respMe, contactUrl, () => redirect && redirect(respMe.id));
      else if (redirect) redirect(respMe.id);
    };
    if (isOauth) {
      this.signInWithOauthCallback();
    } else {
      webloginPassword(params, onEnd);
    }
  };

  /**
   * On forget password callback
   */
  private onForgetPassword = () => {
    const { email, lang, loginData, mode, setParentState, userExists } = this.props;
    const { errors } = this.state;

    EventTrack.click({
      name: 'forgot-password',
      data: mode === 'phone' && loginData ? { phone: `${loginData.phoneParsed?.number?.replace('+', '')}` } : { email },
    });

    let userExistParams;
    if (mode === 'phone') {
      const { phoneParsed } = loginData;
      const phone = phoneParsed?.number.replace('+', '');
      userExistParams = { phone };
    } else {
      userExistParams = { email };
    }

    userExists(userExistParams, (result, code) => {
      if (result === 'error') {
        this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
        return;
      }

      switch (code) {
        case UserExistsCode.UserExistsWithPassword:
        case UserExistsCode.UserExistsWithoutPassword:
          setParentState({ recoverPassword: true });
          this.getVerificationCode();
          break;

        default:
          if (mode === 'phone') {
            errors.set('phoneNumber', 'user_not_exists');
            this.setState({
              textError: __('WelcomeLogin.user_not_exists_phone', { locale: lang }),
              errors,
            });
          } else {
            errors.set('email', 'user_not_exists');
            this.setState({
              textError: __('WelcomeLogin.user_not_exists_email', { locale: lang }),
              errors,
            });
          }
      }
    });
  };

  /**
   * Request code to verify phone. Or if phone number is not set, show forget password form.
   */
  private getVerificationCode = () => {
    const { email, lang, loginData, mode, navAuthAction, verifyEmailCode, verifyPhone } = this.props;

    if (mode === 'phone') {
      const finalNumber =
        loginData.phoneParsed && loginData.phoneParsed.number.replace(`+${loginData.countryPrefix}`, '');
      verifyPhone(finalNumber, Number(loginData.countryPrefix), true, result => {
        if (result === 'error') {
          this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
          return;
        }
        navAuthAction(REGISTER_STEPS.VERIFY_CODE);
      });
    } else {
      verifyEmailCode({ email }, (result: 'success' | 'error') => {
        if (result === 'error') this.onErrorCallback(__('Components.Onboarding.error_generic', { locale: lang }));
        navAuthAction(REGISTER_STEPS.VERIFY_CODE);
      });
    }
  };
}
