import { colors, debounce } from 'common-services';

let msie;
if (typeof window !== 'undefined') {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // Detect IE11 or older versions
  msie = window.navigator.userAgent.indexOf('MSIE ');

  window.addEventListener(
    'resize',
    debounce(() => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 50),
  );
}

const sizes = {
  desktop_l: 1600,
  desktop_ml: 1400,
  desktop_m: 1200,
  ipad: 1024,
  mobile: 768,
};

export { sizes };

const theme = {
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.12)',
  selectedBoxShadow: `1px 1px 5px 2px ${colors.grey2.replace('1)', '0.6)')}`,
  redBoxShadow: `0px 1px 4px 0px ${colors.red1.replace('1)', '0.6)')}`,
  chatListWidth: {
    xl: 350,
    l: 250,
    s: 59,
  },
  colors,
  fontSize: {
    H1: '1.5rem', // 24px
    H2: '1.25rem', // 20px
    H3: '1.125rem', // 18px
    big: '0.9375rem', // 15px
    normal: '0.8125rem', // 13px
    small: '0.6875rem', // 11px
    tiny: '0.5625rem', // 9px
  },
  fontWeight: {
    Light: '300',
    Regular: '400',
    Semi: '600',
    Bold: '700',
  },
  headerHeight: '53px',
  landingMinHeight: '700px',
  sizes: {
    desktop_l: sizes.desktop_l + 'px',
    desktop_ml: sizes.desktop_ml + 'px',
    desktop_m: sizes.desktop_m + 'px',
    ipad: sizes.ipad + 'px',
    mobile: sizes.mobile + 'px',
  },
  paddingObject: '14px',
  paddingView: '20px',
  paddingSize: (multiplier: number = 1): string => `${6 * multiplier}px`,
  inputHeight: '40px',
  borderRadius: '5px',
  contentHeight:
    // @ts-ignore
    (typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode) || msie > 0
      ? 'calc(100vh - 53px)'
      : 'calc(var(--vh, 1vh) * 100 - 53px)',
  contentHeightWithBreadcrumb:
    // @ts-ignore
    (typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode) || msie > 0
      ? 'calc(100vh - 53px)'
      : 'calc(var(--vh, 1vh) * 100 - 95px)', // 53 + 42 (breadcrumb)
  windowHeight:
    // @ts-ignore
    (typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode) || msie > 0
      ? '100vh'
      : 'calc(var(--vh, 1vh) * 100)',
};
export default theme;
