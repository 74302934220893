import {
  __,
  buyerWorkspaceSelectors,
  constants,
  contactSelectors,
  date,
  EventTrack,
  i18n,
  modalActions,
  RETURNING_IN,
  sellerWorkspaceSelectors,
  sellerWorkspaceService,
  userActions,
  userSelectors,
  userService,
  utils,
  WORKING_STATUS,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import config from '../../../../bindings/config';
import { supportAction } from '../../../actions/user';
import { IMAGES } from '../../../assets';
import { getTermsLink, ROUTE_PATHS } from '../../../constants';
import { doLogOut } from '../../../store';
import { useDebounce, useWindowSize } from '../../../util/hooks';
import getPath from '../../../util/routes';
import { SimpleDropdown } from '../../atoms';

import * as S from './MainHeader.styled';
import NotificationsModal from './NotificationsModal';
import WorkspaceSelectModal from './WorkspaceSelectModal.component';

import type { IReduxState } from '../../../reducers';
import type { ITab } from '../../molecules/NavigationTabs/NavigationTabs.component';
import type { IHeaderProps } from '../Header';
import type { Dispatch } from 'redux';

interface IProps {
  className?: string;
}

/**
 * Main header on top of web app for the new navigation
 *
 */
const MainHeader: React.FC<IProps & IHeaderProps> = ({
  className,
  breadcrumbs = [],
  show,
  title = '',
  subtitle = '',
  tabSelected,
}) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const history = useHistory();
  const params = useParams<{ workspaceId: string }>();
  const me = useSelector(userSelectors.getUser);
  const contacts = useSelector(contactSelectors.getContacts());
  const workspaceId = Number(params?.workspaceId) || useSelector((state: IReduxState) => state.nav.workspaceSelected);

  const workspaceType = useSelector((state: IReduxState) => state.nav.workspaceType);
  const bWsInitialized = useSelector((state: IReduxState) => state.nav.bWsInitialized);
  const sWsInitialized = useSelector((state: IReduxState) => state.nav.sWsInitialized);
  const sellerWorkspaces = useSelector(sellerWorkspaceSelectors.getCatalogs());
  const buyerWorkspaces = useSelector(buyerWorkspaceSelectors.getWorkspaces());

  const isBuyerWorkspace = workspaceType === 'buyer';
  const workspace = isBuyerWorkspace
    ? useSelector(buyerWorkspaceSelectors.getWorkspace(workspaceId))
    : useSelector(sellerWorkspaceSelectors.getCatalog(workspaceId));

  const isLogged = !!me.id;
  const [workingStatus, setWorkingStatus] = React.useState(me.settings.workingStatus);
  const [hiddenBreadcrumbs, setHiddenBreadcrumbs] = React.useState(0);
  const dropDrownRef = React.useRef<SimpleDropdown>();
  const workspaceModalRef = React.useRef<SimpleDropdown>();
  const toggleBuyerOfferTableEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_BUYER_OFFER_TABLE));
  const toggleSellerOfferTableEnable = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_SELLER_OFFER_TABLE));
  const defaultBreadcrumbs = breadcrumbs.length === 0 ? [{ label: title }, { label: subtitle }] : breadcrumbs;

  const cart = useSelector((state: IReduxState) =>
    Object.values(state.order.cart).filter(el => {
      return el.orderId === 0 && el.asSeller === false && el.contactId && el.items.find(item => item.amount > 0);
    }),
  );

  const getTotalItemCount = arr => {
    return arr.reduce((total, obj) => total + obj.items.length, 0);
  };
  /**
   * Handle log out click, showing alert modal.
   * If not logged, do not undertake any action.
   */
  const onLogOutClick = React.useCallback(() => {
    if (!isLogged) return;
    dispatch(
      modalActions.modalOpen(
        __('Components.Header.confirm_log_out'),
        () => {
          dispatch(modalActions.modalClose());
          setTimeout(() => {
            dispatch(userActions.logout(() => doLogOut()));
            localStorage.clear();
          }, 150);
        },
        {
          text2: __('Components.Header.confirm_log_out_description'),
          buttonText: __('Components.Header.Logout'),
          id: 'logout',
        },
        'nice',
      ),
    );
    dropDrownRef?.current?.hide();
  }, [dispatch, dropDrownRef, isLogged]);

  /**
   * Open modal if there is no workspace and show options to create one or to pick one from the available ones
   */

  const showWorkspaceOptionsModal = React.useCallback(() => {
    dispatch(
      modalActions.modalOpen(
        __('MainHeader.workspace_modal.title'),
        () => {
          dispatch(modalActions.modalClose());
          history.push(getPath({ path: ROUTE_PATHS.WORKSPACES_NEW }));
        },

        {
          icon: IMAGES.cautionGrape,
          text2:
            sellerWorkspaces || buyerWorkspaces
              ? __('MainHeader.workspace_modal.text_choose_workspace')
              : __('MainHeader.workspace_modal.text_create_workspace'),
          buttonText: __('MainHeader.workspace_modal.create'),
          showCancelButton: sellerWorkspaces || buyerWorkspaces ? true : false,
          buttonCancelText: __('MainHeader.workspace_modal.change'),
          cancelAction: () => {
            dispatch(modalActions.modalClose());
            history.push(getPath({ path: ROUTE_PATHS.WORKSPACES }));
          },
          closeable: false,
          id: 'logout',
          linkAction: () => {
            dispatch(supportAction());
          },
        },
        'nice',
      ),
    );
    dropDrownRef?.current?.hide();
  }, [dispatch, workspace]);
  /**
   * Update user's working status
   */
  const updateUserWorkingStatus = React.useCallback(
    (workingStatusUpdated: WORKING_STATUS, muteNotificationsWhileNotAvailable: boolean) => {
      EventTrack.track('outofoffice_widget_update', {
        working_status: workingStatusUpdated,
        mute_notifications_while_not_available: muteNotificationsWhileNotAvailable,
      });
      dispatch(
        userActions.updateUser(
          {
            ...me,
            settings: {
              ...me.settings,
              workingStatus: workingStatusUpdated,
              muteNotificationsWhileNotAvailable,
              returnToAvailableOption: RETURNING_IN.UNSPECIFIED,
              returnToAvailableTime: undefined,
            },
          },
          err => {
            setWorkingStatus(err ? me.settings.workingStatus : workingStatusUpdated);
          },
        ),
      );
    },
    [dispatch, me],
  );

  /**
   * Handle updating working status. If not available selected, show modal to choose to deactivate notifications
   */
  const handleUpdateWorkingStatus = React.useCallback(
    (workingStatusUpdated: WORKING_STATUS) => {
      if (
        workingStatusUpdated !== WORKING_STATUS.NOT_AVAILABLE ||
        global.localStorage.getItem('notAvailablePushModal') === 'hidden'
      ) {
        updateUserWorkingStatus(workingStatusUpdated, false);
      } else {
        dispatch(
          modalActions.modalOpen(
            __('ReceiveNotificationsModal.title'),
            () => {
              updateUserWorkingStatus(workingStatusUpdated, true);
              dispatch(modalActions.modalClose());
              global.localStorage.setItem('notAvailablePushModal', 'hidden');
            },
            {
              showCancelButton: true,
              text2: __('ReceiveNotificationsModal.description'),
              subtitle: __('ReceiveNotificationsModal.subtitle'),
              buttonCancelText: __('ReceiveNotificationsModal.cta_cancel'),
              icon: IMAGES.notificationsPineapple,
              buttonText: __('ReceiveNotificationsModal.cta_accept'),
              closeAction: () => {
                updateUserWorkingStatus(workingStatusUpdated, true);
                dispatch(modalActions.modalClose());
                global.localStorage.setItem('notAvailablePushModal', 'hidden');
              },
              cancelAction: () => {
                updateUserWorkingStatus(workingStatusUpdated, false);
                dispatch(modalActions.modalClose());
                global.localStorage.setItem('notAvailablePushModal', 'hidden');
              },
            },
            'nice',
          ),
        );
      }
    },
    [dispatch, updateUserWorkingStatus],
  );

  /**
   * Render the user's settings modal
   */
  const renderUserSettingsModal = React.useCallback(() => {
    const workingStatusLiterals = constants.workingStatusLiterals(me.settings.workingStatus);
    const returnToAvailableDate = me.settings.returnToAvailableTime
      ? new Date(me.settings.returnToAvailableTime)
      : undefined;
    return (
      <S.UserStatusModal className="working-status-modal">
        <S.HeaderUser>
          <S.UserAvatarModal
            avatarColor={utils.getAvatarColor(me.name)}
            img={me.settings.avatar}
            onClick={() => {
              history.push(getPath({ path: ROUTE_PATHS.SETTINGS, tab: 'profile' }));
              dropDrownRef?.current?.hide();
            }}
            size={42}
            text={me.name}
          />
          <S.UserNameCol>
            <S.TextName>{me.name}</S.TextName>
            <S.TextSubtitle>{me.email}</S.TextSubtitle>
          </S.UserNameCol>
        </S.HeaderUser>
        <S.InputWithLabel
          description={
            returnToAvailableDate
              ? date.getReturningInDateLiteral(returnToAvailableDate, me.settings.dateFormat, me.settings.hourFormat)
              : undefined
          }
          footerText={workingStatusLiterals.description}
          isRequired={true}
          label=""
        >
          <S.SelectWorkingStatus
            name="workingStatus"
            value={workingStatus}
            onChange={(name, val) => {
              setWorkingStatus(val as WORKING_STATUS);
              handleUpdateWorkingStatus(val as WORKING_STATUS);
            }}
          />
        </S.InputWithLabel>
        <S.WorkingStatusLink
          type="link"
          withoutPadding={true}
          onClick={() => {
            history.push(getPath({ path: ROUTE_PATHS.SETTINGS, tab: 'working-status' }));
            dropDrownRef?.current?.hide();
          }}
        >
          {__('Components.ChatList.working_status.link')}
        </S.WorkingStatusLink>
        <S.DividerGrey />
        <S.MenuButton
          iconName="Section-settings"
          iconSize="18px"
          iconType="background"
          onClick={() => {
            history.push(getPath({ path: ROUTE_PATHS.SETTINGS, tab: 'profile' }));
            dropDrownRef?.current?.hide();
          }}
          type="skip"
          withoutPadding={true}
        >
          {__('MainHeader.configuration')}
        </S.MenuButton>
        <S.MenuButton
          iconName="Help"
          iconSize="18px"
          iconType="background"
          onClick={() => {
            window.open(
              userService.getConsentioUniversityLink(
                sellerWorkspaceService.isProPlan(workspace?.plan),
                i18n.default.currentLocale() as LOCALE,
                me.settings.isBuyer,
                me.settings.isSeller,
                workspaceType === 'seller',
                config.TOGGLE_UNIVERSITY.enabled,
              ),
              '_blank',
            );
            EventTrack.track('help_click');
            dropDrownRef?.current?.hide();
          }}
          type="skip"
          withoutPadding={true}
        >
          {__('MainHeader.help')}
        </S.MenuButton>
        <S.MenuButton
          id="main-header-notifications"
          iconName="Notification"
          iconSize="18px"
          iconType="background"
          onClick={() => {
            history.push(getPath({ path: ROUTE_PATHS.SETTINGS, tab: 'notifications' }));
            dropDrownRef?.current?.hide();
          }}
          type="skip"
          withoutPadding={true}
        >
          {__('MainHeader.notification')}
        </S.MenuButton>
        <S.DividerGrey />
        <S.MenuButton
          iconName="Leave-group"
          iconSize="16px"
          iconType="background"
          onClick={() => onLogOutClick()}
          type="skip"
          withoutPadding={true}
        >
          {__('MainHeader.log_out')}
        </S.MenuButton>
        <S.TermsContainer>
          <S.TextSmall>
            {
              utils.formatText(__('MainHeader.terms_and_conditions'), (s: string, idx: number) => (
                <S.TextLink key={idx} href={getTermsLink(Math.floor(idx / 2))} target="_blank">
                  {s}
                </S.TextLink>
              )) as any // TYPEERROR
            }
          </S.TextSmall>
          <S.TextGrey2>Consentio Platform S.L.</S.TextGrey2>
        </S.TermsContainer>
      </S.UserStatusModal>
    );
  }, [dropDrownRef, handleUpdateWorkingStatus, history, onLogOutClick, me, workingStatus, workspace]);

  const breadcrumbsRef = React.useRef<HTMLSpanElement>();
  const rightContainerRef = React.useRef<HTMLDivElement>();
  const [windowWidth] = useWindowSize();
  // Use a breadcrumb id debounced to avoid lots of repainting
  const breadcrumbId = useDebounce(JSON.stringify(breadcrumbs) + '_' + title + '_' + subtitle, 50);
  // Reset hidden breadcrumbs state when changing page
  React.useEffect(() => {
    setHiddenBreadcrumbs(0);
  }, [breadcrumbId]);
  React.useEffect(() => {
    if (!workspace && isLogged && bWsInitialized && sWsInitialized && config.TOGGLE_NO_WORKSPACE_MODAL.enabled)
      showWorkspaceOptionsModal();
  }, [workspace, bWsInitialized, sWsInitialized, isLogged]);

  // Hide the breadcrumbs if needed according to the window width
  React.useEffect(() => {
    if (breadcrumbsRef?.current && rightContainerRef?.current && windowWidth > 0) {
      const breadcrumSize = breadcrumbsRef.current.getBoundingClientRect();
      const rightContainerSize = rightContainerRef.current.getBoundingClientRect();
      const isBreadcrumsOverflow = breadcrumSize.width > windowWidth - rightContainerSize.width - 80;

      if (isBreadcrumsOverflow && hiddenBreadcrumbs < 5) {
        setHiddenBreadcrumbs(hiddenBreadcrumbs + 1);
      }
    }
  }, [breadcrumbId, breadcrumbsRef, hiddenBreadcrumbs, rightContainerRef, windowWidth]);

  const notificationsCounter = useSelector((state: IReduxState) => state.notification?.internalNotifications);
  const chatsCounter = useSelector((state: IReduxState) => state.chat.unreads);
  const badge = notificationsCounter?.[workspaceId];
  const otherNotificationsCount = notificationsCounter
    ? Object.keys(notificationsCounter).reduce((acc, k) => {
        acc += Number(k) === workspaceId ? 0 : notificationsCounter[k]?.alert;
        return acc;
      }, 0)
    : 0;
  if (!show) return null;
  return (
    <S.Container id="main-header" className={className}>
      <S.Content>
        <S.LeftContainer>
          <S.RowCenter>
            <S.ConsentioLogoSquare
              src={IMAGES.consentioLogoWhiteJPG}
              alt="consentio-logo"
              onClick={() => history.replace(getPath({ path: ROUTE_PATHS.CHAT_LIST }))}
            />
            {workspace ? (
              <SimpleDropdown
                Content={
                  <WorkspaceSelectModal
                    hide={() => workspaceModalRef.current?.hide()}
                    history={history}
                    workspaceSelected={workspace}
                  />
                }
                hAlign="right"
                ref={workspaceModalRef}
              >
                <S.WorkspaceSelectRow id="main-workspace-select">
                  <S.WorkspaceTitle>{sellerWorkspaceService.getCatalogName(workspace, contacts, me)}</S.WorkspaceTitle>
                  {badge ? (
                    <S.TotalBadge
                      className="main-header-total-badge"
                      count={otherNotificationsCount || 0}
                      size={20}
                      color="red"
                    />
                  ) : null}
                  <S.ChevronWorkspaceIcon name="Down" disableHover={true} />
                </S.WorkspaceSelectRow>
              </SimpleDropdown>
            ) : (
              <S.WorkspaceTitle>Consentio</S.WorkspaceTitle>
            )}
          </S.RowCenter>
          {workspace ? (
            <S.NavigationTabs
              selected={tabSelected}
              tabs={getHeaderTabs()}
              history={history}
              className="multiple-carts"
              rightTabs={
                isBuyerWorkspace && workspaceId === me.buyerWorkspaceId
                  ? [
                      {
                        label: __('Components.MultipleCarts.unsent_cta'),
                        icon: 'Cart',
                        id: 'multiple-carts',
                        badge: getTotalItemCount(cart),

                        hideBadgeNumber: true,
                        url: getPath({
                          path: ROUTE_PATHS.WORKSPACE_CARTS,
                          workspaceId: workspaceId + '',
                        }),
                      },
                    ]
                  : undefined
              }
            />
          ) : (
            <S.TextContainer id="main-header-breadcrumbs" ref={breadcrumbsRef}>
              {defaultBreadcrumbs
                .filter(b => b.label)
                .map((b, idx, arr) => {
                  const isLast = idx === arr.length - 1;
                  const shouldBeHidden = idx <= hiddenBreadcrumbs - 1;
                  if (shouldBeHidden) return idx > 0 ? null : <S.Item key={b.label + '_' + idx}>{'... > '}</S.Item>;
                  return (
                    <React.Fragment key={b.label + '_' + idx}>
                      <S.Item hasAction={!!b.action} isFirst={idx === 0} isLast={isLast} onClick={b.action}>
                        {b.label}
                      </S.Item>
                      {isLast ? null : <S.Item>{' > '}</S.Item>}
                    </React.Fragment>
                  );
                })}
            </S.TextContainer>
          )}
        </S.LeftContainer>
        <S.RightContainer id="main-header-right-container" ref={rightContainerRef}>
          <S.NotificationsContainer
            className="notification-bell-container"
            Content={<NotificationsModal />}
            ref={dropDrownRef}
            badgeCount={badge?.alert || 0}
          >
            <S.NotificationRow>
              <S.NotificationIcon name="Notification" disableHover={true} />
              {!badge?.alert && badge?.informative ? <S.InformativeIndicator isMain={true} /> : null}
            </S.NotificationRow>
          </S.NotificationsContainer>
          <S.ChatButton
            badgeCount={chatsCounter || 0}
            isSelected={tabSelected === 'chat'}
            iconName="Chat"
            onClick={() =>
              history.push(
                getPath({
                  path: ROUTE_PATHS.CHAT_LIST,
                }),
              )
            }
          >
            {__('Components.Sidebar.chat')}
          </S.ChatButton>
          <S.UserSettingsContainer
            className="user-status-container"
            Content={renderUserSettingsModal()}
            ref={dropDrownRef}
          >
            <S.UserRow>
              <S.UserAvatar
                text={me.name}
                img={me.settings.avatar}
                avatarColor={utils.getAvatarColor(me.name)}
                size={35}
                workingStatus={me.settings.workingStatus}
                bubbleSize={15}
              />
              <S.TextRegular>{me.name}</S.TextRegular>
              <S.ChevronIcon name="Down" disableHover={true} />
            </S.UserRow>
          </S.UserSettingsContainer>
        </S.RightContainer>
      </S.Content>
      {tabSelected !== 'chat' || breadcrumbs.length > 0 ? (
        <S.Breadcrumbs id="main-header-breadcrumbs" ref={breadcrumbsRef}>
          {defaultBreadcrumbs
            .filter(b => b.label)
            .map((b, idx, arr) => {
              const isLast = idx === arr.length - 1;
              const shouldBeHidden = idx <= hiddenBreadcrumbs - 1;
              return (
                <React.Fragment key={b.label + '_' + idx}>
                  <S.Breadcrum hasAction={!!b.action} isFirst={idx === 0} isLast={isLast} onClick={b.action}>
                    {b.label}
                  </S.Breadcrum>
                  {isLast ? null : <S.Breadcrum>{' > '}</S.Breadcrum>}
                </React.Fragment>
              );
            })}
        </S.Breadcrumbs>
      ) : null}
    </S.Container>
  );
  /**
   * Get header tabs according to toggles
   */
  function getHeaderTabs(): Array<ITab> {
    return isBuyerWorkspace
      ? [
          ...(toggleBuyerOfferTableEnable
            ? [
                {
                  id: 'offers',
                  label: __('Components.ProductsList.Tabs.offers'),
                  url: getPath({
                    path: ROUTE_PATHS.WORKSPACE_OFFERS,
                    workspaceId: workspaceId + '',
                  }),
                },
              ]
            : []),
          {
            id: 'products',
            label: __('Components.ProductsList.Tabs.products_buyer'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_PRODUCTS_BUYER,
              workspaceId: workspaceId + '',
            }),
          },
          {
            id: 'purchase',
            label: __('Components.Sidebar.purchases'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_PURCHASES,
              workspaceId: workspaceId + '',
            }),
          },
          {
            id: 'suppliers',
            label: __('Components.ProductsList.Tabs.suppliers'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_SUPPLIERS,
              workspaceId: workspaceId + '',
            }),
          },
          ...(config.TOGGLE_CRUD_REFERENCES.enabled || config.TOGGLE_CRUD_REFERENCES.organizations.includes(workspaceId)
            ? [
                {
                  id: 'referential',
                  label: __('Components.ProductsList.Tabs.referential'),
                  url: getPath({
                    path: ROUTE_PATHS.WORKSPACE_REFERENTIAL,
                    workspaceId: workspaceId + '',
                  }),
                },
              ]
            : []),
        ]
      : [
          {
            id: 'products',
            label: __('Components.ProductsList.Tabs.products'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
              workspaceId: workspaceId + '',
            }),
          },
          {
            id: 'sale',
            label: __('Components.Sidebar.sales'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_SALES,
              workspaceId: workspaceId + '',
            }),
          },
          ...(toggleSellerOfferTableEnable
            ? [
                {
                  id: 'offers',
                  label: __('Components.ProductsList.Tabs.offers'),
                  url: getPath({
                    path: ROUTE_PATHS.WORKSPACE_OFFERS,
                    workspaceId: workspaceId + '',
                  }),
                },
              ]
            : []),
          {
            id: 'pricelist',
            label: workspace.plan?.addons?.quoterMarginsEnabled
              ? __('Components.ProductsList.Tabs.margins')
              : __('Components.ProductsList.Tabs.rates'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_PRICE_GROUPS,
              workspaceId: workspaceId + '',
            }),
          },
          {
            id: 'clients',
            label: __('Components.ProductsList.Tabs.clients'),
            url: getPath({
              path: ROUTE_PATHS.WORKSPACE_CLIENTS,
              workspaceId: workspaceId + '',
            }),
          },
          ...(sellerWorkspaceService.isProPlan(workspace?.plan)
            ? [
                {
                  id: 'prepare-orders',
                  label: __('Components.ProductsList.Tabs.prepare_orders'),
                  url: getPath({
                    path: ROUTE_PATHS.WORKSPACE_PREPARE_ORDERS,
                    workspaceId: workspaceId + '',
                  }),
                },
              ]
            : []),
        ];
  }
};

export default React.memo(MainHeader);
