import { PRODUCT_UNIT, __, orderService, orderUtils, parsers } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { AVAILABILITY } from '../../../../../../../../constants';
import { priceUnitTranslate } from '../../../../../../../../util/utils';
import { Input, Select } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'served-quantity',
  rank: 1000,
  isVisible: true,
  getTitle: () => __('Components.Cart.items.served_quantity'),
  minWidth: '70px',
  width: '8%',
  condition: ({ showServedColumn }) => showServedColumn,
  columnValue: ({
    order,
    isPendingOrder,
    amEditor,
    servedFlowEnabled,
    editAfterAccept,
    canServeOrder,
    onUpdateItem,
  }) => ({
    getElement: item => {
      if (item?.status && item.status !== AVAILABILITY.ACTIVE) {
        return (
          <S.ItemPriceTotal isCancelled={false} showGrey={false}>
            -
          </S.ItemPriceTotal>
        );
      }

      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));

      const hasServedChange =
        showServedColumn && (item.servedQuantity !== item.amount || item.servedSaleUnit !== item.saleUnit);
      const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));
      if (!editable || !item.servedQuantity)
        return (
          <S.CellContainer showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
            <S.AmountRow onClick={e => e.stopPropagation()}>
              <S.Amount
                showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}
                hasServedChange={hasServedChange}
                servedQuantity={true}
              >
                {item.servedQuantity}{' '}
                {parsers.getUnitText(
                  config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                    ? priceUnitTranslate(item.servedSaleUnit)
                    : item.servedSaleUnit,
                  item.weightUnit,
                  item.servedQuantity,
                )}
              </S.Amount>
            </S.AmountRow>
          </S.CellContainer>
        );

      const saleUnits = item.saleUnits || [item.saleUnit]; // hack fallback
      return (
        <S.CellContainer showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
          <S.AmountRow onClick={e => e.stopPropagation()}>
            <S.LeftRow>
              <Input
                id={`served-quantity-${item.id}`}
                name="servedQuantity"
                onBlur={(key: string, value: string, error: string) => {
                  const v = Number(value);
                  if (!error && !isNaN(v) && v !== item.servedQuantity) {
                    let newCartItem = { ...item, servedQuantity: v };
                    if (!showServedColumn) {
                      newCartItem = { ...newCartItem, amount: 0 };
                    }
                    onUpdateItem(newCartItem);
                  }
                }}
                value={item.servedQuantity || 0}
                minValue={0}
                precision={item.saleUnit === PRODUCT_UNIT.KG ? 2 : 0}
                width="80px"
                type="number"
                textAlign="right"
                // autoFocus={false}
                hasError={!item.servedQuantity}
                disabled={!editable}
                containerMargin="0 6px 0 0"
              />
              <Select
                containerMargin="0"
                value={item.servedSaleUnit}
                name={`served-sale-unit-${item.id}`}
                options={saleUnits.map(s => ({
                  value: s,
                  label: parsers.getUnitText(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId) ? priceUnitTranslate(s) : s,
                    item.weightUnit,
                    0,
                  ),
                }))}
                onChange={(n, v) => {
                  onUpdateItem({
                    ...item,
                    servedSaleUnit: v as PRODUCT_UNIT,
                  });
                }}
                width="100px"
              />
            </S.LeftRow>
          </S.AmountRow>
        </S.CellContainer>
      );
    },
  }),
};
