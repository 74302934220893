import { __, currency, orderUtils } from 'common-services';
import * as React from 'react';

import { AVAILABILITY } from '../../../../../../../../constants';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'delivery-price',
  isVisible: true,
  getTitle: () => __('Components.OffersList.table.deliveredPrice'),
  onClick: e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  },
  minWidth: '120px',
  width: '10%',
  condition: ({ isPrepare }) => !isPrepare,
  columnValue: ({ order, pricePrecision }) => ({
    getElement: item => {
      const isItemInactive = item?.status && item.status !== AVAILABILITY.ACTIVE;
      const isCancelled = isItemInactive || orderUtils.isCanceled(order);
      const displayPrice =
        isItemInactive || !item?.price
          ? '-'
          : currency.getPricePerUnit(item.currency, item.priceUnit, item.weightUnit, item.price, pricePrecision);

      return (
        <S.ItemPriceTotal isCancelled={isCancelled} showGrey={false}>
          {displayPrice}
        </S.ItemPriceTotal>
      );
    },
  }),
};
