import styled from 'styled-components';

import { RowContainer, TextLabel } from '../../../atoms';

import type { ITheme } from '../../../../styled-components';

interface IStyledProps {
  disabled?: boolean;
  theme: ITheme;
  toggleCantRepeatNorDraftNotHistory?: number;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: auto;
  overflow: auto;
  padding-right: ${(p: IStyledProps) => p.theme.paddingSize(1)};
  -webkit-overflow-scrolling: touch;
`;

export const Text = styled(TextLabel)`
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
`;

export const Col = styled.td`
  vertical-align: middle;
  padding-right: ${(p: IStyledProps) => p.theme.paddingObject};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  min-width: 70px;
  max-width: 170px;
`;

export const CenterContainer = styled(RowContainer)`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} 0;
`;

export const ItemsPanel = styled.div`
  width: 100%;
  padding: ${(p: IStyledProps) => p.theme.paddingSize()} 0;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  justify-items: center;
`;
