import styled from '../../../../styled-components';

import type { ITheme } from '../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  isDisabled?: boolean;
}

export const CardInputs = styled.div`
  display: flex;
  width: 97%;
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
