import styled, { css } from '../../../styled-components';
import { Button, ColumnContainer, FontIcon, RowContainer, SimpleDropdown, TextLabel } from '../../atoms';
import TooltipComponent from '../../atoms/Tooltip/Tooltip.component';

import type { ITheme } from '../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  isDisabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  background-color: ${(p: IStyledProps) => p.theme.colors.white};
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const Text = styled(TextLabel)`
  display: flex;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextBlack = styled(Text)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  color: ${(p: IStyledProps) => p.theme.colors.black};
  ${(p: IStyledProps) =>
    p.isDisabled &&
    css`
      opacity: 0.65;
    `}
`;

export const TextGrey2 = styled(TextBlack)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
`;

export const LinkAdd = styled(SimpleDropdown)``;

export const FlexRow = styled(RowContainer)`
  align-items: center;
  ${(p: IStyledProps) =>
    p.isDisabled &&
    css`
      opacity: 0.65;
    `}
`;

export const ClientNameColumn = styled(ColumnContainer)`
  min-width: 85px;
`;

export const ClientImagesRow = styled(RowContainer)`
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize(2)};
`;

export const AddIcon = styled(FontIcon)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  margin-right: ${(p: IStyledProps) => p.theme.paddingSize()};
`;

export const SearchContainer = styled(RowContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  height: ${(p: IStyledProps) => p.theme.headerHeight};
  align-items: center;
  min-width: 450px;
`;
export const Stats = styled(TextLabel)`
  padding: ${(p: IStyledProps) => p.theme.paddingSize(4)} ${(p: IStyledProps) => p.theme.paddingSize(5)};
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
`;
export const SellButton = styled(Button)`
  float: right;
`;
export const LinkButton = styled(Button)`
  padding: 0;
  height: min-content;
`;

export const TextBlue = styled(TextLabel)`
  display: inline;
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Regular};
  color: ${(p: IStyledProps) => p.theme.colors.blue1};
  float: right;
`;

export const TooltipContainer = styled.span`
  display: inline;
  padding-left: 4px;
`;

export const Tooltip = styled(TooltipComponent)``;

export const InfoIcon = styled(FontIcon)`
  color: ${(p: IStyledProps) => p.theme.colors.grey2};
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  height: ${(p: IStyledProps) => p.theme.fontSize.normal};
  width: ${(p: IStyledProps) => p.theme.fontSize.normal};
  line-height: ${(p: IStyledProps) => p.theme.fontSize.normal};
`;
export const TextWrapper = styled.span`
  float: right;
  display: flex;
`;
