import {
  __,
  currency,
  CURRENCY_CODES,
  modalActions,
  notificationsActions,
  orderBuyerPreparationActions,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IMAGES } from '../../../assets';
import { ROUTE_PATHS } from '../../../constants';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { OrderPreparationContext } from '../WorkspaceOfferToOrderPreparation/OrderPreparationContext';
import ItemsCard from './Fragments/ItemsCard';
import { calculateTotalPrice } from './Fragments/ItemsCard/ItemsCard.component';
import { getCartLoadSummaryText } from './ItemsCardFunctions';
import * as S from './OrderPreparationCarts.styled';

import type { CartItem } from './OrderPreparationCarts.types';
import type { IOrderBuyerPreparationCart } from 'common-services';
export interface IProps {
  workspaceId?: number;
  cart: IOrderBuyerPreparationCart;
  deliveryDate?: string;
  changeTab?: () => void;
}

const OrderCard: React.FC<IProps> = ({ workspaceId, cart: initialCart, deliveryDate, changeTab }) => {
  const { orderBuyerPreparationId } = useContext(OrderPreparationContext);
  const dispatch = useDispatch<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const history = useHistory();
  const me = useSelector(userSelectors.getUser);

  const [expanded, setExpanded] = React.useState(true);
  const [cart, setCart] = React.useState(initialCart);
  const [orderHashId, setOrderHashId] = React.useState(''); // TODO: cart.link.orderHashId
  const [orderExists, setOrderExists] = React.useState(
    cart.link.orderId !== undefined && cart.link.status === 'active',
  );
  const [hasChanges, setHasChanges] = React.useState(false); // TODO: backend should tell us if there are changes

  React.useEffect(
    () => {
      if (cart.link.orderId !== undefined && cart.link.status === 'active') {
        api.order
          .get(me.id, cart.link.orderId)
          .then(order => {
            setOrderHashId(order.hashId);
          })
          .catch(console.error);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  React.useEffect(() => {
    if (initialCart) {
      setHasChanges(true);
    }
  }, [initialCart]);

  const handleCartUpdate = (updatedItem: CartItem): void => {
    setHasChanges(true);
    setCart(prevCart => ({
      ...prevCart,
      lines: prevCart.lines
        .map(line => {
          if (line.id === updatedItem.id) {
            return {
              ...line,
              orderedQuantity: updatedItem.servedQuantity || updatedItem.amount,
              offeredProduct: {
                ...line.offeredProduct,
              },
            };
          }
          return line;
        })
        .filter(line => line.orderedQuantity > 0),
    }));

    const line = cart.lines.find(line => line.id === updatedItem.id);
    if (!line) return;

    const body = {
      departurePrice: line.offeredProduct.offeredDeparturePrice,
      deliveredPrice: line.offeredProduct.offeredPrice,
      orderedQuantity: updatedItem.servedQuantity || updatedItem.amount,
      comment: '',
    };

    dispatch(orderBuyerPreparationActions.lineUpdate(workspaceId, orderBuyerPreparationId, body, line.id)).then(() =>
      setCart(prevCart => ({ ...prevCart })),
    );
  };

  const getTotalAmount = () => {
    const items = cart.lines
      .filter(line => line.orderedQuantity > 0)
      .map(line => ({
        id: line.id,
        title: line.reference.name,
        code: line.reference.buyerTag,
        boxesPerPallet: line.offeredProduct.boxPerPallet,
        boxWeight: line.offeredProduct.boxWeight,
        unitsPerBox: line.offeredProduct.unitsPerBox,
        amount: line.orderedQuantity || 0,
        offeredDeparturePrice: line.offeredProduct.offeredDeparturePrice,
        offeredPrice: line.offeredProduct.offeredPrice,
        totalPrice: line.offeredProduct.offeredPrice * (line.orderedQuantity || 0),
        priceUnit: line.offeredProduct.priceUnit,
        currency: CURRENCY_CODES.EUR,
        servedQuantity: line.orderedQuantity || 0,
        saleUnit: line.offeredProduct.priceUnit,
        saleUnits: [line.offeredProduct.priceUnit],
        quantityUnit: line.offeredProduct.quantityUnit,
        type: 'product',
        isValid: true,
        offeredQuantityUnit: line.offeredProduct.quantityUnit,
      }));
    const itemStrings = items.map(item => {
      const total = calculateTotalPrice(
        {
          ...item,
          servedQuantity: item.servedQuantity || 0,
        },
        item.boxesPerPallet,
      );

      return currency.getPrice(item.currency, total, 2);
    });

    const ans = itemStrings.reduce((acc, item) => {
      return acc + parseFloat(item);
    }, 0);
    return ans.toFixed(2);
  };

  const linesWithQuantity = cart.lines.filter(line => line.orderedQuantity && line);

  const createOrUpdateOrder = async (notify: boolean, isUpdate: boolean): Promise<void> => {
    try {
      await dispatch(
        orderBuyerPreparationActions.orderFromCartCreate(
          workspaceId,
          orderBuyerPreparationId,
          cart.buyerWarehouse.id,
          cart.seller.id,
          res => {
            setOrderHashId(res.hash);
          },
        ),
      );
    } catch (error) {
      console.error(
        'Error during order creation:',
        workspaceId,
        orderBuyerPreparationId,
        cart.buyerWarehouse.id,
        cart.seller.id,
        error,
      );
    }
    setOrderExists(true);
    if (notify)
      dispatch(
        notificationsActions.notificationShow({
          title: isUpdate
            ? __('Components.OrderPreparation.notification.updated_title')
            : __('Components.OrderPreparation.notification.created_title'),
          subtitle: __('Components.OrderPreparation.notification.created_text'),
          closable: true,
          style: 'success',
        }),
      );
  };

  const handleOrderCreate = (isUpdate: boolean): void => {
    const copy = isUpdate ? 'save' : 'create';
    dispatch(
      modalActions.modalOpen(
        __('Components.OrderPreparation.create_modal.title'),
        () => {
          createOrUpdateOrder(true, isUpdate);
          setHasChanges(false);
        },
        {
          showCancelButton: true,
          buttonCancelText: __('Components.OrderPreparation.create_modal.cancel'),
          text2: __(`Components.OrderPreparation.${copy}_modal.description`),
          buttonText: __(`Components.OrderPreparation.${copy}_modal.cta`),
          icon: IMAGES.informativePineapple,
        },
        'nice',
      ),
    );
  };

  const navigateToOrder = (): void => {
    history.push(
      getPath({
        path: ROUTE_PATHS.WORKSPACE_PURCHASES,
        workspaceId: workspaceId?.toString(),
        hashId: orderHashId,
      }),
      { date: deliveryDate },
    );
  };

  if (cart.lines.length > 0) {
    return (
      <S.OrderCard>
        <S.Resume onClick={() => setExpanded(!expanded)}>
          <S.DownIcon name={expanded ? 'Down' : 'Right'} disableHover={true} />
          <S.ResumeLeft>
            <S.TitleRow>
              <S.ContactImagesRow>
                <S.ContactImage img="" text={cart.seller.name} avatarColor={{ background: '#f0f0f0', text: '#333' }} />
              </S.ContactImagesRow>
              <S.ContactNameColumn>
                <S.TextTitle>
                  {cart.seller.name} ·{' '}
                  {__('Components.OrderPreparation.Cart.articles', { count: linesWithQuantity.length })}
                </S.TextTitle>
                <S.TextBlackSmall>{getCartLoadSummaryText(linesWithQuantity)}</S.TextBlackSmall>
              </S.ContactNameColumn>
            </S.TitleRow>
          </S.ResumeLeft>
          <S.ResumeRight>
            <S.TextBlack>{__('Components.Cart.total')}</S.TextBlack>
            <S.TextTitle>{getTotalAmount()} €</S.TextTitle>
          </S.ResumeRight>
        </S.Resume>
        {expanded && (
          <>
            <S.HeadRow>
              <S.ProductTh>{__('Components.OrderPreparation.Cart.Info.Columns.supplier')}</S.ProductTh>
              <S.ProductTh>{__('Components.OrderPreparation.Cart.Info.Columns.delivery_address')}</S.ProductTh>
              <S.ProductTh>{__('Components.OrderPreparation.Cart.Info.Columns.transport_details')}</S.ProductTh>
            </S.HeadRow>

            <S.DataRowHead>
              <S.ProductTd>
                <S.Row>
                  <S.ContactImagesRow>
                    <S.ContactImage
                      img=""
                      text={cart.seller.name}
                      avatarColor={{ background: '#f0f0f0', text: '#333' }}
                    />
                  </S.ContactImagesRow>
                  <S.ContactNameColumn>
                    <S.TextBlack>{cart.seller.name}</S.TextBlack>
                  </S.ContactNameColumn>
                </S.Row>
              </S.ProductTd>

              <S.ProductTd>
                <S.Row>
                  <S.ContactImagesRow>
                    <S.ContactImage
                      iconName="Truck"
                      img=""
                      text={cart.buyerWarehouse.name}
                      avatarColor={{ text: '#666', background: '#f5f5f5' }}
                      type="other"
                      size={30}
                    />
                  </S.ContactImagesRow>
                  <S.ContactNameColumn>
                    <S.TextBlack>{cart.buyerWarehouse.name}</S.TextBlack>
                    {/* <S.TextSecondary>{cart.buyerWarehouse.id}</S.TextSecondary> TODO: add warehouse subtitle, backend should tell us */}
                  </S.ContactNameColumn>
                </S.Row>
              </S.ProductTd>

              <S.ProductTd>
                <S.Row>
                  <S.ContactImagesRow>
                    <S.ContactImage
                      iconName="Availability"
                      img=""
                      text=""
                      avatarColor={{ text: '#666', background: '#f5f5f5' }}
                      type="other"
                      size={30}
                    />
                  </S.ContactImagesRow>
                  <S.TextBlack>{deliveryDate}</S.TextBlack>
                </S.Row>
              </S.ProductTd>
            </S.DataRowHead>

            <S.TableContainer>
              <ItemsCard
                cart={{
                  key: `${cart.orderBuyerPreparationId}-${cart.buyerWarehouse.id}`,
                  contactId: cart.seller.id,
                  catalogId: cart.orderBuyerPreparationId,
                  items: cart.lines
                    .filter(line => line.orderedQuantity > 0)
                    .map(line => ({
                      id: line.id,
                      title: line.reference.name,
                      code: line.reference.buyerTag,
                      boxesPerPallet: line.offeredProduct.boxPerPallet,
                      boxWeight: line.offeredProduct.boxWeight,
                      unitsPerBox: line.offeredProduct.unitsPerBox,
                      amount: line.orderedQuantity || 0,
                      offeredDeparturePrice: line.offeredProduct.offeredDeparturePrice,
                      offeredPrice: line.offeredProduct.offeredPrice,
                      totalPrice: line.offeredProduct.offeredPrice * (line.orderedQuantity || 0),
                      priceUnit: line.offeredProduct.priceUnit,
                      currency: CURRENCY_CODES.EUR,
                      servedQuantity: line.orderedQuantity || 0,
                      saleUnit: line.offeredProduct.priceUnit,
                      saleUnits: [line.offeredProduct.priceUnit],
                      quantityUnit: line.offeredProduct.quantityUnit,
                      totalQuantityUnit: line.offeredProduct.totalQuantityUnit,
                      type: 'product',
                      isValid: true,
                      offeredQuantityUnit: line.offeredProduct.quantityUnit,
                    })),
                  priceMode: 'edit',
                }}
                cartUpdateItem={handleCartUpdate}
                showCustomColumns={false}
                weAreSeller={false}
              />
            </S.TableContainer>

            {orderExists ? (
              <S.ButtonsRow>
                <S.InfoCta onClick={changeTab}>{__('Components.OrderPreparation.add_reference')}</S.InfoCta>
                <S.ButtonsColumn>
                  <S.ButtonsWrapper>
                    <S.ActionButton type="secondary" id="edit-order" onClick={navigateToOrder}>
                      {__('Components.OrderPreparation.see_order')}
                    </S.ActionButton>
                    <S.ActionButton
                      type="principal"
                      id="send-offer-btc"
                      onClick={() => handleOrderCreate(true)}
                      disabled={!hasChanges}
                    >
                      {__('Components.OrderPreparation.save_changes')}
                    </S.ActionButton>
                  </S.ButtonsWrapper>
                  <S.InfoText>
                    {__('Components.OrderPreparation.to_confirm')}
                    <S.TextLink onClick={navigateToOrder}>{__('Components.OrderPreparation.current_order')}</S.TextLink>
                  </S.InfoText>
                </S.ButtonsColumn>
              </S.ButtonsRow>
            ) : (
              <S.ButtonsRow>
                <S.ActionButton type="secondary" id="edit-order" onClick={changeTab}>
                  {__('Components.OrderPreparation.add_reference')}
                </S.ActionButton>
                <S.ActionButton type="principal" id="send-offer-btc" onClick={() => handleOrderCreate(false)}>
                  {__('Components.OrderPreparation.create_pre_order')}
                </S.ActionButton>
              </S.ButtonsRow>
            )}
          </>
        )}
      </S.OrderCard>
    );
  } else {
    return null;
  }
};

export default OrderCard;
