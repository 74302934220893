import styled from 'styled-components';

export const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5;
`;

export const TextStrong = styled.span`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: bold;
  padding-right: 6px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5;
`;
