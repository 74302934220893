import { __, modalActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { IMAGES } from '../../../../../assets';
import { Select } from '../../../../atoms';
import { FormSection, InputWithLabel } from '../../../../molecules';
import { getSortItemsByOptions } from '../../WorkspaceSettingsSeller.utils';

interface IProps {
  catalog: IWorkspace;
  editMode: boolean;
  isLast: boolean;
  isPro: boolean;
  sectionRef: React.RefObject<HTMLDivElement>;
  updateCatalog: (catalog: IWorkspace) => void;
}

const OfferPreferences: React.FC<IProps> = ({ catalog, editMode, isLast, sectionRef, updateCatalog }) => {
  const dispatch = useDispatch();
  const numHeads = 1;
  return (
    <FormSection
      sectionRef={sectionRef}
      id="offer"
      title={__('WorkspaceSettings.Menu.Offer.title')}
      numHeaders={isLast ? numHeads : undefined}
    >
      <InputWithLabel
        isRequired={true}
        label={__('WorkspaceSettings.Offer.sort_items.title')}
        description={__('WorkspaceSettings.Offer.sort_items.description')}
        disabled={!editMode}
      >
        <Select
          name="sort_items_by"
          value={catalog.sortOfferItemsBy}
          options={getSortItemsByOptions()}
          onChange={(n, v) => {
            if (v !== catalog.sortOfferItemsBy) {
              dispatch(
                modalActions.modalOpen(
                  __('WorkspaceSettings.Offer.sort_items.modal_title', {
                    option: getSortItemsByOptions().find(o => o.value === v)?.label,
                  }),
                  () => {
                    updateCatalog({ ...catalog, sortOfferItemsBy: v as SortItemFilter });
                    dispatch(modalActions.modalClose());
                  },
                  {
                    icon: IMAGES.informativePineapple,
                    buttonText: __('WorkspaceSettings.Offer.sort_items.modal_cta'),
                    showCancelButton: true,
                  },
                  'nice',
                ),
              );
            }
          }}
          disabled={!editMode}
          containerMargin="4px 0"
          width="40%"
        />
      </InputWithLabel>
    </FormSection>
  );
};

export default React.memo(OfferPreferences);
