import { __, currency, i18n, IOfferedProduct, IReferenceSellerView, parsers, productService } from 'common-services';
import * as React from 'react';

import { unitTranslator } from '../../../util/unit';
import { Input, Select } from '../../atoms';
import { IColumn } from '../Table/Table.component';
import { checkRepeatedClientAssociations } from './Functions';
import * as S from './OfferedProductTable.styled';

export enum Availability {
  total = 'total',
  address = 'address',
}

export enum DepartureAndDeliveredPrice {
  departureAndDeliveredPrice = 'departureAndDeliveredPrice',
  departurePrice = 'departurePrice',
  deliveredPrice = 'deliveredPrice',
}
interface ColumnsProps {
  clientAssociations: Array<IReferenceSellerView>;
  products: Array<
    GenericProduct & {
      cellClassName?: string;
    }
  >;
  prodTypes: {
    [key: string]: IProdType;
  };
  toggleAssociateProductsEnabled: boolean;
  availability: Availability;
  editable: boolean;
  removeReference(sku: string, associatedReferenceId: number): void;
  setHasChanges: () => void;
  setSelectedProduct: (value: React.SetStateAction<GenericProduct>) => void;
  departureAndDeliveredPrice: DepartureAndDeliveredPrice;
  setProductOffer: (sku: string, name: string, value: number | string | boolean, warehouseId?: number) => void;
  offeredProducts: Array<IOfferedProduct>;
  setSelectAvailable: (value: React.SetStateAction<string>) => void;
  selectAvailable: string;
  deleteProduct: (id: string) => void;
  addresses: Array<IAddress>;
  pricePrecision: number;
}

export const getColumns = (props: ColumnsProps): Array<IColumn> => {
  const productColumns: Array<IColumn> = [
    {
      title: __('Components.ProductsList.Table.Sku'),
      id: 'sku',
      width: '80px',
      element: (data: GenericProduct) => {
        return <S.TextBlack>{data.sku}</S.TextBlack>;
      },
    },
    {
      title: __('Components.ProductsList.Table.TypeVariety'),
      id: 'name',
      width: '150px',
      element: (data: GenericProduct) => {
        const { hasRepeatedSkus, referenceSellerView } = checkRepeatedClientAssociations(
          data,
          props.clientAssociations,
          props.products,
        );
        return (
          <S.ProductWrapper>
            <S.TextBlack>
              {data.productTitle ||
                productService.getProductTypeVarietyDisplay(
                  data.type,
                  props.prodTypes[data.type]?.name || '',
                  data.variety,
                )}
            </S.TextBlack>
            {hasRepeatedSkus ? (
              <S.WarningRow>
                <S.WarningIcon name="Warning" disableHover={true} />
                <S.WarningText>
                  {__('Components.OffersList.table.warning', {
                    reference: referenceSellerView ? referenceSellerView.reference?.name : '',
                  })}
                </S.WarningText>
              </S.WarningRow>
            ) : null}
          </S.ProductWrapper>
        );
      },
    },
    ...(props.toggleAssociateProductsEnabled
      ? [
          {
            title: __('Components.OffersList.reference'),
            id: 'reference',
            width: '100px',
            info: __('Components.OffersList.table.tooltip'),
            element: (data: GenericProduct) => {
              const referenceSellerView = props.clientAssociations?.length
                ? props.clientAssociations?.find(ca => ca.sku === data.sku)
                : undefined;
              return referenceSellerView ? (
                <S.ReferenceRow>
                  <S.TextBlack>{referenceSellerView?.reference.name}</S.TextBlack>
                  {props.editable ? (
                    <S.CloseIcon
                      name="Close"
                      onClick={async () => {
                        props.removeReference(data.sku, referenceSellerView.reference.id);
                        props.setHasChanges();
                      }}
                    />
                  ) : null}
                </S.ReferenceRow>
              ) : props.editable ? (
                <S.ReferenceCta onClick={() => props.setSelectedProduct(data)}>
                  {__('Components.OffersList.table.associate_reference')}
                </S.ReferenceCta>
              ) : (
                <S.ZeroCaseReference>{'-'}</S.ZeroCaseReference>
              );
            },
          },
        ]
      : []),
    {
      title: __('Components.ProductsList.Table.Origin'),
      id: 'origin',
      width: '80px',
      element: (data: GenericProduct) => {
        const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);
        return <S.TextBlack disabled={hasRepeatedSkus}>{data.origin}</S.TextBlack>;
      },
    },
    {
      title: __('Components.OffersList.table.total_availability'),
      id: 'availability',
      width: '100px',
      element: (data: GenericProduct) => {
        const totalAvailability = props.offeredProducts
          .filter(p => p.sku === data.sku && p.unlimitedQuantity === false)
          .reduce((acc, product) => (acc === 0 ? product.offeredQuantity : acc + product.offeredQuantity), 0);
        const isUnlimited = props.offeredProducts.every(p => p.sku === data.sku && p.unlimitedQuantity === true);
        const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);
        return (
          <S.TextBold disabled={hasRepeatedSkus}>
            {isUnlimited
              ? __('Components.OffersList.table.unlimited')
              : `${totalAvailability || '-'} ${unitTranslator(data.saleUnit, totalAvailability)}`}
          </S.TextBold>
        );
      },
    },
  ];

  if (props.editable) {
    productColumns.unshift({
      title: '',
      id: 'delete',
      width: '50px',
      element: (data: GenericProduct) => {
        return (
          <S.IconWrapper>
            <S.RemoveIcon
              name="Close"
              onClick={e => {
                props.deleteProduct(data.sku);
                props.setHasChanges();
                e.stopPropagation();
              }}
            />
          </S.IconWrapper>
        );
      },
    });
  }
  if (
    props.addresses?.length > 0 &&
    !(props.availability === 'total' && props.departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice)
  ) {
    // aqui si availability = total y departureAndDeliveredPrice = departurePrice
    // if (false) {

    props.addresses?.map(address => {
      productColumns.push({
        title: __('Components.OffersList.table.unit_price'),
        id: 'price',
        width: '100px',
        cellClassName: 'offer-bordered-cell-left',
        element: (data: any, _, rowIdx: number) => {
          const priceValue =
            props.offeredProducts.find(p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id))
              ?.offeredPrice || 0;
          const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);
          if (
            (props.availability === Availability.total || props.availability === Availability.address) &&
            props.departureAndDeliveredPrice === DepartureAndDeliveredPrice.departurePrice
          ) {
            return <S.TextBold>-</S.TextBold>;
          }
          return props.editable ? (
            <Input
              id={`price-input-${rowIdx}`}
              name={'offeredPrice'}
              onBlur={(name, value: number) => {
                props.setProductOffer(data.sku, name, value, address.id !== -1 ? address.id : undefined);
                props.setHasChanges();
              }}
              value={priceValue}
              disabled={hasRepeatedSkus}
              zeroCase="0"
              precision={props.pricePrecision}
              variableTextSingular={currency.getPricePerUnit(data.currency, data.priceUnit, data.weightUnit)}
              width="120px"
              type="number"
              textAlign="left"
              hasError={false}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          ) : (
            <S.TextBlack>{`${priceValue || '-'} ${currency.getPricePerUnit(
              data.currency,
              data.priceUnit,
              data.weightUnit,
            )}`}</S.TextBlack>
          );
        },
      });

      productColumns.push({
        title: __('Components.OffersList.table.daily_availability'),
        id: 'daily-availability',
        width: '100px',
        cellClassName: 'offer-bordered-cell-right',
        element: (data: any, _, rowIdx: number) => {
          const offeredProduct = props.offeredProducts.find(
            p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id),
          );
          const unlimitedAvailability = offeredProduct?.unlimitedQuantity === true;
          const availabilityValue =
            props.offeredProducts.find(p => p.sku === data.sku && (address.id === -1 || p.warehouseId === address.id))
              ?.offeredQuantity || 0;

          if (props.editable) {
            const selectOptions = data.saleUnits.map(s => ({
              value: s,
              label: parsers.getUnitText(s, data.weightUnit, 0, i18n.getLanguageCode(i18n.default.currentLocale())),
            }));
            selectOptions.push({ value: 'unlimited', label: __('Components.OffersList.table.unlimited') });
            const { hasRepeatedSkus } = checkRepeatedClientAssociations(data, props.clientAssociations, props.products);
            return (
              <S.InputRow>
                {unlimitedAvailability ? null : (
                  <Input
                    id={`availability-input-${rowIdx}`}
                    name={'offeredQuantity'}
                    onChange={(name, value: number) => {
                      props.setProductOffer(data.sku, name, +value, address.id !== -1 ? address.id : undefined);
                      props.setHasChanges();
                    }}
                    disabled={hasRepeatedSkus}
                    value={availabilityValue}
                    containerMargin="0"
                    width="70px"
                    type="number"
                    textAlign="left"
                    hasError={false}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                )}
                <Select
                  containerMargin={unlimitedAvailability ? '0' : '0 6px'}
                  value={unlimitedAvailability ? 'unlimited' : offeredProduct?.offeredQuantityUnit}
                  name={`offeredQuantityUnit`}
                  dropUp={rowIdx >= props.products.length - 2 ? true : false}
                  options={selectOptions}
                  zIndex={3}
                  disabled={hasRepeatedSkus}
                  onChange={(name, value) => {
                    if (value === 'unlimited') {
                      props.setProductOffer(
                        data.sku,
                        'unlimitedQuantity',
                        true,
                        address.id !== -1 ? address.id : undefined,
                      );
                    } else {
                      props.setProductOffer(data.sku, name, value, address.id !== -1 ? address.id : undefined);
                      if (unlimitedAvailability) {
                        props.setProductOffer(
                          data.sku,
                          'unlimitedQuantity',
                          false,
                          address.id !== -1 ? address.id : undefined,
                        );
                      }
                    }
                    props.setHasChanges();
                  }}
                  width={unlimitedAvailability ? '165px' : '90px'}
                />
              </S.InputRow>
            );
          } else {
            return (
              <S.TextBlack>
                {unlimitedAvailability
                  ? __('Components.OffersList.table.unlimited')
                  : `${availabilityValue || '-'} ${parsers.getUnitText(
                      data.saleUnit,
                      data.weightUnit,
                      availabilityValue,
                      i18n.getLanguageCode(i18n.default.currentLocale()),
                    )}`}
              </S.TextBlack>
            );
          }
        },
      });
    });
  }

  return productColumns;
};
