import { __, EventTrack, i18n, LOCALE, qs, RenderTrack } from 'common-services';
import * as React from 'react';
import { setDefaultLocale } from 'react-datepicker';

import locales from '../../../../locales';
import { IMAGES } from '../../../assets';
import { getLanguageLiteral } from '../../../constants';
import Layout from '../../../layout-flex';
import { LandingFooter } from '../../molecules';
import LoginRegisterFlow from '../LoginRegisterFlow';

import * as S from './LoginLanding.styled';
import LoginLandingCarousel from './LoginLandingCarousel';

import type { navAuthAction, navResetAuthAction } from '../../../actions/nav';
import type {
  register,
  resendCode,
  resetUser,
  setRegisterInfo,
  updatePassword,
  verifyEmailCode,
  verifyPhone,
  verifyPhoneCode,
  webloginOauth,
  webloginPassword,
} from '../../../actions/user';
import type { REGISTER_STEPS } from '../../../constants';
import type {
  contactActions,
  countryActions,
  ICountry,
  IGlobalWeb,
  IUser,
  modalActions,
  notificationsActions,
  prodTypeActions,
  userActions,
} from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';

declare let global: IGlobalWeb;

export interface IStateProps {
  authStep: REGISTER_STEPS;
  contactUrl?: string;
  countries: { [k: string]: ICountry };
  error?: string;
  loading: boolean;
  me: IUser;
  redirect?: () => void;
}

export interface IDispatchProps {
  addContactByPricelistUrl: typeof contactActions.addContactByPricelistUrl;
  countriesGet: typeof countryActions.countriesGet;
  hideLoading: typeof modalActions.hideLoading;
  hideLogin: () => void;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navAuthAction: typeof navAuthAction;
  navResetAuthAction: typeof navResetAuthAction;
  notificationShow: typeof notificationsActions.notificationShow;
  prodTypesGet: typeof prodTypeActions.prodTypesGet;
  register: typeof register;
  resendCode: typeof resendCode;
  resetPassword: typeof userActions.resetPassword;
  resetUser: typeof resetUser;
  scanContacts: typeof contactActions.scanContacts;
  setRegisterInfo: typeof setRegisterInfo;
  showLoading: typeof modalActions.showLoading;
  showLogin: typeof userActions.showLogin;
  updatePassword: typeof updatePassword;
  updateSettings: typeof userActions.updateSettings;
  userExists: typeof userActions.userExists;
  isEmailValid: typeof userActions.isEmailValid;
  userSet: typeof userActions.userSet;
  verifyEmailCode: typeof verifyEmailCode;
  verifyPhone: typeof verifyPhone;
  verifyPhoneCode: typeof verifyPhoneCode;
  verifyUser: typeof userActions.verifyUserData;
  webloginOauth: typeof webloginOauth;
  webloginPassword: typeof webloginPassword;
}

export type IProps = IStateProps & IDispatchProps & RouteComponentProps<{}>;

interface IState {
  lang: LOCALE;
}

export default class LoginLanding extends React.PureComponent<IProps, IState> {
  private t: number;
  constructor(props: IProps) {
    super(props);
    this.t = Date.now();

    const params = qs.parse(window.location.search || '') as { lang?: string };
    this.state = {
      lang: i18n.getLanguageCode(
        params?.lang || global.localStorage.getItem('language') || window.navigator.language.substring(0, 2),
      ),
    };
  }

  public componentDidMount(): void {
    RenderTrack.track('LoginLanding', { renderTime: this.t });
  }

  public render() {
    const { authStep } = this.props;
    const { lang } = this.state;

    return (
      <Layout header={{ show: false, tabSelected: 'chat' }} loginModal={false}>
        <S.Container>
          <S.HeaderBackground>
            <S.BackgroundDiv />
            <S.Header>
              <S.HeaderRow>
                <S.HeaderLeft className="login-landing-header-left">
                  <LoginLandingCarousel />
                </S.HeaderLeft>
                <S.HeaderRight authStep={authStep} className="login-landing-header-right">
                  <S.ConsentioLogo src={IMAGES.login.consentioLogo} authStep={authStep} />
                  <LoginRegisterFlow {...this.props} lang={lang} />
                </S.HeaderRight>
              </S.HeaderRow>
            </S.Header>
            <S.LanguageSelect
              className="login-landing-language-select"
              label={__('PublicShowroom.Tabs.lang', { lang: lang.toUpperCase() })}
              onSelect={l => this.onChangeLang(l)}
              options={Object.keys(LOCALE)
                .sort()
                .map(l => ({ key: LOCALE[l], value: `${getLanguageLiteral(LOCALE[l])} (${l})` }))}
            />
          </S.HeaderBackground>
          <LandingFooter
            className="login-landing-footer"
            lang={i18n.default.currentLocale() as LOCALE}
            trackEvent={(key: string, url: string) => {
              EventTrack.track('login_landing_click_cta', {
                url,
                action: key,
                section: 'footer',
                type: 'link',
                lang,
              });
            }}
          />
        </S.Container>
      </Layout>
    );
  }

  /**
   * On change lang (refetch countries and product types)
   */
  private onChangeLang = (locale: LOCALE): void => {
    const { countriesGet, history, prodTypesGet } = this.props;
    locales(locale as LOCALE);
    global.localStorage.setItem('language', locale);
    prodTypesGet(locale);
    countriesGet(locale);
    setDefaultLocale(locale);
    this.setState({ lang: locale });

    EventTrack.track('change_language', {
      lang: locale,
      from: 'login-landing',
    });
    history.push({ search: qs.stringify({ lang: locale }) });
  };
}
