import {
  __,
  constants,
  CURRENCY_CODES,
  date,
  debounce,
  EventTrack,
  i18n,
  mocks,
  parsers,
  productService,
  qs,
  realtime,
  RenderTrack,
  sellerWorkspaceService,
  throttle,
  WEIGHT_UNIT,
} from 'common-services';
import { APIToProduct } from 'common-services/dist/api-parsers';
import { messageTypes } from 'common-services/dist/realtime';
import * as React from 'react';

import config from '../../../../bindings/config';
import { ROUTE_PATHS } from '../../../constants';
import ShareShowroom from '../../../screens/product-list-share';
import { logError } from '../../../services/log';
import { api } from '../../../store';
import { sizes } from '../../../theme';
import getPath from '../../../util/routes';
import { deviceIsIpad } from '../../../util/utils';
import { FontIcon, SimpleDropdown } from '../../atoms';
import { ActionsModal, NonResults, ProductFilter, ProductTable, SimpleSearch } from '../../molecules';
import ProductEdit from '../ProductEdit';
import ProductInfo from '../ProductInfo/ProductInfo.component';
import Workspace from '../Workspace/Workspace.component';

import { downloadCostExport, downloadExport } from './functions';
import * as S from './WorkspaceProducts.styled';

import type * as userAppActions from '../../../actions/user';
import type {
  buyerWorkspaceActions,
  costActions,
  imageActions,
  LOCALE,
  modalActions,
  notificationsActions,
  productActions,
  sellerWorkspaceActions,
  tagActions,
  userActions,
} from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';

export interface IStateProps {
  addresses: Array<IAddress>;
  catalogs: Record<string, IWorkspace>;
  catalogAssignedId: number;
  catalog: IWorkspace;
  contacts: { [id: number]: IContact };
  countries: { [k: string]: ICountry };
  lang: LOCALE;
  me: IUser;
  priceGroups: { [priceGroupId: string]: IPriceGroup };
  prices: { [key: number]: IPrice };
  prodTypes: { [key: string]: IProdType };
  tags: Array<string>;
}

export interface IDispatchProps {
  catalogAssign: typeof sellerWorkspaceActions.catalogAssign;
  catalogMemberDelete: typeof sellerWorkspaceActions.catalogMemberDelete;
  catalogMembersUpdate: typeof sellerWorkspaceActions.catalogMembersUpdate;
  catalogSync: typeof sellerWorkspaceActions.catalogSync;
  exportProducts: typeof productActions.catalogExportProducts;
  costExport: typeof costActions.costExportProducts;
  getPrices: typeof productActions.getPrices;
  mediaUpload: typeof imageActions.mediaUploadWithProgress;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  notificationShow: typeof notificationsActions.notificationShow;
  priceGroupAddPrices: typeof sellerWorkspaceActions.priceGroupAddPrices;
  priceGroupDeletePrice: typeof sellerWorkspaceActions.priceGroupDeletePrice;
  priceGroupsGet: typeof sellerWorkspaceActions.priceGroupsGet;
  pricesAddProduct: typeof sellerWorkspaceActions.pricesAddProduct;
  pricesGetFromProduct: typeof sellerWorkspaceActions.pricesGetFromProduct;
  productCreate: typeof productActions.productCreate;
  productDeleteFile: typeof productActions.productDeleteFile;
  productRemove: typeof productActions.productRemove;
  productUpdate: typeof productActions.productUpdate;
  requestProInfo: typeof userActions.requestProInfo;
  supportAction: typeof userAppActions.supportAction;
  tagsGet: typeof tagActions.tagsGet;
  touchImage: typeof modalActions.touchImage;
  workspaceAddressesGet: typeof buyerWorkspaceActions.workspaceAddressesGet;
}

export type IProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ contactId: string; productId: string; workspaceId: string }>;

interface IState {
  facets: IFacets;
  facetsGlobal: IFacets;
  filterSelected: 'all' | 'active' | 'inactive';
  hasMore?: boolean;
  hideFilters?: boolean;
  hideList?: boolean;
  isSearched?: boolean;
  prodTypes: { [key: string]: IProdType };
  products: Array<IProduct>;
  productSelected?: number | 'new' | 'clone';
  productToShow?: IProduct;
  productToShareHash?: string;
  productToShareId?: number;
  searchId?: string;
  searchState: ISearch;
  showPriceGroups?: boolean;
  showShare?: boolean;
  sortMode: string;
  totalResults: number;
  originLot?: string;
  boxTypes?: Array<ICustomBoxType>;
  palletTypes?: Array<ICustomPalletType>;
}

class WorkspaceProducts extends React.PureComponent<IProps, IState> {
  private t: number;
  private sendSearch: () => void = debounce(() => {
    const { history, me, location } = this.props;
    const { searchState } = this.state;
    history.replace(location.pathname + qs.stringify({ query: searchState.text }));
    this.setState({ isSearched: false });
    productService
      .productSearch(searchState, searchState.language, config.SEARCH_PREFIX, me.id, api, true)
      .then(this.onReceiveProducts);
  }, 200);

  private scrollSearch = throttle(() => {
    const { searchId } = this.state;
    productService.productScrollSearch(searchId, api).then(this.onReceiveProducts);
  }, 200);

  constructor(props: IProps) {
    super(props);
    this.t = Date.now();
    const { originLot } = qs.parse(props.location.search, ['originLot']) as {
      originLot?: string;
    };
    const text = (qs.parse(window.location.search, ['query']) as { query: string })?.query || '';
    this.state = {
      filterSelected: 'all',
      prodTypes: props.prodTypes,
      productSelected: Number(props.match.params.productId) || (props.match.params.productId as 'new'),
      sortMode: `title_sort.${i18n.default.currentLocale()} asc`,
      searchState: {
        text,
        language: props.me.settings.language as LOCALE,
        index: props.catalog ? props.catalog.hashId : props.me.sellerWorkspaceHashId,
        status: [],
        sort: text ? '' : `title_sort.${i18n.default.currentLocale()}`,
        sortOrder: 'asc',
      },
      totalResults: 0,
      products: [],
      facets: {},
      facetsGlobal: {},
      hideFilters: typeof window !== 'undefined' && window.innerWidth > sizes.ipad,
      originLot,
    };
  }

  public componentDidMount() {
    RenderTrack.track('WorkspaceProducts', {
      renderTime: this.t,
    });
    const { catalog, catalogAssignedId, getPrices, me, priceGroupsGet } = this.props;
    const { productSelected, products } = this.state;
    if (catalog) {
      getPrices(catalogAssignedId, me.id!);
      api.product.getBoxTypes(me.id!, catalogAssignedId).then(res => {
        this.setState({ boxTypes: res });
      });
      api.product.getPalletTypes(me.id!, catalogAssignedId).then(res => {
        this.setState({ palletTypes: res });
      });

      this.sendSearch();
      if (sellerWorkspaceService.isProPlan(catalog.plan)) {
        priceGroupsGet(catalog.id);
      }
    }
    if (productSelected) {
      const product = products.find(p => p.id === productSelected);

      this.setState({
        productToShow:
          productSelected && (product || productSelected === 'new' || productSelected === 'clone')
            ? this.showProducts()
            : undefined,
      });
    }
    realtime.addEventListener(messageTypes.productsSync, this.onProductGet);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { catalogAssignedId, catalog, getPrices, me, match, priceGroupsGet } = this.props;
    const { searchId, products, productSelected, productToShow } = this.state;
    if (prevProps.match.params.productId !== match.params.productId) {
      this.setState({ productSelected: Number(match.params.productId) || (match.params.productId as 'new') });
    }
    if (prevState.productSelected !== this.state.productSelected || !productToShow) {
      const product = products.find(p => p.id === productSelected);
      this.setState({
        productToShow:
          productSelected && (product || productSelected === 'new' || productSelected === 'clone')
            ? this.showProducts()
            : undefined,
      });
      if (!this.state.productSelected) {
        this.setState({ productToShow: undefined });
      }
    }

    const { filterSelected, searchState, sortMode } = this.state;
    if (
      filterSelected !== prevState.filterSelected ||
      sortMode !== prevState.sortMode ||
      catalogAssignedId !== prevProps.catalogAssignedId ||
      catalog?.hashId !== prevProps.catalog?.hashId
    ) {
      if (catalogAssignedId !== prevProps.catalogAssignedId || !prevProps.catalog) {
        getPrices(catalogAssignedId, me.id!);
        api.product.getBoxTypes(me.id!, catalogAssignedId).then(res => {
          this.setState({ boxTypes: res });
        });
        api.product.getPalletTypes(me.id!, catalogAssignedId).then(res => {
          this.setState({ palletTypes: res });
        });
      }
      if (catalog && sellerWorkspaceService.isProPlan(catalog.plan)) {
        priceGroupsGet(catalog.id);
      }
      this.setState({
        searchState: {
          ...searchState,
          index: catalog ? catalog.hashId : me.sellerWorkspaceHashId,
          status: filterSelected !== 'all' ? [filterSelected] : [],
          sort: sortMode ? sortMode.split(' ')[0] : '',
          sortOrder: (sortMode ? sortMode.split(' ')[1] : 'desc') as 'asc' | 'desc',
        },
      });
    }
    if (searchState !== prevState.searchState) {
      this.sendSearch();
    }
    if (searchId && prevState.searchId !== searchId) {
      this.setState({ isSearched: true });
    }
  }

  public componentWillUnmount(): void {
    const { catalog, catalogSync } = this.props;
    if (catalog?.showZeroCaseTooltip) {
      catalogSync({ ...catalog, showZeroCaseTooltip: false });
    }
    realtime.removeEventListener(messageTypes.productsSync, this.onProductGet);
  }

  public render() {
    const {
      addresses,
      catalogAssignedId,
      catalogs,
      countries,
      history,
      me,
      modalClose,
      modalOpen,
      notificationShow,
      priceGroups,
      prices,
      pricesAddProduct,
      prodTypes,
      requestProInfo,
      touchImage,
    } = this.props;
    const {
      boxTypes,
      facets,
      facetsGlobal,
      hideFilters,
      hideList,
      isSearched,
      originLot,
      palletTypes,
      products,
      productSelected,
      productToShow,
      searchState,
      showPriceGroups,
      showShare,
      totalResults,
    } = this.state;
    const catalog = this.props.catalog || catalogs[me.sellerWorkspaceId];
    const role = this.getMyRole();
    const isQuoterMode = (config.TOGGLE_MARGINS.enabled && catalog?.plan?.addons?.quoterMarginsEnabled) || false;
    const title = this.getTitle(productSelected, productToShow);
    const subtitle = this.getSubTitle(productToShow);

    const isZeroCase = !products.length && !searchState.text && !this.getFiltersCount();
    const priceGroupsTitle = isQuoterMode ? __('WorkspaceMargins.title') : __('Components.Header.WorkspacePriceGroups');
    return (
      <Workspace
        parentSections={
          productSelected && productToShow
            ? [
                {
                  label: __('Components.Header.WorkspaceProducts'),
                  action: () => {
                    this.setState({ showPriceGroups: false });
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                        workspaceId: catalog.id + '',
                      }),
                    );
                  },
                },
                ...(showPriceGroups
                  ? [
                      {
                        label: `${title} ${subtitle}`,
                        action: () => this.setState({ showPriceGroups: false }),
                      },
                    ]
                  : []),
              ]
            : undefined
        }
        subtitle={showPriceGroups ? '' : subtitle}
        title={showPriceGroups ? priceGroupsTitle : title}
        tabSelected="products"
        workspaceId={catalogAssignedId}
      >
        {hideList ? null : (
          <S.Row>
            <ProductFilter
              changeSearchState={s => this.setState({ searchState: s })}
              clearFilters={() => {
                EventTrack.track('workspace_products_filter_clear', {
                  workspace_id: catalog.id,
                });

                this.setState({
                  searchState: {
                    text: searchState.text,
                    language: searchState.language,
                    index: searchState.index,
                    status: [],
                    sort: searchState.text ? '' : searchState.sort,
                  },
                });
              }}
              facets={facets}
              facetsGlobal={facetsGlobal}
              me={me}
              numberOfHeaders={3}
              searchState={searchState}
              onHide={h => this.setState({ hideFilters: h })}
              showClosed={hideFilters}
              showOver="only-ipad"
              boxTypes={boxTypes}
              palletTypes={palletTypes}
            />
            <S.Column>
              {this.renderProductsSubheader(role, isZeroCase)}
              <NonResults
                amSeller={true}
                buttonAction={() =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                      workspaceId: catalogAssignedId + '',
                      productId: 'new',
                    }),
                  )
                }
                hasHits={isSearched && products ? !!products.length : true}
                searchText={searchState.text}
                isZeroCase={isZeroCase && role !== 'viewer'}
              >
                <ProductTable
                  addresses={addresses}
                  catalog={catalog}
                  clickProduct={this.onProductClick}
                  configId="product_list"
                  disabled={role === 'viewer'}
                  me={me}
                  onScroll={this.onScroll}
                  onShareProduct={this.onShareSearch}
                  place="private"
                  products={products.map(p => ({
                    ...p,
                    price: prices[p.id] ? prices[p.id].price : 0,
                    currency: prices[p.id] ? prices[p.id].currency : p.currency,
                  }))}
                  precision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
                  productUpdate={this.onProductUpdate}
                  role={role}
                  searchState={searchState}
                  setFocus={this.setProductFocus}
                  showCustomColumns={true}
                  totalResults={totalResults}
                  touchImage={touchImage}
                  updateSortMode={this.updateSortMode}
                />
              </NonResults>
            </S.Column>
          </S.Row>
        )}
        {productSelected && productToShow ? (
          <S.ProductDetails>
            {role !== 'viewer' ? (
              <ProductEdit
                {...this.props}
                boxTypes={boxTypes}
                catalogId={catalogAssignedId}
                me={me}
                notificationShow={notificationShow}
                palletTypes={palletTypes}
                priceGroups={priceGroups}
                product={productToShow}
                productClone={this.onProductClone}
                productCreate={this.onProductCreate}
                productUpdate={this.onProductUpdate}
                productRemove={this.onProductRemove}
                pricesAddProduct={pricesAddProduct}
                requestProInfo={requestProInfo}
                setShowPriceGroups={show => this.setState({ showPriceGroups: show })}
                showPriceGroups={showPriceGroups}
                hasChanges={!!originLot}
              />
            ) : (
              <ActionsModal onClose={() => history.goBack()} title="">
                <ProductInfo
                  amSeller={true}
                  contactId={0}
                  contactName=""
                  isContactUnregistered={false}
                  catalogId={me.sellerWorkspaceId}
                  close={history.goBack}
                  countries={countries}
                  from="product"
                  history={history}
                  isPublic={true}
                  me={me}
                  modalClose={modalClose}
                  modalOpen={modalOpen}
                  pricePrecision={catalog ? catalog?.numberOfDecimalsShowed : constants.PRICE_PRECISION}
                  prodTypes={prodTypes}
                  product={parsers.productToGenericProduct(productToShow)}
                  showBack={false}
                  showDefaultPicture={true}
                  showFeatured={false}
                  showShare={true}
                  touchImage={touchImage}
                />
              </ActionsModal>
            )}
          </S.ProductDetails>
        ) : null}
        {showShare ? this.renderShareModal() : null}
        {this.renderTutorial()}
      </Workspace>
    );
  }

  private updateSortMode = (sortMode: string) => {
    const { catalog } = this.props;
    this.setState({ sortMode });
    EventTrack.track('workspace_products_sort', { workspace_id: catalog.id, sort: sortMode });
  };

  /**
   * Returns my role in the workspace
   */
  private getMyRole = () => {
    const { catalogs, me } = this.props;
    const catalog = this.props.catalog || catalogs[me.sellerWorkspaceId];
    const member = sellerWorkspaceService.getMember(catalog, me.id);
    return member ? member.role : 'viewer';
  };

  /**
   *  Render products management subheader
   */
  private renderProductsSubheader = (role: ICatalogRole, isZeroCase: boolean) => {
    const { catalogAssignedId, history } = this.props;
    const { hideFilters, products, searchState } = this.state;
    return (
      <S.SubheaderCustom>
        <S.SubheaderLeftSecond>
          <S.SubheaderLeftSearch>
            <S.FilterButton
              filtersOpened={!hideFilters}
              filtersSelected={this.getFiltersCount()}
              onClick={() => this.setState({ hideFilters: !hideFilters })}
            />
            <SimpleSearch
              id="search-products"
              query={searchState.text}
              placeHolder={__('Components.ProductsList.search.placeholder')}
              onChange={(t: string) =>
                this.setState({ searchState: { ...searchState, text: t, sort: t ? '' : searchState.sort } })
              }
              RightButton={searchState.text && products.length ? () => this.renderShareButton(true) : undefined}
            />
          </S.SubheaderLeftSearch>
          <S.SubheaderLeftFilters>{!searchState.text ? this.renderShareButton(false) : null}</S.SubheaderLeftFilters>
        </S.SubheaderLeftSecond>
        <S.Sections iconName="Section-settings" type="secondary" iconSize="16px" onClick={this.goToSections}>
          {__('Components.ProductsList.ManageSections')}
        </S.Sections>
        {role !== 'viewer' ? (
          <S.SubheaderRight>
            {isZeroCase ? null : (
              <S.CreateButton
                id="poducts-list-cta-create"
                onClick={() =>
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
                      workspaceId: catalogAssignedId + '',
                      productId: 'new',
                    }),
                  )
                }
              >
                {__('Components.ProductsList.CreateProduct')}
              </S.CreateButton>
            )}
            <SimpleDropdown
              hAlign="right"
              onSelect={(name: string) => this.handleExtendedOptions(name)}
              options={[
                { key: 'import', value: __('Components.ProductsList.Import') },
                { key: 'export', value: __('Components.ProductsList.Export') },
                { key: 'costImport', value: __('Components.ProductsList.costImport') },
                { key: 'costExport', value: __('Components.ProductsList.costExport') },
              ]}
            >
              <S.FontIconWrapper>
                <FontIcon id="products-Kebab" name="Kebab" />
              </S.FontIconWrapper>
            </SimpleDropdown>
          </S.SubheaderRight>
        ) : null}
      </S.SubheaderCustom>
    );
  };

  private getTitle = (productId?: number | 'new' | 'clone', product?: IProduct) => {
    const { prodTypes } = this.props;
    if (!productId || !product) return __('Components.Header.WorkspaceProducts');
    if (product?.productTitle) return '';
    return product?.id
      ? product.type.type !== '-'
        ? prodTypes[product.type.type].name
        : ''
      : __('Components.ProductDetails.create');
  };

  private getSubTitle = (product: IProduct) => {
    return !product?.id ? '' : product.productTitle || product.type.variety;
  };

  /**
   * On click product, go to the product's details
   */
  private onProductClick = (productId: number) => {
    const { catalogAssignedId, history } = this.props;
    // do not navigate when user is selecting text
    if (!window.getSelection || window.getSelection().toString() === '') {
      history.push(
        getPath({
          path: ROUTE_PATHS.WORKSPACE_PRODUCTS,
          workspaceId: catalogAssignedId + '',
          productId: productId + '',
        }),
      );
    }
  };

  /**
   * Focus a product element
   */
  private setProductFocus = (selector: string) => {
    if (selector) {
      const nextElement = document.querySelector(selector) as HTMLInputElement;
      if (nextElement) {
        nextElement.focus();
        deviceIsIpad() ? nextElement.setSelectionRange(0, nextElement.value.length) : nextElement.select?.();
      }
    }
  };

  /**
   * Render the share button for the search or for the all the products
   */
  private renderShareButton(isSearch?: boolean) {
    const { catalog } = this.props;
    if (catalog?.status === 'inactive') return null;
    return (
      <S.ShareTouchable
        id="team-products-share-catalog"
        type="link"
        onClick={() => this.onShareSearch()}
        iconName="Share"
        iconSize="14px"
        withoutPadding={true}
      >
        {isSearch ? __('Components.ProductListShare.share_search') : __('Components.ProductListShare.share_showroom')}
      </S.ShareTouchable>
    );
  }

  /**
   * Go to workspace sections
   */
  private goToSections = () => {
    const { catalogAssignedId, history } = this.props;
    history.push(getPath({ path: ROUTE_PATHS.WORKSPACE_SECTIONS, workspaceId: catalogAssignedId + '' }));
  };

  /**
   * Render the share tutorial.
   */
  private renderTutorial() {
    // TODO Temporal disabled
    // const { lang } = this.props;
    // return <Tutorial icon="Share-web" name="share" lang={lang} />;
    return null;
  }

  /**
   * handle on click in import/export option
   */
  private handleExtendedOptions(op: string): void {
    const { history, catalogAssignedId, me, catalog, exportProducts, costExport } = this.props;
    switch (op) {
      case 'import':
        history.push(getPath({ path: ROUTE_PATHS.PRODUCTS_IMPORT, workspaceId: catalogAssignedId + '' }));
        break;
      case 'export':
        downloadExport(me, catalog, exportProducts);
        break;
      case 'costImport':
        history.push(getPath({ path: ROUTE_PATHS.COST_IMPORT, workspaceId: `${me.sellerWorkspaceId}` }));
        break;
      case 'costExport':
        downloadCostExport(me.sellerWorkspaceId, costExport);
        break;
      default:
        logError(new Error('unknown option: ' + op), 'extended.options');
        break;
    }
  }

  /**
   * Get product's filters count
   */
  private getFiltersCount = () => {
    const { searchState } = this.state;
    const { brand, boxId, category, organic, origin, size, type, status, variety, packaging } = searchState;

    return (
      (origin?.length || 0) +
      (organic?.length || 0) +
      (brand?.length || 0) +
      (type?.length || 0) +
      (size?.length || 0) +
      (boxId?.length || 0) +
      (status?.length || 0) +
      (variety?.length || 0) +
      (packaging?.length || 0) +
      (category?.length || 0)
    );
  };

  /**
   * Render share product modal
   */
  private renderShareModal() {
    const { catalog, history, me } = this.props;
    const { products, totalResults } = this.state;
    const {
      productToShareId: productToShare,
      productToShareHash,
      searchState: { text },
    } = this.state;
    return (
      <ShareShowroom
        amSeller={true}
        close={this.closeShareSearchModal}
        history={history}
        ownerId={me.id}
        catalogId={catalog.id}
        product={
          productToShare ? parsers.productToGenericProduct(products.find(p => p.id === productToShare)) : undefined
        }
        productHashId={productToShareHash}
        numResults={totalResults}
        search={text}
      />
    );
  }

  /**
   * On share product, opens modal
   */
  private onShareSearch = (productId?: number, productHashId?: string) => {
    this.setState({ showShare: true, productToShareId: productId, productToShareHash: productHashId });
  };

  /**
   * Close share product modal
   */
  private closeShareSearchModal = () => {
    this.setState({ showShare: false, productToShareId: undefined, productToShareHash: undefined });
  };

  /**
   * Receive data for search with elastic
   */
  private onReceiveProducts = (res: ISearchData<IProduct>) => {
    if (!res) return;
    const { data, toReplace, facets, facetsGlobal } = res;
    const { catalog } = this.props;
    const { searchState } = this.state;
    const filters = productService.getTrackedFilters(searchState);
    if (filters.length) {
      EventTrack.track('workspace_products_filter', {
        workspace_id: catalog.id,
        results: data.totalResults,
        filters,
      });
    }
    if (toReplace) {
      this.setState({
        products: data.hits,
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
        facets,
        facetsGlobal,
      });
    } else {
      this.setState({
        products: [...this.state.products, ...data.hits],
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
      });
    }
  };

  /**
   * handle the scroll event for sticky the searchbar
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { hasMore } = this.state;
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      this.scrollSearch();
    }
  };

  /**
   * Handle update products in the productlist
   */
  private onProductUpdate = (workdpaceId: number, product: IProduct, cb?: () => void) => {
    const { catalogAssignedId, getPrices, history, me, productUpdate } = this.props;
    productUpdate(
      workdpaceId,
      product,
      cb
        ? () => {
            cb();
            history.goBack();
            getPrices(catalogAssignedId, me.id!);
          }
        : undefined,
    );
  };

  /**
   * Handle create products in the productlist
   */
  private onProductCreate = (workdpaceId: number, product: IProduct, cb?: (p?: IProduct) => void) => {
    const { catalogAssignedId, getPrices, history, me, productCreate } = this.props;
    productCreate(workdpaceId, product, (p?: IProduct) => {
      cb(p);
      history.goBack();
      getPrices(catalogAssignedId, me.id!);
      this.sendSearch();
    });
  };
  private onProductRemove = (workdpaceId: number, productId: number, cb?: () => void) => {
    const { history, productRemove } = this.props;
    productRemove(workdpaceId, productId, () => {
      cb();
      history.goBack();
      this.sendSearch();
    });
  };

  /**
   * Handle clone products in the productlist
   */
  private onProductClone = () => {
    this.setState({ productSelected: 'clone' });
  };

  private onProductGet = (data: MessageRt) => {
    const { products } = this.state;
    const newProducts = [...products];
    data.action.products?.map(APIToProduct).forEach(product => {
      const idx = newProducts.findIndex(p => p.id === product.id);
      if (idx !== -1) {
        newProducts[idx] = { ...product, updatedAt: date.formatDate(new Date().getTime(), date.DATE_ISO_FORMAT) };
      }
    });
    this.setState({ products: newProducts });
  };
  private showProducts = (): IProduct => {
    const { catalog, me, prices } = this.props;
    const { productSelected, products, originLot } = this.state;
    const product =
      products.find(p => p.id === productSelected) ||
      mocks.productEmpty(me.id, catalog?.defaultWeightUnit || WEIGHT_UNIT.KG);
    return {
      ...product,
      price: prices[productSelected] ? prices[productSelected].price : 0,
      currency: prices[productSelected]
        ? prices[productSelected].currency
        : catalog?.defaultCurrency || CURRENCY_CODES.EUR,
      ...(originLot && !product.originLots?.includes(Number(originLot))
        ? productSelected === 'new'
          ? { originLots: [Number(originLot)] }
          : { originLots: [...(product.originLots || []), Number(originLot)] }
        : {}),
    };
  };
}

export default WorkspaceProducts;
