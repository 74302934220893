import { __, EventTrack, orderService, RenderTrack } from 'common-services';
import * as React from 'react';

import { AVAILABILITY } from '../../../../../../../constants';
import { calculatePriceServedUnit } from '../../../../../../../domain/order';
import { Table } from '../../../../../../molecules';
import { useTableColumns } from '../../../../../../molecules/Table';
import { useOrderContext } from '../../../../context';

import { buyerOfferingColumnSpecs, defaultColumnSpecs, sellerOfferingColumnSpecs } from './columns';
import { PriceDisplay, Header } from './elements';
import { useTableConfig } from './hooks/useColumnsConfig';
import { useColumnProps } from './hooks/useColumnsProps';
import { usePriceCalculation } from './hooks/usePriceCalculation';
import { useSortedItems } from './hooks/useSortedItems';
import * as S from './OrderItems.styled';

import type { IOrderItem, SortItemFilter } from 'common-services';

const OrderItems: React.FC = React.memo(() => {
  const {
    me,
    order,
    cart,
    catalog,
    isCatalogInactive: inactiveCatalog,
    contactId,
    self: isSelf,
    priceMode,
    previewMode,
    pricePrecision,
    weAreSeller,
    isPrepare,
    amEditor,
    canEditAfterAccept: editAfterAccept,
    setCanEditAfterAccept,
    isFavoriteChecked,
    setIsFavoriteChecked,
    prodTypes,
    cartSet,
    orderToggleReadStatus,
    navShowroom: navigateToShowroom,
    showProductFromItem: navigateToProductInfo,
    columnConfig,
    anyItemWithEmptyPrice,
    isQuoterMode,
    orderEditable,
    isBuyerOfferingView,
    canServeOrder,
    cartKey,
    customItems,
    notMarkAsRead,
  } = useOrderContext();

  // Constants
  const onModifyAfterAccept = (): void => setCanEditAfterAccept(true);
  const hideTotal = !!customItems?.length;
  const showCustomColumns = true;
  const myRole = catalog?.members.find(m => m.userId === me.id)?.role || 'viewer';
  const canEdit = amEditor && orderEditable && contactId && !inactiveCatalog;
  const amWorkspaceEditor = ['admin', 'editor'].includes(myRole);

  // Hooks
  const { totalPrice, shippingCost, price } = usePriceCalculation(
    // TODO: this is a temporary fix for isBuyerOfferingView
    isBuyerOfferingView
      ? {
          ...order,
          price: 0,
          items: order.items.map(item => ({
            ...item,
            isPor: false,
            totalPrice: calculatePriceServedUnit(
              isBuyerOfferingView && item.price === 0
                ? {
                    ...item,
                    price: item.departurePrice,
                    servedPrice: item.departurePrice,
                  }
                : item,
              item.boxesPerPallet,
            ),
            pricePrecision,
          })),
        }
      : order,
    isBuyerOfferingView
      ? cart.items.map(item => ({
          ...item,
          isPor: false,
          totalPrice: calculatePriceServedUnit(
            isBuyerOfferingView && item.price === 0
              ? {
                  ...item,
                  price: item.departurePrice,
                  servedPrice: item.departurePrice,
                }
              : item,
            item.boxesPerPallet,
          ),
          pricePrecision,
        }))
      : cart.items,
    pricePrecision,
  );
  const totalMargin = price && isQuoterMode ? orderService.getTotalMargin(order, pricePrecision) : undefined;
  const { setSortItemsFilter, sortOptions, sortedItems, selectedSortLabel } = useSortedItems({
    items: cart.items,
    catalog,
    prodTypes,
  });
  const { productColumns, columnsConfig, areLinesClickable, fixedColumns } = useTableConfig({
    weAreSeller,
    isQuoterMode,
    columnConfig,
    order,
  });

  const columnSpecs = isBuyerOfferingView
    ? !weAreSeller
      ? buyerOfferingColumnSpecs
      : sellerOfferingColumnSpecs
    : defaultColumnSpecs;
  const columns = useTableColumns(columnsConfig, columnSpecs, useOrderContext, useColumnProps);

  // Handlers
  const handleSortChange = React.useCallback(
    (value: string) => {
      setSortItemsFilter(value as SortItemFilter);
      EventTrack.track('order_items_sort', { order_id: order?.id, sort: value });
    },
    [order?.id, setSortItemsFilter],
  );

  const handleRowClick = React.useCallback(
    (el: IOrderItem) => {
      const clickable = order?.issues.filter(
        issue => issue.orderItemId === el.id && issue.type !== 'no-pricegroup-matching',
      );

      return clickable.length > 0 || el.status !== AVAILABILITY.INACTIVE ? null : navigateToProductInfo(el);
    },
    [order?.issues, navigateToProductInfo],
  );

  // Effects
  React.useEffect(
    () => {
      if (order.id && !notMarkAsRead) {
        if (order.sellerId === me.id || order.buyerId === me.id || order.delegateId === me.id)
          if (orderToggleReadStatus) orderToggleReadStatus(me.id, order.id, true);
        RenderTrack.track('OrderDetails', {
          orderId: order.id.toString(),
          status: order.status,
          price: order.price,
          numItems: order.items.length,
          sellerId: order.sellerId.toString(),
          buyerId: order.buyerId.toString(),
          authorId: order.authorId.toString(),
          renderTime: Date.now(),
        });
      }
      if (order && order?.id > 0) {
        cartSet(cartKey, order, contactId);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <S.SectionTitle>
        {__('Components.Cart.items.title')} ({order?.items.length})
        {canEdit && !isBuyerOfferingView ? (
          <S.Row>
            <S.ActionLink
              iconName="Add-more"
              iconSize="18px"
              id="cta-add-button"
              onClick={navigateToShowroom}
              type="link"
              withoutPadding={true}
            >
              {__('Components.Cart.add')}
            </S.ActionLink>
            {canServeOrder && !editAfterAccept ? (
              <>
                <S.VerticalDivider />
                <S.ActionLink
                  iconName="Edit"
                  iconSize="15px"
                  id="cta-modify-button"
                  onClick={onModifyAfterAccept}
                  type="link"
                  withoutPadding={true}
                >
                  {__('Components.Cart.modify_quantities')}
                </S.ActionLink>
              </>
            ) : null}
          </S.Row>
        ) : null}
      </S.SectionTitle>

      <S.CardItem>
        <S.TableContainer>
          <PriceDisplay
            mode="header"
            totalPrice={totalPrice.toString()}
            totalMargin={totalMargin}
            priceMode={priceMode}
            order={order}
            hideTotal={hideTotal}
            isPrepare={isPrepare}
            shipping={{
              cost: shippingCost,
              currency: order?.currency,
            }}
            pricePrecision={pricePrecision}
            isBuyerOfferingView={isBuyerOfferingView} // TODO: this is a temporary fix for isBuyerOfferingView
          />

          <Header
            me={me}
            sortOptions={sortOptions}
            selectedSortLabel={selectedSortLabel}
            onSortChange={handleSortChange}
            amWorkspaceEditor={amWorkspaceEditor}
            previewMode={previewMode}
            amEditor={amEditor}
            orderEditable={orderEditable}
            order={order}
            weAreSeller={weAreSeller}
            anyPriceEmpty={anyItemWithEmptyPrice}
            isSelf={isSelf}
            catalog={catalog}
            setIsFavoriteCheck={setIsFavoriteChecked}
            isFavoriteCheck={isFavoriteChecked}
          />

          <Table
            className="items-table"
            values={sortedItems}
            onClickRow={areLinesClickable ? handleRowClick : undefined}
            rowCursor={areLinesClickable ? 'pointer' : 'default'}
            emptyText=""
            columns={columns}
            selectable={false}
            fixedColumns={fixedColumns}
            showCustomColumns={showCustomColumns && amEditor && !previewMode && !isBuyerOfferingView}
            configId={weAreSeller ? 'order_detail' : 'order_detail_buyer'}
            productColumns={productColumns}
          />

          <PriceDisplay
            mode="footer"
            totalPrice={totalPrice.toString()}
            totalMargin={totalMargin}
            priceMode={priceMode}
            order={order}
            hideTotal={hideTotal}
            isPrepare={isPrepare}
            shipping={{
              cost: shippingCost,
              currency: order?.currency,
            }}
            pricePrecision={pricePrecision}
            isBuyerOfferingView={isBuyerOfferingView} // TODO: this is a temporary fix for isBuyerOfferingView
          />
        </S.TableContainer>
      </S.CardItem>
    </>
  );
});

OrderItems.displayName = 'OrderItems';

export default OrderItems;
