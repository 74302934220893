import * as React from 'react';

import FontIcon from '../FontIcon';

import * as S from './Ribbon.styled';

import type { IFontIconKeys } from '../FontIcon';

export interface IProps {
  actionText?: string;
  badgeCount?: number;
  className?: string;
  hideIcon?: boolean;
  iconName?: IFontIconKeys;
  id?: string;
  onClickAction?: () => void;
  onClose?: () => void;
  text: string | React.ReactNode;
  type: S.IRibbonType;
}
const Ribbon: React.FC<IProps> = ({
  actionText,
  badgeCount,
  className,
  hideIcon,
  iconName,
  id,
  onClickAction,
  onClose,
  text,
  type,
}) => {
  return (
    <S.Ribbon className={className} ribbonType={type} id={id}>
      {(iconName || type) && !hideIcon ? (
        <S.IconWrapper ribbonType={type} badgeCount={badgeCount}>
          <FontIcon name={iconName || getIconCode(type)} onClick={() => null} disableHover={true} />
        </S.IconWrapper>
      ) : null}
      <S.Content>
        <S.TextRegular>
          {formatText(text, s => <S.TextBold key={s}>{s}</S.TextBold>) as string}
          {actionText ? <S.Action onClick={onClickAction}>{actionText}</S.Action> : null}
        </S.TextRegular>
      </S.Content>
      {onClose ? <S.CloseIcon name="Close" onClick={onClose} /> : null}
    </S.Ribbon>
  );
};

/**
 * Parser for title and body to change **whatever** for a featured text.
 */
function formatText(
  text: string | React.ReactNode,
  renderHighlightText: (s: string) => React.ReactNode,
): string | React.ReactNode {
  if (React.isValidElement(text) && typeof text !== 'string') return text;
  return (text as string).split('**').map((str, index) => (index % 2 ? renderHighlightText(str) : str));
}

export default React.memo(Ribbon);

function getIconCode(type: S.IRibbonType): IFontIconKeys {
  const ic: Record<S.IRibbonType, IFontIconKeys> = {
    info: 'Info',
    warning: 'Warning',
    error: 'Error',
  };
  return ic[type];
}
