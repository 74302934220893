import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { __, colors, utils } from 'common-services';
import * as React from 'react';

import { priceUnitTranslator, unitTranslator } from '../../../util/unit';

import * as S from './MultiTable.styled.v1.0';

import type { AggregationFn, Row, SortingState } from '@tanstack/react-table';
import type { IOfferSummary, IReferenceSummary } from 'common-services';

const offersColumnHelper = createColumnHelper<IOfferSummary>();

type renderingFn = (data: IOfferSummary) => string;
type mergingFn = (data: Array<Row<IOfferSummary>>) => IOfferSummary;

const mergeCells = (renderFn: renderingFn, mergeFn?: mergingFn): AggregationFn<any> => {
  return (columnId, _, childRows) => {
    const parts = columnId.split(':');
    const warehouseName = parts[1];
    const sellerId = parts[2];

    const filteredRows: Array<Row<IOfferSummary>> = childRows.filter(row => {
      return row.original.buyerWarehouseName === warehouseName && String(row.original.seller.id) === sellerId;
    });
    if (filteredRows && filteredRows.length > 0) {
      if (filteredRows.length === 1) {
        return renderFn(filteredRows[0].original);
      } else {
        if (mergeFn) {
          return renderFn(mergeFn(filteredRows));
        } else {
          return renderFn(filteredRows[0].original);
        }
      }
    }
    return '';
  };
};

interface IProps {
  buyerOffers: Array<IOfferSummary>;
}

const compareReferenceSummaries = (a: IReferenceSummary, b: IReferenceSummary) => {
  // Special handling if either rank is 0
  if (a.rank === 0 && b.rank === 0) {
    // If both ranks are 0, sort by kindName, then by name
    const kindNameComparison = a.kindName.localeCompare(b.kindName);
    if (kindNameComparison !== 0) {
      return kindNameComparison;
    }
    return a.name.localeCompare(b.name);
  } else if (a.rank === 0) {
    // If A rank is 0, it should come last
    return 1;
  } else if (b.rank === 0) {
    // If B rank is 0, it should come last
    return -1;
  }

  // Normal rank comparison if neither is 0
  if (a.rank !== b.rank) {
    return a.rank - b.rank;
  }

  // Ranks are equal and not 0, compare by kindName
  const kindNameComparison = a.kindName.localeCompare(b.kindName);
  if (kindNameComparison !== 0) {
    return kindNameComparison;
  }

  // Finally, compare by name if all else is equal
  return a.name.localeCompare(b.name);
};

// first column is not being pinned
export const MultiTableV1dot0: React.FC<IProps> = ({ buyerOffers }) => {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [timeOut, setTimeOut] = React.useState<NodeJS.Timeout | null>(null);
  const groupOfferIntoColumns = (offerData: Array<IOfferSummary>) => {
    // Step 1: Group offers by warehouse name
    const offersGroupedByWarehouseAndSeller = offerData.reduce((group, offer) => {
      const warehouseName = offer.buyerWarehouseName;
      const sellerId = offer.seller.id;

      if (!group[warehouseName]) {
        group[warehouseName] = {};
      }

      if (!group[warehouseName][sellerId]) {
        group[warehouseName][sellerId] = [];
      }

      group[warehouseName][sellerId].push(offer);

      return group;
    }, {});

    // Step 2: Create a sorted list of warehouses and seller
    const sortWarehousesAndSellers = (offersGroupedByWarehouseAndSeller: Record<string, Record<string, any>>) => {
      const sortedWarehouses = Object.keys(offersGroupedByWarehouseAndSeller).sort((a, b) => {
        if (a === __('Components.OffersList.noWarehouse')) return -1;
        if (b === __('Components.OffersList.noWarehouse')) return 1;
        return a.localeCompare(b);
      });
      const sortedOffersGroupedByWarehouseAndSeller: Record<string, Record<string, any>> = {};
      sortedWarehouses.forEach(warehouse => {
        const sellers = offersGroupedByWarehouseAndSeller[warehouse];
        const sortedSellers = Object.keys(sellers).sort((a, b) => {
          return sellers[a][0].seller.name.localeCompare(sellers[b][0].seller.name);
        });
        sortedOffersGroupedByWarehouseAndSeller[warehouse] = sortedSellers.reduce((acc, seller) => {
          acc[seller] = sellers[seller];
          return acc;
        }, {});
      });
      return sortedOffersGroupedByWarehouseAndSeller;
    };

    // Step 3: Create columns for each warehouse and its offers
    const sortedOffersGroupedByWarehouseAndSeller = sortWarehousesAndSellers(offersGroupedByWarehouseAndSeller);
    const groupedColumnsCount = Object.keys(sortedOffersGroupedByWarehouseAndSeller).length;
    const groupedColumns = Object.keys(sortedOffersGroupedByWarehouseAndSeller).map((warehouseName, columnIndex) => {
      const sellersInWarehouse = sortedOffersGroupedByWarehouseAndSeller[warehouseName];

      // Create columns for each seller in the warehouse
      const sellerColumns = Object.keys(sellersInWarehouse).flatMap(sellerId => {
        const sellerOffers = sellersInWarehouse[sellerId];

        // Assume there's at least one offer per seller to access seller details
        const nonEmptyOffers = sellerOffers.filter(offer => offer.seller.name !== '');
        const firstOffer = nonEmptyOffers.length > 0 ? nonEmptyOffers[0] : sellerOffers[0];

        return offersColumnHelper.group({
          id: `Seller-${firstOffer.seller.name}-${warehouseName}`,
          header: () => (
            <S.GreyCell className="seller">
              <S.SellerImage
                text={firstOffer.seller.name}
                avatarColor={utils.getAvatarColor(firstOffer.seller.name)}
                img={firstOffer.seller.logo || ''}
              />
              <S.BlackText>{firstOffer.seller.name}</S.BlackText>
            </S.GreyCell>
          ),
          columns: [
            offersColumnHelper.accessor(
              row =>
                row.unlimited
                  ? __('Components.OffersList.availability')
                  : `${row.quantity} ${unitTranslator(row.quantityUnit, row.quantity)}`,
              {
                id: `quantity:${warehouseName}:${sellerId}`, // required for mergeCells function
                size: 200,
                header: property => {
                  return (
                    <S.HeaderCell noBorderRight>
                      <S.BlackText>{__('Components.OffersList.table.availability')}</S.BlackText>
                    </S.HeaderCell>
                  );
                },
                cell: info => {
                  const { getValue } = info;
                  return (
                    <div>
                      <S.BodyText>{getValue().split('-')[0]} </S.BodyText>
                      <S.UnitText>{getValue().split('-')[1]}</S.UnitText>
                    </div>
                  );
                },
                aggregationFn: mergeCells(
                  row =>
                    row.unlimited
                      ? __('Components.OffersList.noLimit')
                      : row.quantity + '-' + unitTranslator(row.quantityUnit, row.quantity),
                  filteredRows =>
                    filteredRows.reduce(
                      (acc, row) => {
                        acc.quantity += row.original.quantity;
                        if (!acc.unlimited) acc.unlimited = row.original.unlimited;
                        return acc;
                      },
                      { ...filteredRows[0].original, quantity: 0 },
                    ),
                ),
              },
            ),
            offersColumnHelper.accessor('boxPerPallet', {
              id: `boxPerPallet:${warehouseName}:${sellerId}`, // required for mergeCells function
              header: () => (
                <S.HeaderCell noBorderRight noBorderLeft>
                  <S.BlackText>{__('Components.OffersList.table.bpp')}</S.BlackText>
                </S.HeaderCell>
              ),
              aggregationFn: mergeCells(row => (row.boxPerPallet ? row.boxPerPallet.toString() : '-')),
            }),
            offersColumnHelper.accessor(row => `${row.price}${row.priceUnit}`, {
              id: `price:${warehouseName}:${sellerId}`, // required for mergeCells function
              header: () => (
                <S.HeaderCell noBorderLeft noBorderRight>
                  <S.BlackText>{__('Components.OffersList.table.price')}</S.BlackText>
                </S.HeaderCell>
              ),
              cell: info => {
                const { getValue } = info;
                return (
                  <div>
                    <S.BodyText>{getValue().split('-')[0]} </S.BodyText>
                    <S.UnitText>{getValue().split('-')[1]}</S.UnitText>
                  </div>
                );
              },
              aggregationFn: mergeCells(row => row.price.toFixed(2) + '-' + priceUnitTranslator(row.priceUnit)),
            }),
          ],
        });
      });

      // Create and return the warehouse group column
      return offersColumnHelper.group({
        id: `Group-${warehouseName}`,
        header: () => {
          const isFirst = columnIndex === 0;
          const isLast = columnIndex === groupedColumnsCount - 1;
          const classNames = `warehouse ${isFirst && 'first'} ${isLast && 'last'}`.trim();
          return (
            <S.WhCell className={classNames}>
              <S.WarehouseNameImage
                text={warehouseName}
                iconName="Address"
                avatarColor={{ background: colors.white, text: '' }}
                img=""
              />
              <S.WarehouseNameText>{warehouseName}</S.WarehouseNameText>
            </S.WhCell>
          );
        },
        columns: sellerColumns, // Add seller columns here
      });
    });

    const sellerProductNameColumn = offersColumnHelper.accessor('buyerReference.name', {
      header: () => (
        <S.HeaderCell noBorderRight noBorderLeft>
          <S.BlackText>{__('Components.OffersList.table.references')}</S.BlackText>
        </S.HeaderCell>
      ),
      // [DEBUG] Aggregate name, kind and rank
      // cell: info => {
      //   const { row } = info;
      //   return (
      //     <div>
      //       <S.BlackText>{row.original.buyerReference.name}</S.BlackText>
      //       <S.GreyText>{row.original.buyerReference.rank}</S.GreyText>
      //       <S.GreyText>{row.original.buyerReference.kindName}</S.GreyText>
      //     </div>
      //   );
      // },
      cell: info => info.getValue(),
      sortingFn: (rowA, rowB) => {
        return compareReferenceSummaries(rowA.original.buyerReference, rowB.original.buyerReference);
      },
    });

    // add `sellerProductNameColumn` at the start
    groupedColumns.unshift(sellerProductNameColumn);

    return groupedColumns;
  };

  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'buyerReference_name',
      desc: false,
    },
  ]);

  const table = useReactTable({
    data: buyerOffers,
    columns: groupOfferIntoColumns(buyerOffers),
    state: {
      sorting,
      grouping: React.useMemo(() => ['buyerReference_name'], []), // useMemo is mandatory to prevent infinite loop in getGroupedRowModel -> https://github.com/TanStack/table/issues/4330
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    onSortingChange: setSorting,
    enableColumnResizing: false,
    defaultColumn: {
      size: 150,
    },
  });

  const handleInputChange = (): void => {
    setIsScrolled(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    const timeout = setTimeout(() => {
      setIsScrolled(false);
    }, 400);
    setTimeOut(timeout);
  };

  return (
    <S.StyledContainer onScroll={handleInputChange}>
      <S.StyledTable isScrolled={isScrolled}>
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id + i}>
              {headerGroup.headers.map((header, idx) => (
                <S.StyledTh key={header.id + idx} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </S.StyledTh>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, idx) => {
                const isEndCell = cell.column.id.startsWith('price:');
                return (
                  <S.StyledCell key={cell.id + idx} isEndCell={isEndCell}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </S.StyledCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </S.StyledTable>
    </S.StyledContainer>
  );
};
