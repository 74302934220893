import {
  buyerWorkspaceActions,
  costActions,
  imageActions,
  modalActions,
  notificationsActions,
  productActions,
  productService,
  sellerWorkspaceActions,
  tagActions,
  userActions,
} from 'common-services';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userAppActions from '../actions/user';
import ProductsList from '../components/organisms/WorkspaceProducts';

import type { IDispatchProps, IStateProps } from '../components/organisms/WorkspaceProducts';
import type { IReduxState } from '../reducers';
import type { Api, IPrice, LOCALE } from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

const emptyPrices: { [id: number]: IPrice } = {};
const emptyArray: Array<any> = [];
function mapStateToProps(state: IReduxState, props: RouteComponentProps<{ workspaceId?: string }>): IStateProps {
  const {
    catalog: { catalogs, priceGroups },
    contact: { inConsentio },
    user: { user },
    product: { prices },
    tag: { tags },
    workspace: { addresses },
  } = state;

  const prodTypes = state.prodType.prodTypes;
  const catalogId = props.match.params.workspaceId ? Number(props.match.params.workspaceId) : user.sellerWorkspaceId;
  const catalogKey = productService.getKey(catalogId, user.id);
  return {
    addresses: addresses?.[catalogId] || emptyArray,
    catalogAssignedId: catalogId,
    catalog: catalogs[catalogId],
    catalogs,
    contacts: inConsentio,
    countries: state.country.countries,
    lang: user.settings.language as LOCALE,
    me: user,
    priceGroups: priceGroups[catalogId],
    prices: prices[catalogKey] || emptyPrices,
    prodTypes,
    tags,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IReduxState, Api, AnyAction>): IDispatchProps {
  return bindActionCreators(
    {
      catalogAssign: sellerWorkspaceActions.catalogAssign,
      catalogMemberDelete: sellerWorkspaceActions.catalogMemberDelete,
      catalogMembersUpdate: sellerWorkspaceActions.catalogMembersUpdate,
      catalogSync: sellerWorkspaceActions.catalogSync,
      exportProducts: productActions.catalogExportProducts,
      costExport: costActions.costExportProducts,
      getPrices: productActions.getPrices,
      mediaUpload: imageActions.mediaUploadWithProgress,
      modalClose: modalActions.modalClose,
      modalOpen: modalActions.modalOpen,
      notificationShow: notificationsActions.notificationShow,
      priceGroupAddPrices: sellerWorkspaceActions.priceGroupAddPrices,
      priceGroupDeletePrice: sellerWorkspaceActions.priceGroupDeletePrice,
      priceGroupsGet: sellerWorkspaceActions.priceGroupsGet,
      pricesAddProduct: sellerWorkspaceActions.pricesAddProduct,
      pricesGetFromProduct: sellerWorkspaceActions.pricesGetFromProduct,
      productCreate: productActions.productCreate,
      productRemove: productActions.productRemove,
      productUpdate: productActions.productUpdate,
      productDeleteFile: productActions.productDeleteFile,
      requestProInfo: userActions.requestProInfo,
      supportAction: userAppActions.supportAction,
      tagsGet: tagActions.tagsGet,
      touchImage: modalActions.touchImage,
      workspaceAddressesGet: buyerWorkspaceActions.workspaceAddressesGet,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
