import styled from '../../../../styled-components';
import { ColumnContainer, RowContainer, TextLabel } from '../../../atoms';

import type { REGISTER_STEPS } from '../../../../constants';
import type { ITheme } from '../../../../styled-components';

interface IStyledProps {
  theme: ITheme;
  selected?: boolean;
  authStep?: REGISTER_STEPS;
}

export const BodyContainer = styled(ColumnContainer)`
  height: 100%;
`;

export const TitleContainer = styled(ColumnContainer)`
  flex: 1;
  justify-content: flex-start;
  padding-top: ${(p: IStyledProps) => p.theme.paddingSize(5)};
  margin-bottom: 15%;
  padding-right: 10%;
  padding-left: 10%;

  @media (max-height: 880px) {
    margin-bottom: 5%;
  }

  @media (max-height: 750px) {
    margin-bottom: 0;
  }
`;

export const TitleLabelContainer = styled(RowContainer)`
  margin-bottom: 5%;
`;

export const TittleText = styled(TextLabel)<{ color?: string }>`
  color: ${props => props.color || 'inherit'};
  font-family: Quicksand;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H1};
  margin-right: 8px;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}), (max-height: 812px) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  }
`;

export const TextSubtitle = styled(TextLabel)`
  text-align: left;
  font-weight: ${({ theme }: IStyledProps) => theme.fontWeight.Bold};
  font-size: 2.5rem;
  font-family: Manrope;
  line-height: 1.6;
  color: ${({ theme }: IStyledProps) => theme.colors.green4};
  padding: ${({ theme }: IStyledProps) => theme.paddingSize(3)} 0;

  @media (max-height: 800px),
    (max-width: ${({ theme }: IStyledProps) => theme.sizes.desktop_ml}),
    (max-width: ${({ theme }: IStyledProps) => theme.sizes.ipad}),
    (max-height: 812px) {
    font-size: 2rem;
  }

  @media (max-height: 780px) {
    font-size: 1.5rem;
  }
`;

export const QouteImage = styled.img`
  height: 25px;
  width: 50px;
  object-fit: contain;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  margin-bottom: 10%;
  margin-left: -6px;
`;

export const Testimonials = styled(ColumnContainer)`
  max-width: max-content;
  align-self: center;
  flex: 1;
`;

export const TestimonialCard = styled(ColumnContainer)`
  border-radius: ${(p: IStyledProps) => p.theme.borderRadius};
  padding: ${(p: IStyledProps) => p.theme.paddingSize(3)} ${(p: IStyledProps) => p.theme.paddingSize(4)};
  width: 25%;
  min-width: 100px;
  margin: 0 auto;
  font-family: Manrope;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_m}) {
    width: calc(min(22%, 500px));
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    width: calc(min(15%, 280px));
  }
`;

export const TestimonialHeader = styled(RowContainer)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const TestimonialTitle = styled(ColumnContainer)`
  padding: 0 ${(p: IStyledProps) => p.theme.paddingSize(3)};
`;

export const Name = styled(TextLabel)`
  font-family: Quicksand;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Light};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.small};
  }
`;

export const Company = styled(Name)`
  padding-bottom: ${(p: IStyledProps) => p.theme.paddingSize()};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  font-family: Quicksand;
  font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  font-weight: ${(p: IStyledProps) => p.theme.fontWeight.Bold};

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.normal};
  }
`;

export const TestimonialText = styled(TextLabel)`
  font-size: ${(p: IStyledProps) => p.theme.fontSize.H3};
  color: ${(p: IStyledProps) => p.theme.colors.white};
  line-height: 24.59px;
  font-family: Manrope;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10%;

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.desktop_ml}), (max-height: 812px) {
    font-size: ${(p: IStyledProps) => p.theme.fontSize.big};
  }

  @media (max-width: ${(p: IStyledProps) => p.theme.sizes.ipad}) {
    font-weight: 200;
  }
`;
