import { __, IOrderBuyerPreparationLineCart, PRODUCT_UNIT, WEIGHT_UNIT } from 'common-services';

/**
 * Get cart load summary
 * Example: "X boxes / X pallets / X kg"
 */

export function getCartLoadSummaryText(cartLines: Array<IOrderBuyerPreparationLineCart>, locale?: LOCALE) {
  const result = [];
  const noBoxes = cartLines.find(i => !getNumBoxes(i));
  const noPallets = cartLines.find(i => !getNumPallets(i));
  const noWeight = cartLines.find(i => !getWeight(i));

  if (!noBoxes) {
    const boxesCount: number = cartLines.reduce((acc, i) => (acc += getNumBoxes(i)!), 0);
    result.push(boxesCount.toFixed(1) + ' ' + __('Constants.ProductUnitPlural.box', { count: boxesCount, locale }));
  }
  if (!noPallets) {
    const palletCount: number = cartLines.reduce((acc, i) => (acc += getNumPallets(i)!), 0);
    result.push(
      palletCount.toFixed(2) +
        ' ' +
        __('Constants.ProductUnitPlural.pallet', { count: palletCount < 1 ? 0 : palletCount, locale }),
    );
  }
  // This is for when a product in the cart has no pallets value
  if (noPallets) {
    const palletCount: number = cartLines.reduce((acc, i) => (acc += getNumPallets(i)!), 0);
    result.push(
      '> ' +
        palletCount.toFixed(2) +
        ' ' +
        __('Constants.ProductUnitPlural.pallet', { count: palletCount < 1 ? 0 : palletCount, locale }),
    );
  }

  if (!noWeight)
    result.push(
      cartLines.reduce((acc, i) => (acc += getWeight(i)!), 0).toFixed(1) + ' ' + WEIGHT_UNIT.KG, // todo: where do we obtain weight unit from?
    );

  return result.length ? result.join(' / ') : '-';
}

export function getNumBoxes(i: IOrderBuyerPreparationLineCart) {
  switch (i.offeredProduct.totalQuantityUnit) {
    case PRODUCT_UNIT.BOX:
      return i.orderedQuantity;
    case PRODUCT_UNIT.KG:
      if (i.offeredProduct.boxWeight) return i.orderedQuantity / i.offeredProduct.boxWeight;
      return null;
    case PRODUCT_UNIT.PALLET:
      if (i.offeredProduct.boxPerPallet) return i.orderedQuantity * i.offeredProduct.boxPerPallet;
      return null;
    case PRODUCT_UNIT.PIECE:
      if (i.offeredProduct.unitsPerBox) return i.orderedQuantity / i.offeredProduct.unitsPerBox;
      return null;
    default:
      return null;
  }
}

export function getNumPallets(i: IOrderBuyerPreparationLineCart) {
  if (i.offeredProduct.totalQuantityUnit === PRODUCT_UNIT.PALLET) return i.orderedQuantity;
  const numBoxes = getNumBoxes(i);
  if (numBoxes === null || !i.offeredProduct.boxPerPallet) return null;
  return numBoxes / i.offeredProduct.boxPerPallet;
}

export function getWeight(i: IOrderBuyerPreparationLineCart) {
  if (i.offeredProduct.totalQuantityUnit === PRODUCT_UNIT.KG) return i.orderedQuantity;
  const numBoxes = getNumBoxes(i);
  if (numBoxes === null || !i.offeredProduct.boxWeight) return null;
  return numBoxes * i.offeredProduct.boxWeight;
}
