import { __, colors, utils } from 'common-services';
import * as React from 'react';

import config from '../../../../bindings/config';
import { useWindowSize } from '../../../util/hooks';
import { LettersAvatar, SimpleDropdown } from '../../atoms';

import * as S from './NavigationTabs.styled';

import type { IFontIconKeys } from '../../atoms/FontIcon/FontIcon.component';
import type { IItem } from '../../atoms/SimpleDropdown/SimpleDropdown.component';

export interface ITab {
  action?: (key?: string) => void;
  alertIcon?: IFontIconKeys;
  badge?: number;
  color?: string;
  icon?: IFontIconKeys;
  iconColor?: string;
  id: string;
  image?: string;
  label: string;
  menu?: Array<IItem>;
  name?: string;
  new?: boolean;
  onCloseTooltip?: () => void;
  hideBadgeNumber?: boolean;
  tooltip?: string;
  tooltipTitle?: string;
  type?: string;
  url?: string;
}

export interface IProps {
  className?: string;
  handleOverflowX?: boolean;
  history?: { push: (url: string) => void };
  selected: string;
  tabs: Array<ITab>;
  rightTabs?: Array<ITab>;
  maxWidth?: string;
  tabTextStyle?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  TabRowContainer?: React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const NavigationTabs: React.FC<IProps> = ({
  className,
  handleOverflowX = false,
  history,
  maxWidth,
  rightTabs,
  selected,
  TabRowContainer = React.Fragment,
  tabs,
  tabStyle,
  tabTextStyle,
}) => {
  const onClick = React.useCallback(
    (t: ITab) => {
      if (t.url) {
        return history?.push(t.url);
      }
      if (t.action) {
        return t.action();
      }
    },
    [history],
  );

  // Add overflowX to tabs container
  const tabsRef = React.useRef<HTMLDivElement>(null);
  const [windowWidth] = useWindowSize();
  const [overflowX, setOverflowX] = React.useState(false);
  const canUseDOM = typeof window !== 'undefined';
  const useLayoutEffect = canUseDOM ? React.useLayoutEffect : React.useEffect;

  useLayoutEffect(() => {
    if (handleOverflowX && tabsRef?.current) {
      setOverflowX(tabsRef?.current.offsetWidth < tabsRef?.current.scrollWidth);
    }
  }, [windowWidth, handleOverflowX]);

  return (
    <S.Tabs data-testid="navigation-tabs" className={className} ref={tabsRef} overflowX={overflowX}>
      <TabRowContainer>
        {tabs?.filter(t => t).map((t, idx) => renderTab(t, idx))}
        {rightTabs ? (
          <S.TabRowContainerRight>{rightTabs.filter(t => t).map((t, idx) => renderTab(t, idx))}</S.TabRowContainerRight>
        ) : null}
      </TabRowContainer>
    </S.Tabs>
  );

  function renderTab(t: ITab, idx: number): JSX.Element {
    const handleCloseTooltip = t.onCloseTooltip;
    return t.tooltip ? (
      <S.Tooltip
        key={t.label + '_' + idx}
        position="bottom"
        text={t.tooltip}
        title={t.tooltipTitle}
        width="470px"
        className="tooltip-tab"
        themeMode="dark"
        showed={true}
        onClose={handleCloseTooltip}
      >
        <NavigationTab
          t={t}
          selected={t.id === selected}
          onClick={onClick}
          maxWidth={maxWidth}
          tabStyle={tabStyle}
          tabTextStyle={tabTextStyle}
        />
      </S.Tooltip>
    ) : (
      <NavigationTab
        key={t.label + '_' + idx}
        t={t}
        selected={t.id === selected}
        onClick={onClick}
        maxWidth={maxWidth}
        tabStyle={tabStyle}
        tabTextStyle={tabTextStyle}
        alertIcon={t.alertIcon}
      />
    );
  }
};

const NavigationTab: React.FC<{
  t: ITab;
  onClick: (t: ITab) => void;
  selected: boolean;
  maxWidth?: string;
  tabTextStyle?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  alertIcon?: IFontIconKeys;
}> = ({ t, onClick, selected, maxWidth, tabStyle, tabTextStyle, alertIcon }) => {
  return t.menu ? (
    <SimpleDropdown options={t.menu} onSelect={key => t.action(key)}>
      <S.Tab id={`tab_${t.id}`} selected={selected} style={tabStyle}>
        <S.Title style={tabTextStyle} hasImage={!!(t.image || t.type)} maxWidth={maxWidth}>
          {utils.firstToUpperCase(t.label)}
        </S.Title>
        <S.Chevron name="Down" />
      </S.Tab>
    </SimpleDropdown>
  ) : t.id !== 'multiple-carts' || !config.TOGGLE_CART_ICON.enabled ? (
    // Normal Tabs
    <S.Tab
      id={`tab_${t.id}`}
      className={'navigation-tab' + (selected ? ' selected' : '')}
      onClick={() => onClick(t)}
      selected={selected}
      color={t.color}
      maxWidth={maxWidth}
      style={tabStyle}
    >
      <S.TabContainer className="navigation-tab-container">
        {t.icon ? <S.Icon name={t.icon} color={selected ? colors.blue1 : t.iconColor} disableHover={true} /> : null}
        {t.image || t.type ? (
          <LettersAvatar
            img={t.image}
            text={t.name || t.label}
            type={t.type}
            avatarColor={utils.getAvatarColor(t.label)}
          />
        ) : null}
        <S.Title style={tabTextStyle} hasImage={!!(t.image || t.type)} maxWidth={maxWidth}>
          {utils.firstToUpperCase(t.label)}
        </S.Title>
        {t.alertIcon ? (
          <S.AlertIcon
            name={alertIcon}
            tooltipLabel={__('WorkspaceSettings.missing_information')}
            disableHover={true}
          />
        ) : null}
        {t.badge ? (
          <S.Badge count={t.badge} color="red" showNumber={!t.hideBadgeNumber} size={t.hideBadgeNumber ? 8 : 18} />
        ) : null}
        {t.new ? <S.New>{__('Components.ChatList.new')}</S.New> : null}
      </S.TabContainer>
    </S.Tab>
  ) : (
    // Cart tab
    <S.Tab
      id={`tab_${t.id}`}
      className={'cart-tab' + (selected ? ' selected' : '') + (t.badge === 0 ? '-empty' : '')}
      onClick={() => onClick(t)}
      selected={selected}
      color={t.color}
      maxWidth={maxWidth}
      style={tabStyle}
    >
      <S.TabContainer className={`${t.badge === 0 ? 'cart-tab-container-empty' : 'cart-tab-container'}`}>
        <S.IconCart
          name={t.icon}
          color={selected ? colors.white : t.badge === 0 ? colors.grey1 : colors.green1}
          disableHover={true}
        />
        <S.Badge
          count={t.badge}
          color={t.badge === 0 ? 'grey' : 'green'}
          showNumber={!t.hideBadgeNumber}
          size={18}
          className="background"
          selected={selected}
          isCart={true}
        />
        {t.new ? <S.New>{__('Components.ChatList.new')}</S.New> : null}
      </S.TabContainer>
    </S.Tab>
  );
};
export default React.memo(NavigationTabs);
