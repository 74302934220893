import { __, ORDER_STATUS } from 'common-services';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../../../constants';
import getPath from '../../../../../util/routes';
import { SimpleDropdown } from '../../../../atoms';
import { useOrderContext } from '../../context';

import { ActionsCard } from './ActionsCard';
import { BackNavigation } from './BackNavigation/BackNavigation.component';
import { EditOrderReference } from './EditOrderReference';
import * as S from './OrderHeader.styled';
import { useDownloadFormats } from './useDownloadFormat';

const OrderHeader: React.FC = React.memo(() => {
  const {
    me,
    order,
    orderReferenceId,
    catalogs,
    pdfFormats: downloadFormats,
    weAreSeller,
    amEditor: isEditor,
    previewMode,
    canEditAfterAccept: editAfterAccept,
    hasItems,
    isBuyerOfferingView,
    backLink,
    showBack,
    closeCart,
    goBack,
    getExcelOrder,
    orderCustomPdforExcelDownload,
    orderPdfDownload,
    orderOriginalFileDownload,
    // downloadFile: onDownloadOriginalFile,
  } = useOrderContext();
  const history = useHistory();
  const onBack = goBack || closeCart;

  const [showEditExternal, setShowEditExternal] = React.useState(!orderReferenceId);

  // Watch for orderReferenceId changes to show/hide edit reference button
  React.useEffect(() => {
    if (orderReferenceId) {
      setShowEditExternal(false);
    } else {
      setShowEditExternal(true);
    }
  }, [orderReferenceId]);

  // Prepare download options
  const downloadKeys = useDownloadFormats({
    order,
    me,
    catalogs,
    downloadFormats,
    weAreSeller,
    editAfterAccept,
    previewMode,
    getExcelOrder,
    orderCustomPdforExcelDownload,
    orderPdfDownload,
    orderOriginalFileDownload,
  });

  //  When the order comes from offer the confirm button shows if user is buyer or if the user is seller and the order is draft
  const canConfirmOfferingView = isBuyerOfferingView
    ? !weAreSeller || (weAreSeller && order.status !== ORDER_STATUS.DRAFT)
    : true;

  return (
    <S.Container data-testid="order-header">
      {showBack && <BackNavigation onBack={onBack} text={backLink} />}

      <S.ContentContainer>
        <S.TitleSection>
          <S.Title>{order?.id ? __('Components.Cart.title_order') : __('Components.Cart.title_new')}</S.Title>
          <S.Reference>
            {__('Components.Cart.ref', {
              hashId: showEditExternal ? '#' : orderReferenceId,
            })}
          </S.Reference>

          {weAreSeller && (
            <EditOrderReference showEditExternal={showEditExternal} setShowEditExternal={setShowEditExternal} />
          )}
        </S.TitleSection>
        <S.ActionsSection>
          {isBuyerOfferingView ? (
            !weAreSeller && order.status === ORDER_STATUS.DRAFT ? (
              <S.ModifyButton
                type="secondary"
                onClick={() => {
                  const searchParams = new URLSearchParams();
                  const dateParam = new Date(order.deliveryEstimatedAt).toISOString().split('T')[0];
                  searchParams.set('date', dateParam);
                  searchParams.set('view', 'supplier');
                  history.push({
                    pathname: getPath({
                      path: ROUTE_PATHS.WORKSPACE_OFFER_TO_ORDER_PREPARATION,
                      workspaceId: order.buyerWorkspaceId.toString(),
                    }),
                    search: searchParams.toString(),
                  });
                }}
              >
                {__('Components.OrderDetails.modify')}
              </S.ModifyButton>
            ) : null
          ) : (
            downloadKeys.length > 0 && (
              <SimpleDropdown options={downloadKeys} hAlign="left" margin="0">
                <S.DownloadButton>
                  <S.DownloadIcon name="Download" />
                  <S.DownloadText>{__('Components.Cart.download_dropdown_test')}</S.DownloadText>
                  <S.Divider />
                  <S.ArrowIcon name="Down" />
                </S.DownloadButton>
              </SimpleDropdown>
            )
          )}
          {isEditor && hasItems && !previewMode && canConfirmOfferingView && <ActionsCard />}
        </S.ActionsSection>
      </S.ContentContainer>
    </S.Container>
  );
});

OrderHeader.displayName = 'OrderHeader';

export default OrderHeader;
