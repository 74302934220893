import {
  __,
  date,
  debounce,
  EventTrack,
  orderService,
  realtime,
  sellerWorkspaceService,
  throttle,
  utils,
} from 'common-services';
import { APIToImportJob } from 'common-services/dist/api-parsers';
import { messageTypes } from 'common-services/dist/realtime';
import * as React from 'react';

import config from '../../../../bindings/config';
import { IMAGES } from '../../../assets';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import { MappingCodeList } from '../../../screens';
import { api } from '../../../store';
import getPath from '../../../util/routes';
import { Button, RowContainer } from '../../atoms';
import { Facets, OrderMagicImport, OrderMagicZero, SelectClientModal } from '../../molecules';
import OrderMagicList from '../OrderMagicList';

import * as S from './SalesOrdersMagic.styled';

import type * as navActions from '../../../actions/nav';
import type { IFontIconKeys } from '../../atoms/FontIcon';
import type {
  contactActions,
  IClient,
  IContact,
  IDateRange,
  IFacets,
  IFormatsResponse,
  IImportJob,
  IProFeature,
  IReduxJobsImport,
  ISearchImportJob,
  ISupplier,
  IUser,
  IWorkspace,
  MessageRt,
  modalActions,
  notificationsActions,
  orderActions,
  userActions,
} from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';

export type OwnProps = {
  contact?: IContact;
  next?: () => void;
  onOpenOrder: (jobId: number, hashId: string) => void;
  onOpenJob: (jobId: number) => void;
  onNewImport: () => void;
} & RouteComponentProps<{ channelId?: string; clientId?: string; workspaceId: string; contactId?: string }>;

export interface IStateProps {
  catalogs: { [id: number]: IWorkspace };
  clients: { [catalogId: number]: Array<IClient> };
  contactId?: number;
  contacts: { [id: number]: IContact };
  hideOrdersMagicZero: boolean;
  importJobsResponse?: IReduxJobsImport;
  me: IUser;
  sellerWorkspace?: IWorkspace;
  suppliers?: { [id: number]: Array<ISupplier> };
}

export interface IDispatchProps {
  downloadImportJobFile: typeof orderActions.downloadImportJobFile;
  importFormatsGet: typeof orderActions.importFormatsGet;
  importOrderFormatAsk: typeof orderActions.importOrderFormatAsk;
  modalClose: typeof modalActions.modalClose;
  modalOpen: typeof modalActions.modalOpen;
  navigateChannelBySection: typeof navActions.navigateChannelBySection;
  notificationShow: typeof notificationsActions.notificationShow;
  orderImportWidgetsGet: typeof orderActions.orderImportWidgetsGet;
  orderOriginalFileDownload: typeof orderActions.orderOriginalFileDownload;
  ordersMagicZeroHide: typeof userActions.ordersMagicZeroHide;
  requestProInfo: typeof userActions.requestProInfo;
  createNewContact: typeof contactActions.createContactWithName;
  contactUpdateMySellerWorkspace: typeof contactActions.contactUpdateMySellerWorkspace;
}

type IProps = IStateProps & IDispatchProps & OwnProps;

interface IState {
  client?: IClient;
  contactSelected?: IContact;
  dateRange: IDateRange;
  facets: IFacets;
  facetsGlobal: IFacets;
  files: Array<File>;
  filteredCatalogs: Array<IWorkspace>;
  formats: IFormatsResponse;
  formatSelected: string;
  hasMore?: boolean;
  hideFilters?: boolean;
  hideOrdersMagicZero: boolean;
  importJobs: Array<IImportJob>;
  isSearched: boolean;
  search: string;
  searchId?: string;
  searchState: ISearchImportJob;
  selectedSection: IProFeature;
  showAllFormats: boolean;
  showContactsModal: boolean;
  tabSelected?: 'list' | 'new' | 'edi' | 'refs';
  text: string;
  totalResults: number;
}

export default class SalesOrdersMagic extends React.PureComponent<IProps, IState> {
  private lastDatesUsed: { beginDate: Date; endDate: Date };
  private lastImportedJobs: { [key: number]: IImportJob };
  private sendSearch: () => void = debounce(() => {
    const { me } = this.props;
    const { searchState } = this.state;
    this.setState({ isSearched: false });
    orderService.importJobsSearch(searchState, config.SEARCH_PREFIX, api, me.id, true).then(this.onReceiveImportJobs);
  }, 200);

  private scrollSearch = throttle(() => {
    const { searchId } = this.state;
    orderService.importJobsScrollSearch(searchId, api).then(this.onReceiveImportJobs);
  }, 200);

  constructor(props: IProps) {
    super(props);
    const { catalogs, clients, sellerWorkspace, hideOrdersMagicZero, contactId } = props;
    this.lastImportedJobs = {};

    const filteredCatalogs = Object.values(props.catalogs).filter(
      cat => cat && sellerWorkspaceService.isProPlan(cat.plan),
    );
    const client = clients[sellerWorkspace?.id]?.find(c => c.userId === contactId);

    this.state = {
      client,
      dateRange: 'all',
      facets: {},
      facetsGlobal: {},
      files: [],
      filteredCatalogs,
      formats: { all: [], recent: [] },
      formatSelected: client?.orderIncomingKind,
      hideFilters: true,
      hideOrdersMagicZero,
      importJobs: [],
      isSearched: false,
      search: '',
      searchState: {
        contactId,
        buyers: contactId ? [contactId] : undefined,
        allCatalogHashs: sellerWorkspace?.hashId
          ? [sellerWorkspace?.hashId]
          : Object.values(catalogs).map(c => c.hashId),
        ...date.getDatesFromRange('all'),
      },
      showAllFormats: !client?.orderIncomingKind,
      tabSelected: 'list',
      text: '',
      totalResults: 0,
      selectedSection: 'orderimport' as IProFeature,
      showContactsModal: false,
    };
  }

  public componentDidMount(): void {
    const { contact, importFormatsGet, match, me, sellerWorkspace } = this.props;
    const { dateRange, formats, searchState } = this.state;

    const dates =
      dateRange === 'custom'
        ? { beginDate: searchState.beginDate, endDate: searchState.endDate }
        : date.getDatesFromRange(dateRange);

    if (match.params.clientId || match.params.contactId) {
      if (window.location.pathname.endsWith('/new')) {
        this.setState({ tabSelected: 'new', showContactsModal: false });
      }
    }
    this.lastDatesUsed = dates;
    // I need at least one usable catalog (the catalog is pro)
    // A race condition can happen when the component is mounted by the catalogs have not yet been placed in the state.

    if (sellerWorkspace && !sellerWorkspaceService.isProPlan(sellerWorkspace.plan)) {
      this.showProInfoModal();
    }
    if (!formats.all.length && sellerWorkspace) {
      importFormatsGet(me.id, sellerWorkspace.id, f =>
        this.setState({
          formats: {
            ...f,
            all: f.all.map((a: IFormat) =>
              a.slug === 'custom' ? { ...a, label: __('MagicOrders.customFormat.label') } : a,
            ),
          },
        }),
      );
    }
    if (contact) this.setState({ contactSelected: contact });

    this.sendSearch();

    realtime.addEventListener(messageTypes.orderImportSync, this.onOrderImportSync);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState): void {
    const { files, formatSelected, client, formats, searchState, tabSelected } = this.state;
    const {
      catalogs,
      clients,
      contactId,
      history,
      hideOrdersMagicZero,
      importFormatsGet,
      match,
      me,
      navigateChannelBySection,
      sellerWorkspace,
    } = this.props;

    if (prevProps.hideOrdersMagicZero !== hideOrdersMagicZero) {
      this.setState({ hideOrdersMagicZero });
    }
    if (prevProps.catalogs !== catalogs) {
      const filteredCatalogs = Object.values(catalogs).filter(cat => cat && sellerWorkspaceService.isProPlan(cat.plan));

      if (!filteredCatalogs.length) {
        navigateChannelBySection(CHANNEL_SECTIONS.ORDERS, match.params.channelId, true, () => history.goBack());
      }
      this.setState({
        filteredCatalogs,
        searchState: sellerWorkspace?.hashId
          ? { ...searchState, allCatalogHashs: [sellerWorkspace?.hashId] }
          : JSON.stringify(Object.values(catalogs).map(c => c.hashId)) !== JSON.stringify(searchState.allCatalogHashs)
          ? { ...searchState, allCatalogHashs: Object.values(catalogs).map(c => c.hashId) }
          : searchState,
      });
    }
    if (prevProps.sellerWorkspace !== sellerWorkspace) {
      this.setState({
        searchState: sellerWorkspace?.hashId
          ? { ...searchState, allCatalogHashs: [sellerWorkspace?.hashId] }
          : JSON.stringify(Object.values(catalogs).map(c => c.hashId)) !== JSON.stringify(searchState.allCatalogHashs)
          ? { ...searchState, allCatalogHashs: Object.values(catalogs).map(c => c.hashId) }
          : searchState,
      });
    }

    if (sellerWorkspace && prevProps.sellerWorkspace !== sellerWorkspace) {
      if (!sellerWorkspaceService.isProPlan(sellerWorkspace.plan)) this.showProInfoModal();
      importFormatsGet(me.id, sellerWorkspace.id, (f: { all: Array<IFormat>; recent: Array<IFormat> }) =>
        this.setState({
          formats: {
            ...f,
            all: f.all.map((a: IFormat) =>
              a.slug === 'custom' ? { ...a, label: __('MagicOrders.customFormat.label') } : a,
            ),
          },
        }),
      );
    }

    if (
      prevProps.clients !== clients ||
      prevProps.contactId !== contactId ||
      (prevProps.sellerWorkspace?.id !== sellerWorkspace?.id && sellerWorkspace)
    ) {
      this.setState({
        client: clients[sellerWorkspace.id]?.find(c => c.userId === contactId),
        searchState: prevProps.contactId !== contactId ? { ...searchState, contactId } : searchState,
      });
    }
    if (formatSelected !== prevState.formatSelected && files.length) this.setState({ files: [] });
    if ((client && !prevState.client) || prevState.client?.userId !== client?.userId) {
      this.setState({
        showAllFormats: client?.orderIncomingKind ? false : true,
        formatSelected:
          (client?.orderIncomingKind && formats.all.find(of => of.slug === client.orderIncomingKind)?.slug) || '',
      });
      this.sendSearch();
    } else if (
      prevState.searchState !== searchState ||
      (prevState.tabSelected !== tabSelected && ['list', 'edi'].includes(tabSelected))
    ) {
      searchState.isEdi = tabSelected === 'edi';
      this.sendSearch();
    }
    if (match.url !== prevProps.match.url) {
      if (window.location.pathname.endsWith('/new')) {
        this.setState({ tabSelected: 'new', showContactsModal: false });
      }
    }
  }

  public componentWillUnmount(): void {
    realtime.removeEventListener(messageTypes.orderImportSync, this.onOrderImportSync);
  }

  FEATURES: Array<{
    feature: IProFeature;
    sidebarImage: string;
    sidebarImageBN: string;
    mainImage: string;
    benefit1Icon: IFontIconKeys;
    benefit2Icon: IFontIconKeys;
    benefit3Icon: IFontIconKeys;
  }> = [
    {
      feature: 'pricelist' as IProFeature,
      sidebarImage: IMAGES.consentioProSidebarPricelist,
      sidebarImageBN: IMAGES.consentioProSidebarPricelistBN,
      mainImage: IMAGES.consentioProPricelistMain,
      benefit1Icon: 'Price-group',
      benefit2Icon: 'Add-group',
      benefit3Icon: 'Multiple-price-group',
    },
    {
      feature: 'orderimport' as IProFeature,
      sidebarImage: IMAGES.consentioProSidebarOrderImport,
      sidebarImageBN: IMAGES.consentioProSidebarOrderImportBN,
      mainImage: IMAGES.consentioProOrderImportMain,
      benefit1Icon: 'Globe',
      benefit2Icon: 'Adaptable',
      benefit3Icon: 'Send-email',
    },
    {
      feature: 'teams' as IProFeature,
      sidebarImage: IMAGES.consentioProSidebarTeams,
      sidebarImageBN: IMAGES.consentioProSidebarTeamsBN,
      mainImage: IMAGES.consentioProTeamsMain,
      benefit1Icon: 'Team-icon',
      benefit2Icon: 'Clock',
      benefit3Icon: 'Comment',
    },
    {
      feature: 'dashboards' as IProFeature,
      sidebarImage: IMAGES.consentioProSidebarDashboards,
      sidebarImageBN: IMAGES.consentioProSidebarDashboardsBN,
      mainImage: IMAGES.consentioProDashboardsMain,
      benefit1Icon: 'Chart',
      benefit2Icon: 'Analysis',
      benefit3Icon: 'Click',
    },
  ];
  public render(): JSX.Element {
    const {
      catalogs,
      clients,
      contact,
      contacts,
      downloadImportJobFile,
      history,
      location,
      match,
      me,
      navigateChannelBySection,
      notificationShow,
      onOpenJob,
      onOpenOrder,
      orderImportWidgetsGet,
      orderOriginalFileDownload,
      ordersMagicZeroHide,
      requestProInfo,
      sellerWorkspace,
      suppliers,
      modalOpen,
      createNewContact,
      contactUpdateMySellerWorkspace,
      onNewImport,
    } = this.props;
    const {
      client,
      contactSelected,
      dateRange,
      facets,
      facetsGlobal,
      formats,
      hideOrdersMagicZero,
      hideFilters,
      importJobs,
      searchState,
      tabSelected,
      showContactsModal,
    } = this.state;
    const fromChat = window.location.pathname.includes('/contact/');
    return (
      <S.Modal>
        <S.Container>
          {showContactsModal ? (
            <SelectClientModal
              onClose={() => this.setState({ showContactsModal: false })}
              title={__('Components.OrdersList.magic_orders')}
              subtitle={__('WorkspaceClientEdit.magic_modal_text')}
              selectContact={(contacId: number, contactName: string) => {
                if (contacts[contacId]) {
                  history.push(
                    getPath({
                      path: ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW,
                      workspaceId: sellerWorkspace.id + '',
                      clientId: contacId + '',
                    }),
                  );
                  if (client) this.setState({ tabSelected: 'new', showContactsModal: false });
                } else {
                  modalOpen(
                    __('WorkspaceClientEdit.client_sales.create_contact.title'),
                    () => {
                      createNewContact(me.id, contacId, contactName, err => {
                        if (err) return null;
                        contactUpdateMySellerWorkspace(
                          me.id,
                          contacId,
                          sellerWorkspace.id,
                          sellerWorkspace.hashId,
                          error => {
                            if (!error) {
                              notificationShow({
                                title: __('ClientsList.create_contact_success'),
                                subtitle: __('ClientsList.create_contact_success_sub'),
                                closable: true,
                                style: 'info',
                              });
                              history.push(
                                getPath({
                                  path: ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW,
                                  workspaceId: sellerWorkspace.id + '',
                                  clientId: contacId + '',
                                }),
                              );
                            }
                          },
                        );
                      });
                    },
                    {
                      text2: __('WorkspaceClientEdit.client_sales.create_contact.subtitle'),
                      buttonText: __('WorkspaceClientEdit.client_sales.create_contact.cta'),
                      showCancelButton: true,
                      icon: IMAGES.informativePineapple,
                    },
                    'nice',
                  );
                }
              }}
              catalogId={sellerWorkspace.id}
              me={me}
            />
          ) : null}
          {!hideOrdersMagicZero ? (
            <OrderMagicZero
              contact={contact}
              history={history}
              match={match}
              me={me}
              ordersMagicZeroHide={() => {
                this.setState({ hideOrdersMagicZero: true });
                return ordersMagicZeroHide();
              }}
              requestProInfo={requestProInfo}
              setParentState={this.setState.bind(this)}
            />
          ) : (
            <S.BodyContainer>
              {tabSelected === 'new' ? (
                <OrderMagicImport
                  channelId={match.params.channelId}
                  client={
                    contactSelected ? clients[sellerWorkspace?.id]?.find(c => c.userId === contactSelected.id) : client
                  }
                  contact={contactSelected || contact}
                  customConf={importJobs[0]?.files[0]?.customConf}
                  formats={formats}
                  setParentState={this.setState.bind(this)}
                />
              ) : (
                <S.HeaderContainer>
                  <S.MOSwitch
                    className="orders-switch"
                    keySelected={tabSelected}
                    onSelectOption={(key: 'list' | 'edi') => this.setState({ tabSelected: key })}
                    options={[
                      { key: 'list', value: __('MagicOrders.Tabs.list') },
                      { key: 'edi', value: __('MagicOrders.Tabs.edi') },
                    ]}
                  />
                  <RowContainer>
                    <S.ReferencesButton
                      type="secondary"
                      onClick={() =>
                        history.push(
                          getPath({
                            path: ROUTE_PATHS.WORKSPACE_REFERENCES,
                            workspaceId: sellerWorkspace.id + '',
                          }),
                        )
                      }
                    >
                      {__('MagicOrders.Tabs.refs')}
                    </S.ReferencesButton>
                    <Button
                      onClick={() => {
                        if (contactSelected) {
                          history.push(
                            fromChat
                              ? getPath({
                                  path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS_NEW,
                                  workspaceId: sellerWorkspace.id + '',
                                  contactId: contactSelected.id + '',
                                })
                              : getPath({
                                  path: ROUTE_PATHS.WORKSPACE_MAGIC_ORDERS_NEW,
                                  workspaceId: sellerWorkspace.id + '',
                                  clientId: contactSelected.id + '',
                                }),
                          );
                        } else {
                          this.setState({ showContactsModal: true });
                        }
                        onNewImport();
                      }}
                    >
                      {__('MagicOrders.Tabs.new')}
                    </Button>
                  </RowContainer>
                </S.HeaderContainer>
              )}
              {tabSelected === 'list' || (config.TOGGLE_EDI_TABS.enabled && tabSelected === 'edi') ? (
                <S.ListContainer>
                  <Facets
                    catalogs={catalogs}
                    clients={clients[match.params?.workspaceId]}
                    changeSearchState={(s, range) => {
                      const filters = orderService.getTrackedFilters(s, (range as IDateRange) || dateRange);
                      if (filters.length) {
                        EventTrack.track('import_orders_filters', {
                          contact_id: contact?.id,
                          filters,
                        });
                      }
                      this.setState({ searchState: s, dateRange: range }, () =>
                        range ? this.setDates(range, false) : null,
                      );
                    }}
                    clearFilters={this.clearFilters}
                    contacts={contacts}
                    dateRange={dateRange}
                    facets={facets}
                    facetsGlobal={facetsGlobal}
                    me={me}
                    numHeaders={2}
                    onHide={hide => this.setState({ hideFilters: hide })}
                    searchState={searchState as any as ISearchOrder}
                    showClosed={hideFilters}
                    viewMode="import-job"
                    type="sale"
                  />
                  <OrderMagicList
                    allFormats={formats.all}
                    catalogs={catalogs}
                    clearFilters={this.clearFilters}
                    clientId={client?.userId}
                    contacts={contacts}
                    clients={clients}
                    suppliers={suppliers}
                    dateRange={dateRange}
                    downloadImportJobFile={downloadImportJobFile}
                    filtersCount={this.getFiltersCount()}
                    hideFilters={hideFilters}
                    setParentState={this.setState.bind(this)}
                    history={history}
                    importJobs={importJobs}
                    location={location}
                    match={match}
                    me={me}
                    navigateChannelBySection={navigateChannelBySection}
                    onScroll={this.onScroll}
                    onOpenOrder={onOpenOrder}
                    onOpenJob={onOpenJob}
                    orderImportWidgetsGet={orderImportWidgetsGet}
                    orderOriginalFileDownload={orderOriginalFileDownload}
                    searchState={searchState}
                    isEdi={tabSelected === 'edi'}
                  />
                </S.ListContainer>
              ) : null}
              {tabSelected === 'refs' ? <MappingCodeList {...this.props} /> : null}
            </S.BodyContainer>
          )}
        </S.Container>
      </S.Modal>
    );
  }

  /**
   * Show pro info modal
   */
  private showProInfoModal = () => {
    const { catalogs, contact, history, match, me, modalOpen, navigateChannelBySection, requestProInfo } = this.props;
    modalOpen(
      'orderimport',
      () => requestProInfo(me.id, contact?.mySellerWorkspaceId || me.sellerWorkspaceId, 'orderimport'),
      {},
      'consentiopro',
    );
    if (Object.keys(catalogs).length) {
      navigateChannelBySection(CHANNEL_SECTIONS.ORDERS, match.params.channelId, true, () => history.goBack());
    }
  };

  private clearFilters = () => {
    const { contact } = this.props;
    this.setState({ searchState: this.getSearchState() });
    EventTrack.track('import_orders_filter_clear', {
      contact_id: contact?.id,
    });
  };

  /**
   * Order import sync, update importJobs state and show notif if job has been processed
   */
  private onOrderImportSync = (data: MessageRt) => {
    const { notificationShow } = this.props;
    const { importJobs, formats } = this.state;
    const importJobUpdated = APIToImportJob(data.action.job);
    const jobId = importJobUpdated.id;
    const oldStatus = this.lastImportedJobs[jobId]?.files?.map(f => f.status) || [];

    const arrayToReplace: Array<IImportJob> = [...importJobs];
    const indexFound = importJobs.findIndex(jobImport => jobImport.id === importJobUpdated.id);
    if (indexFound !== -1) {
      arrayToReplace[indexFound] = importJobUpdated;
    } else arrayToReplace.unshift(importJobUpdated);

    this.setState({
      importJobs: arrayToReplace,
    });

    if (importJobUpdated.id === this.lastImportedJobs[jobId]?.id && importJobUpdated?.files?.length) {
      for (let i = 0; i < oldStatus.length; i++) {
        const previousStatus = oldStatus[i];
        const newStatus = importJobUpdated.files[i]?.status;
        const fileName = importJobUpdated.files[i]?.name || '';
        const jobFormat = importJobUpdated.files[i]?.kind;
        const format = jobFormat ? formats.all?.find(f => f.slug === jobFormat) : undefined;
        const formatName = format?.label || jobFormat;
        if (['queued', 'processing'].includes(previousStatus) && newStatus === 'failed') {
          notificationShow({
            title: __('Components.OrdersImport.importing_orders_error'),
            subtitle: formatName + ' · ' + utils.cropWithExtension(fileName, 50),
            closable: true,
            style: 'error',
          });
        } else if (['queued', 'processing'].includes(previousStatus) && newStatus === 'success') {
          notificationShow({
            title: __('Components.OrdersImport.importing_orders_success'),
            subtitle: formatName + ' · ' + utils.cropWithExtension(fileName, 50),
            style: 'success',
            closable: true,
          });
        }
      }
    }
    this.lastImportedJobs[jobId] = importJobUpdated;
  };

  /**
   * Set new date range and update search state accordingly
   */
  private setDates = (key: string, newSearch: boolean) => {
    const { dateRange, searchState } = this.state;

    const dates =
      dateRange === 'custom'
        ? { beginDate: searchState.beginDate, endDate: searchState.endDate }
        : date.getDatesFromRange(dateRange);
    this.lastDatesUsed = dates;

    const newSearchState = {
      ...(newSearch ? this.getSearchState() : searchState),
      ...(key === 'unknown'
        ? { withoutDeliveryAt: true, beginDate: null, endDate: null }
        : {
            withoutDeliveryAt: undefined,
            ...(key === 'custom' ? this.lastDatesUsed : date.getDatesFromRange(key as IDateRange)),
          }),
    };
    this.setState({
      dateRange: key as IDateRange,
      searchState: newSearchState,
    });
  };

  private getSearchState = (catalogHashs?: Array<string>, sellers?: Array<number>): ISearchImportJob => {
    const { catalogs, contactId } = this.props;
    return {
      allCatalogHashs: Object.values(catalogs).map(c => c.hashId),
      ...this.lastDatesUsed,
      contactId,
      catalogHashs,
      sellers,
    };
  };

  /**
   * receive data for search with elastic
   */
  private onReceiveImportJobs = (res: ISearchData<IImportJob>) => {
    if (!res) return;
    const { data, facets, facetsGlobal, toReplace } = res;
    if (toReplace) {
      this.setState({
        importJobs: data.hits,
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
        facets,
        facetsGlobal,
        isSearched: true,
      });
    } else {
      this.setState({
        importJobs: [...this.state.importJobs, ...data.hits],
        searchId: data.searchId,
        totalResults: data.totalResults,
        hasMore: data.hasMore!,
      });
    }
  };

  /**
   * handle the scroll event for sticky the searchbar
   */
  private onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { hasMore } = this.state;
    if (hasMore && e.currentTarget.scrollTop + e.currentTarget.offsetHeight > e.currentTarget.scrollHeight - 180) {
      this.scrollSearch();
    }
  };

  /**
   * Count selected filters
   */
  private getFiltersCount() {
    const { searchState } = this.state;
    const { beginDate, endDate, catalogHashs, sellers, status } = searchState;
    let result = 0;
    if (beginDate || endDate) result++;
    result = result + (catalogHashs?.length || 0) + (sellers?.length || 0) + (status?.length || 0);
    return result;
  }

  /**
   * Content with info + picture to explain the feature
   */
  Content = React.memo(
    ({
      ctaLiteral = __('ConsentioProModal.CTA'),
      handleCTA,
      selectedSection,
      showCTA,
    }: {
      ctaLiteral?: string;
      handleCTA: () => void;
      selectedSection: IProFeature;
      showCTA: boolean;
    }) => {
      const current = this.FEATURES.filter(f => f.feature === selectedSection)[0];
      const literals = this.getFeaturesLiterals(current?.feature);
      return (
        <S.Content>
          <S.TitleContainer>
            <S.TitleText>{literals.title}</S.TitleText>
            <S.TitleBadge>Consentio Pro</S.TitleBadge>
          </S.TitleContainer>
          <S.SubtitleContainer>
            <S.SubtitleText>{literals.subtitle}</S.SubtitleText>
          </S.SubtitleContainer>
          <S.MainImageContainer>
            <S.MainImage src={current?.mainImage} />
          </S.MainImageContainer>
          <S.Benefits>
            <S.Benefit>
              <S.BenefitIconWrapper>
                <S.BenefitIcon name={current?.benefit1Icon} disableHover={true} />
              </S.BenefitIconWrapper>
              <S.BenefitTitle>{literals.benefit1Title}</S.BenefitTitle>
              <S.BenefitDescription>{literals.benefit1Description}</S.BenefitDescription>
            </S.Benefit>
            <S.Benefit>
              <S.BenefitIconWrapper>
                <S.BenefitIcon name={current?.benefit2Icon} disableHover={true} />
              </S.BenefitIconWrapper>
              <S.BenefitTitle>{literals.benefit2Title}</S.BenefitTitle>
              <S.BenefitDescription>{literals.benefit2Description}</S.BenefitDescription>
            </S.Benefit>
            <S.Benefit>
              <S.BenefitIconWrapper>
                <S.BenefitIcon name={current?.benefit3Icon} disableHover={true} />
              </S.BenefitIconWrapper>
              <S.BenefitTitle>{literals.benefit3Title}</S.BenefitTitle>
              <S.BenefitDescription>{literals.benefit3Description}</S.BenefitDescription>
            </S.Benefit>
          </S.Benefits>
          <S.ActionWrapper>
            {showCTA ? (
              <React.Fragment>
                <S.CTA onClick={handleCTA}>{ctaLiteral}</S.CTA>
                <S.CTASubtitle>{__('ConsentioProModal.CTASubtitle')}</S.CTASubtitle>
              </React.Fragment>
            ) : null}
          </S.ActionWrapper>
        </S.Content>
      );
    },
  );

  /**
   * Get features literals according to the feature
   */
  private getFeaturesLiterals(feature: IProFeature): {
    title: string;
    subtitle: string;
    benefit1Title: string;
    benefit1Description: string;
    benefit2Title: string;
    benefit2Description: string;
    benefit3Title: string;
    benefit3Description: string;
  } {
    let title;
    let subtitle;
    let benefit1Title;
    let benefit1Description;
    let benefit2Title;
    let benefit2Description;
    let benefit3Title;
    let benefit3Description;

    switch (feature) {
      case 'pricelist':
        title = __('ConsentioProModal.pricelist.title');
        subtitle = __('ConsentioProModal.pricelist.subtitle');
        benefit1Title = __('ConsentioProModal.pricelist.benefit1Title');
        benefit1Description = __('ConsentioProModal.pricelist.benefit1Description');
        benefit2Title = __('ConsentioProModal.pricelist.benefit2Title');
        benefit2Description = __('ConsentioProModal.pricelist.benefit2Description');
        benefit3Title = __('ConsentioProModal.pricelist.benefit3Title');
        benefit3Description = __('ConsentioProModal.pricelist.benefit3Description');
        break;
      case 'orderimport':
        title = __('ConsentioProModal.orderimport.title');
        subtitle = __('ConsentioProModal.orderimport.subtitle');
        benefit1Title = __('ConsentioProModal.orderimport.benefit1Title');
        benefit1Description = __('ConsentioProModal.orderimport.benefit1Description');
        benefit2Title = __('ConsentioProModal.orderimport.benefit2Title');
        benefit2Description = __('ConsentioProModal.orderimport.benefit2Description');
        benefit3Title = __('ConsentioProModal.orderimport.benefit3Title');
        benefit3Description = __('ConsentioProModal.orderimport.benefit3Description');
        break;
      case 'teams':
        title = __('ConsentioProModal.teams.title');
        subtitle = __('ConsentioProModal.teams.subtitle');
        benefit1Title = __('ConsentioProModal.teams.benefit1Title');
        benefit1Description = __('ConsentioProModal.teams.benefit1Description');
        benefit2Title = __('ConsentioProModal.teams.benefit2Title');
        benefit2Description = __('ConsentioProModal.teams.benefit2Description');
        benefit3Title = __('ConsentioProModal.teams.benefit3Title');
        benefit3Description = __('ConsentioProModal.teams.benefit3Description');
        break;
      case 'dashboards':
        title = __('ConsentioProModal.dashboards.title');
        subtitle = __('ConsentioProModal.dashboards.subtitle');
        benefit1Title = __('ConsentioProModal.dashboards.benefit1Title');
        benefit1Description = __('ConsentioProModal.dashboards.benefit1Description');
        benefit2Title = __('ConsentioProModal.dashboards.benefit2Title');
        benefit2Description = __('ConsentioProModal.dashboards.benefit2Description');
        benefit3Title = __('ConsentioProModal.dashboards.benefit3Title');
        benefit3Description = __('ConsentioProModal.dashboards.benefit3Description');
        break;
    }
    return {
      title,
      subtitle,
      benefit1Title,
      benefit1Description,
      benefit2Title,
      benefit2Description,
      benefit3Title,
      benefit3Description,
    };
  }
}
