import { __ } from 'common-services';
import * as React from 'react';

import { Input } from '../../../atoms';
import { FormSection, InputWithLabel } from '../../../molecules';

import * as S from './Integration.styled';

import type { ISupplier } from 'common-services';

const Integration = React.memo(
  ({
    supplier,
    integrationRef,
    updateSupplier,
    amEditor,
  }: {
    supplier: ISupplier;
    updateSupplier: (supplier: ISupplier) => void;
    integrationRef: React.RefObject<HTMLDivElement>;
    amEditor: boolean;
  }) => {
    const handleChangeSupplier = (key: string, value: string | number): void => {
      updateSupplier({ ...supplier, counterpartId: `${value}` });
    };

    return (
      <FormSection
        id="integration"
        title={__('WorkspaceClientEdit.Menu.integration.title')}
        sectionRef={integrationRef}
      >
        <InputWithLabel
          isRequired={false}
          label={__('Components.ContactDetails.counterpart_label')}
          description={__('Components.ContactDetails.counterpart_description', { name: supplier.name })}
          width="90%"
          disabled={!amEditor}
        >
          <S.CardInputs>
            <Input
              name="counterpart"
              onBlur={(key: string, value: string | number) => handleChangeSupplier(key, value)}
              onChange={(key: string, value: string | number) => handleChangeSupplier(key, value)}
              value={supplier.counterpartId ?? ''}
              type="text"
              placeholder={__('Components.ContactDetails.counterpart_placeholder')}
              containerMargin="0 0"
              width="350px"
              disabled={!amEditor}
              zeroCase={__('Components.ContactDetails.counterpart_zero')}
            />
          </S.CardInputs>
        </InputWithLabel>
      </FormSection>
    );
  },
);

Integration.displayName = 'Integration';
export default Integration;
