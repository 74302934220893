import styled from '../../../../../../../../styled-components';
import { ColumnContainer, FontIcon, Input, RowContainer, TextLabel } from '../../../../../../../atoms';

import type { ITheme } from '../../../../../../../../styled-components';

/**
 * Base styled component props interface
 */
interface BaseStyledProps {
  theme: ITheme;
  align?: string;
  margin?: string;
  width?: string;
}

/**
 * Extended styled component props interface
 */
interface StyledProps extends BaseStyledProps {
  baseMarginPercentage?: number;
  enabled?: boolean;
  hasError?: boolean;
  hasServedChange?: boolean;
  isCancelled?: boolean;
  isChecked?: boolean;
  isClickable?: boolean;
  isIssue?: boolean;
  marginPercentage?: number;
  rightPosition?: string;
  servedQuantity?: boolean;
  showGrey?: boolean;
  color?: string;
  disabled?: boolean;
}

// Base Components
const BaseText = styled(TextLabel)<StyledProps>`
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
`;

const BaseIcon = styled(FontIcon)<StyledProps>`
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
`;

// Text Components
export const SubText = styled.span<StyledProps>`
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  font-size: ${({ theme }) => theme.fontSize.normal};
  align-self: center;
  padding-right: ${({ theme }) => theme.paddingSize(0.5)};
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
`;

export const Text = styled(BaseText)`
  width: 100%;
`;

export const NoDataText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey2};
`;

export const TextLink = styled(Text)`
  width: auto;
  color: ${({ theme }) => theme.colors.blue1};
  display: inline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const TextBold = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.Bold};
  display: inline;
`;

export const TextGrey = styled(Text)`
  color: ${({ theme }) => theme.colors.grey2};
  padding-left: ${({ theme }) => theme.paddingSize()};
`;

export const BlueText = styled(TextLabel)`
  display: inline;
  color: ${({ theme }) => theme.colors.blue1};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

export const GreyText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey2};
  padding-right: ${({ theme }) => theme.paddingSize()};
`;

export const ItemTitle = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.Bold};
  font-size: ${({ theme }) => theme.fontSize.big};
  flex: 1;
  align-self: start;
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
`;

// Icon Components
export const InfoIcon = styled(BaseIcon)``;

export const PencilIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.colors.blue1};
  margin-right: ${({ theme }) => theme.paddingSize()};
`;

export const RemoveIcon = styled(BaseIcon)`
  font-size: ${({ theme }) => theme.fontSize.big};
  width: ${({ theme }) => theme.fontSize.big};
  height: ${({ theme }) => theme.fontSize.big};
  line-height: ${({ theme }) => theme.fontSize.big};
`;

export const WarningIcon = styled(FontIcon)<StyledProps>`
  color: ${({ theme }) => theme.colors.orange1};
  font-size: 20px;
  margin-right: ${({ theme }) => theme.paddingSize(2)};
  height: 20px;
  width: 20px;
`;

export const KebabIcon = styled(FontIcon)`
  font-size: 20px;
  height: 26px;
  width: 26px;
  padding-bottom: 2px;
`;

export const Arrow = styled(FontIcon)`
  font-size: ${({ theme }) => theme.fontSize.small};
  width: ${({ theme }) => theme.fontSize.normal};
  height: ${({ theme }) => theme.fontSize.normal};
  line-height: ${({ theme }) => theme.fontSize.normal};
`;

// Input Components
const getInputBorderColor = (props: StyledProps): string => {
  const { theme, hasError, isIssue } = props;
  if (hasError) {
    return isIssue ? theme.colors.orange1 : theme.colors.red1;
  }
  return theme.colors.grey2;
};

export const AmountInput = styled(Input)<StyledProps>`
  border: ${props => `1px solid ${getInputBorderColor(props)}`};
  &:focus-within {
    border: ${props =>
      `1px solid ${
        props.hasError
          ? props.isIssue
            ? props.theme.colors.orange1
            : props.theme.colors.red1
          : props.theme.colors.green1
      }`};
  }
`;

export const TablePriceInput = styled(AmountInput)`
  margin-right: 0;
`;

// Container Components
export const CellContainer = styled.div<StyledProps>`
  vertical-align: middle;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
  padding: ${({ theme }) => theme.paddingSize(1)};
`;
export const CodeCell = styled(CellContainer)`
  padding: 0;
`;
export const RefContainer = styled.div<StyledProps>`
  vertical-align: middle;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
`;
export const ReferenceRow = styled(RowContainer)`
  background-color: ${({ theme }) => theme.colors.grey3};
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  margin-right: ${({ theme }) => theme.paddingSize()};
`;

export const TooltipContainer = styled(ColumnContainer)`
  padding-right: ${({ theme }) => theme.paddingSize(1.5)};
`;
export const ErrorColumn = styled(ColumnContainer)`
  display: inline;
`;

export const IconWrapper = styled.div`
  padding: 0 3px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  align-items: center;
  display: flex;
  top: 0;
  align-self: center;
`;

// Price Components
const getMarginColor = (props: StyledProps): string => {
  const { theme, baseMarginPercentage, marginPercentage } = props;
  if (!baseMarginPercentage || !marginPercentage) {
    return theme.colors.black;
  }
  return marginPercentage.toFixed(2) >= baseMarginPercentage.toFixed(2) ? theme.colors.green1 : theme.colors.red1;
};

const BasePrice = styled(Text)<StyledProps>`
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  text-align: right;
  flex: none;
`;

export const ItemPrice = styled(BasePrice)`
  padding-right: ${({ theme }) => theme.paddingSize(2)};
  width: auto;
`;

export const ItemPriceTotal = styled(ItemPrice)`
  float: left;
  font-weight: ${({ theme }) => theme.fontWeight.Bold};
  padding-right: ${({ theme }) => theme.paddingSize(4)};

  @media (max-width: ${({ theme }) => theme.sizes.ipad}) {
    padding-right: ${({ theme }) => theme.paddingSize(2)};
  }
`;

export const MarginPercentage = styled(Text)`
  font-weight: ${({ theme, baseMarginPercentage }) =>
    baseMarginPercentage ? theme.fontWeight.Regular : theme.fontWeight.Bold};
  font-size: ${({ theme }) => theme.fontSize.normal};
  color: ${getMarginColor};
  flex: none;
  display: inline;
  width: auto;
`;

// Row Components
export const LeftRow = styled(RowContainer)`
  align-items: center;
`;

export const AmountRow = styled(RowContainer)`
  align-items: start;
  align-self: center;
  cursor: auto;
  padding-right: ${({ theme }) => theme.paddingSize(2)};

  @media (max-width: ${({ theme }) => theme.sizes.ipad}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const PriceRow = styled(RowContainer)`
  align-items: center;
  justify-content: flex-end;
`;

export const ProductPriceRow = styled(RowContainer)`
  justify-content: flex-start;
  align-items: center;
`;

export const PriceEditRow = styled(RowContainer)<StyledProps>`
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'default')};
  align-self: center;
  justify-content: flex-start;
`;

export const QuantityRow = styled(RowContainer)`
  align-items: center;
`;

export const ProductErrorRow = styled(RowContainer)`
  padding-right: ${({ theme }) => theme.paddingSize(2)};
  align-items: center;
`;

export const Row = styled(RowContainer)`
  justify-content: space-between;
  width: 100%;
  flex: 1;
  max-height: 100%;
`;

export const ArrowContainer = styled(RowContainer)`
  justify-content: flex-start;
`;

// Column Components
export const PriceColumn = styled(ColumnContainer)`
  padding-right: ${({ theme }) => theme.paddingSize(1)};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PriceGroupColumn = styled(PriceColumn)`
  padding-right: ${({ theme }) => theme.paddingSize(2)};
`;

export const UnitPriceColumn = styled(ColumnContainer)`
  align-self: center;
  width: fit-content;
  align-items: flex-end;
`;

export const DiscardWrapper = styled(ColumnContainer)`
  justify-content: flex-start;
`;

// Other Components
export const Option = styled(RowContainer)`
  align-items: center;
`;

export const OptionText = styled(Text)<StyledProps>`
  color: ${({ theme, isChecked }) => (isChecked ? theme.colors.green1 : theme.colors.black)};
  margin-left: ${({ theme }) => theme.paddingSize(2)};
`;

export const SubtotalTd = styled(CellContainer)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  justify-content: flex-end;
`;

export const DeleteContainer = styled(RowContainer)`
  justify-content: flex-start;
`;

export const DiscardContainer = styled(RowContainer)`
  justify-content: flex-start;
  display: inline;
`;

export const Deleted = styled(Text)`
  width: auto;
`;

export const Undo = styled(TextLink)`
  padding-left: ${({ theme }) => theme.paddingSize(0.5)};
`;

export const Reference = styled(TextLabel)<StyledProps>`
  display: inline;
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
`;

export const Amount = styled.h2<StyledProps>`
  font-size: ${({ theme }) => theme.fontSize.normal};
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  padding-right: ${({ theme }) => theme.paddingSize()};
`;

export const AmountBlack = styled.h2<StyledProps>`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.Regular};
  display: flex;
  color: ${({ theme, isClickable, showGrey }) =>
    isClickable ? theme.colors.blue1 : showGrey ? theme.colors.grey2 : theme.colors.black};
  padding-right: ${({ theme }) => theme.paddingSize()};
`;

export const TextPriceGroup = styled(TextLabel)<StyledProps>`
  text-align: right;
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

export const TextPriceMismatch = styled(TextLabel)<StyledProps>`
  display: inline;
  color: ${({ theme, showGrey }) => (showGrey ? theme.colors.grey2 : theme.colors.black)};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;
