import { ORDER_STATUS, __, orderUtils } from 'common-services';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { SimpleDropdown } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { IItem } from '../../../../../../../atoms/SimpleDropdown/SimpleDropdown.component';
import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'boxes-per-pallet',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.BoxesPerPallet'),
  minWidth: '70px',
  width: '9%',
  columnValue: ({
    order,
    catalog,
    amSeller,
    productList,
    onUpdateItem,
    openBppConfirmationModal,
    openBoxesPerPalletModal,
  }) => ({
    getElement: item => {
      // Find related issues and check for specific issue types
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = issuesRelated?.some(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );

      // Check if item is discarded (served quantity is 0)
      const isDiscarded = item.servedQuantity === 0;

      // Get boxes per pallet values for the current product
      const product = productList.find(p => p.id === item.childId);
      const boxesPerPalletValues = product?.boxesPerPalletValues;

      // Find default and client-specific values
      const defaultValue = boxesPerPalletValues?.find(b => b.isDefault)?.value;
      const clientValues = boxesPerPalletValues?.filter(b => b.clientId === order?.buyerId);
      const defaultClientValue = clientValues?.find(b => b.isDefault)?.value;

      /**
       * Determines the boxes per pallet value based on priority:
       * 1. Explicitly set value
       * 2. Default client value
       * 3. First client value
       * 4. Default value
       * 5. First available value
       * @returns The determined boxes per pallet value or null
       */
      const getBoxesPerPallet = (): number | null => {
        if (item.boxesPerPallet) return item.boxesPerPallet;
        if (defaultClientValue) return defaultClientValue;
        if (clientValues?.length > 0) return clientValues[0].value;
        if (defaultValue) return defaultValue;
        if (boxesPerPalletValues?.length > 0) return boxesPerPalletValues[0].value;
        return null;
      };

      // Create unique dropdown options excluding the current value
      const currentValue = getBoxesPerPallet();
      const uniqueValues = new Set(boxesPerPalletValues?.map(b => b.value) || []);
      const valuesArray: Array<IItem> = Array.from(uniqueValues)
        .filter(value => value !== currentValue)
        .map(value => ({
          key: value.toString(),
          value: `${value} pp`,
          color: 'black',
          action: () => {
            onUpdateItem({ ...item, boxesPerPallet: value });
            openBppConfirmationModal(item, value);
          },
        }));

      // Add the "add option" to the dropdown
      const addOption: IItem = {
        key: 'add-option',
        value: __('Components.Cart.boxesPerPallet.add_option'),
        // color: '#4a90e2',
        action: () => openBoxesPerPalletModal(item),
      };
      valuesArray.push(addOption);

      const isEditable =
        config.TOGGLE_BOXES_PER_PALLET.enabled &&
        catalog?.mandatoryPalletizationByClient &&
        amSeller &&
        order?.status === ORDER_STATUS.PENDING;

      const isItemInactive = item?.status && item.status !== 'active';

      if (isItemInactive) {
        return (
          <S.ItemPriceTotal isCancelled={false} showGrey={false}>
            -
          </S.ItemPriceTotal>
        );
      }

      return (
        <S.ArrowContainer className="arrow-options" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          {isEditable ? (
            <>
              <S.AmountBlack
                isClickable={!currentValue}
                showGrey={isDiscarded || hasNotFoundIssue}
                onClick={() => openBoxesPerPalletModal(item)}
              >
                {currentValue ? `${currentValue} pp` : __('Components.Cart.boxesPerPallet.add_option')}
              </S.AmountBlack>
              {currentValue && (
                <SimpleDropdown options={valuesArray} hAlign="left" onSelect={s => console.warn('wtf, TODO', s)}>
                  <S.Arrow name="Down-solid" />
                </SimpleDropdown>
              )}
            </>
          ) : (
            <S.Amount showGrey={isDiscarded || hasNotFoundIssue || orderUtils.isCanceled(order)}>
              {item.boxesPerPallet ? `${item.boxesPerPallet} pp` : null}
            </S.Amount>
          )}
        </S.ArrowContainer>
      );
    },
  }),
};
