import { __, orderUtils } from 'common-services';
import * as React from 'react';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'box-weight',
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.BoxWeight'),
  minWidth: '70px',
  width: '9%',
  columnValue: ({ order }) => ({
    getElement: item => {
      const isItemInactive = item?.status && item.status !== 'active';
      const isCancelled = false;
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = issuesRelated?.some(issue =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(issue.type),
      );
      const isDiscarded = item.servedQuantity === 0;
      const showGrey = isDiscarded || hasNotFoundIssue || orderUtils.isCanceled(order);

      if (isItemInactive) {
        return (
          <S.ItemPriceTotal isCancelled={isCancelled} showGrey={false}>
            -
          </S.ItemPriceTotal>
        );
      }

      return (
        <S.CellContainer showGrey={showGrey}>
          {item.boxWeight ? `${item.boxWeight} ${item.weightUnit}` : ''}
        </S.CellContainer>
      );
    },
  }),
};
