import {
  orderService,
  sellerWorkspaceService,
  RenderTrack,
  ORDER_ORIGIN,
  constants,
  ORDER_STATUS,
} from 'common-services';
import * as React from 'react';

import config from '../../../../../bindings/config';
import { AVAILABILITY } from '../../../../constants';
import { api } from '../../../../store';

import type {
  IOrder,
  IContact,
  IClient,
  IWorkspace,
  IUser,
  IPrice,
  sellerWorkspaceActions,
  buyerWorkspaceActions,
  orderActions,
  IOrderUser,
  IMember,
  IOrderItem,
  IAddress,
  IOrderDownloadOptions,
  IChannel,
  ISupplier,
  ICart,
  ColumnConfigType,
  ICustomItem,
} from 'common-services';

interface UseOrderInitializationProps {
  orderId?: number;
  order?: IOrder;
  cart: ICart;
  me: IUser;
  contact?: IContact;
  workspaceSelected: IWorkspace;
  contacts: { [key: number]: IContact };
  catalog?: IWorkspace;
  catalogs: { [cId: number]: IWorkspace };
  channelMembers?: Array<IMember>;
  clients?: Array<IClient>;
  suppliersOld?: Array<ISupplier>;
  commentsChannel?: IChannel;
  weAreBuyer: boolean;
  weAreSeller: boolean;
  hideBack?: boolean;
  editAfterAccept?: boolean;
  amEditor?: boolean;
  workspaces: Record<string, IWorkspace>;
  prices?: Record<number, IPrice>;
  notMarkAsRead?: boolean;
  clientsGet: typeof sellerWorkspaceActions.clientsGet;
  suppliersGet: typeof buyerWorkspaceActions.suppliersGet;
  orderChannelGet: typeof orderActions.orderChannelGet;
  orderGet: typeof orderActions.orderGet;
  tableVisibilityConfigGet: typeof sellerWorkspaceActions.tableVisibilityConfigGet;
}

interface OrderInitializationReturn extends UseOrderInitializationReturn {
  loading: boolean;
  error: Error | null;
  initialized: boolean;
}

export interface UseOrderInitializationReturn {
  logisticAddresses: Array<IAddress>;
  setLogisticAddresses: (addresses: Array<IAddress>) => void;
  pickupAddresses: Array<IAddress>;
  setPickupAddresses: (addresses: Array<IAddress>) => void;
  pdfFormats: Array<IOrderDownloadOptions>;
  initializeOrder: (order?: IOrder) => void;
  isFavoriteChecked: boolean;
  setIsFavoriteChecked: (checked: boolean) => void;
  contactId: number;
  itemsWithEmptyPrices: Array<IOrderItem>;
  anyItemWithEmptyPrice: boolean;
  isPro: boolean;
  client: IClient;
  hasItems: boolean;
  hasIssues: boolean;
  membersAsClient: Array<IMember>;
  sellerWorkspace: IWorkspace;
  isCatalogInactive: boolean;
  pricePrecision: number;
  commentsRibbon: string;
  setCommentsRibbon: (string) => void;
  updatesRibbon: string;
  setUpdatesRibbon: (string) => void;
  showBack: boolean;
  isBlocked: boolean;
  amViewer: boolean;
  seller: IOrderUser;
  buyer: IOrderUser;
  orderReferenceId: string | undefined;
  servedFlowEnabled: boolean;
  canEditDraft: boolean;
  canEdit: boolean;
  canServeOrder: boolean;
  orderEditable: boolean;
  isBuyerOfferingView: boolean;
  isDeletable: boolean;
  isQuoterMode: boolean;
  showServedColumn: boolean;
  isPrepared: boolean;
  isPendingOrder: boolean;
  isItemDeletable: boolean;
  customItemsCount: string;
  customItems: Array<ICustomItem>;
  hasInactiveProducts: boolean;
}

/**
 * Hook to handle order initialization and data loading
 */
const useOrderInitialization = ({
  orderId,
  cart,
  order,
  me,
  catalog,
  catalogs,
  workspaceSelected,
  contact,
  contacts,
  clients,
  suppliersOld,
  commentsChannel,
  channelMembers,
  weAreSeller,
  hideBack,
  workspaces,
  notMarkAsRead,
  clientsGet,
  suppliersGet,
  orderChannelGet,
  orderGet,
  tableVisibilityConfigGet,
  editAfterAccept,
  amEditor,
}: UseOrderInitializationProps): OrderInitializationReturn => {
  // Computed values
  const contactId = weAreSeller ? order?.buyerId : order?.sellerId;
  const isPro = sellerWorkspaceService.isProPlan(catalog?.plan);
  const showBack = !hideBack;

  // State management
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [initialized, setInitialized] = React.useState(false);
  const [logisticAddresses, setLogisticAddresses] = React.useState<Array<IAddress>>([]);
  const [pickupAddresses, setPickupAddresses] = React.useState<Array<IAddress>>([]);
  const [pdfFormats, setPdfFormats] = React.useState<Array<IOrderDownloadOptions>>([]);
  const [isFavoriteChecked, setIsFavoriteChecked] = React.useState(false);
  const [commentsRibbon, setCommentsRibbon] = React.useState('');
  const [updatesRibbon, setUpdatesRibbon] = React.useState('');

  // Get order on load
  React.useEffect(() => {
    if (orderId > 0 && me?.id) {
      if (!order?.changedItems && !order?.changedCustomItems && !order?.changedLogistics) {
        orderGet(me.id, orderId);
      }
      // else if (!order.items && cart.items.length === 0) {
      //   orderGet(me.id, orderId);
      // }
    }
  }, [orderId, me?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const itemsWithEmptyPrices = React.useMemo(() => {
    const items = cart.items.filter(
      i =>
        order?.servedFlowEnabled ||
        i.amount ||
        order?.origin === ORDER_ORIGIN.IMPORT_UI ||
        order?.origin === ORDER_ORIGIN.EMAIL,
    );
    return items.filter(i => !i.price);
  }, [cart.items, order?.servedFlowEnabled, order?.origin]);

  const allInactiveProducts = React.useMemo(() => {
    if (order?.items.length === 0) return false;

    return order?.items.every(i => i.status && i.status !== AVAILABILITY.ACTIVE);
  }, [order?.items]);

  const hasInactiveProducts = React.useMemo(() => {
    return order?.items.some(i => i.status && i.status !== AVAILABILITY.ACTIVE);
  }, [order?.items]);

  const { seller, buyer } = React.useMemo(() => {
    if (order) return orderService.getSellerBuyer(order, contacts, me, clients || [], suppliersOld || []);
    return { seller: undefined, buyer: undefined };
  }, [order, contacts, me, clients, suppliersOld]);

  const orderReferenceId: string | undefined = React.useMemo(() => {
    return (
      order?.externalIdSeller ||
      order?.externalIdBuyer ||
      cart.externalIdSeller ||
      cart.externalIdBuyer ||
      (order?.hashId ? '#' + order?.hashId : undefined)
    );
  }, [order, cart]);

  const amViewer = React.useMemo(() => {
    const myRole = catalog?.members.find(member => member.userId === me.id)?.role;
    return myRole === 'viewer' ? true : false;
  }, [catalog, me?.id]);

  // Memoize served flow status
  const servedFlowEnabled = React.useMemo(
    () => order?.status === ORDER_STATUS.ACCEPTED && order?.servedFlowEnabled,
    [order?.status, order?.servedFlowEnabled],
  );

  // Memoize custom items
  const customItems = React.useMemo(
    () => order?.customItems.filter(ci => ci.type !== 'shipping'),
    [order?.customItems],
  );

  // Memoize favorite status
  const customItemsCount = React.useMemo(
    () => (customItems?.length > 0 ? customItems?.length.toString() : ''),
    [customItems?.length],
  );

  // Memoize draft edit draft permission
  const canEditDraft = React.useMemo(
    () => weAreSeller && order?.status === ORDER_STATUS.DRAFT,
    [weAreSeller, order?.status],
  );

  // Memoize serve order permission
  const canServeOrder = React.useMemo(() => servedFlowEnabled && weAreSeller, [servedFlowEnabled, weAreSeller]);

  // Memoize order editable status
  const orderEditable = React.useMemo(
    () => order?.status === 'pending' || canServeOrder || canEditDraft,
    [order?.status, canServeOrder, canEditDraft],
  );
  // Memoize order from offer
  const isBuyerOfferingView = React.useMemo(() => order?.origin === ORDER_ORIGIN.OFFERS, [order?.origin]);

  // Memoize draft edit permission
  const canEdit = React.useMemo(() => weAreSeller && amEditor && orderEditable, [weAreSeller, orderEditable, amEditor]);

  // Memoize deletable status
  const isDeletable = React.useMemo(() => !order?.id || order?.items.length > 1, [order?.id, order?.items.length]);

  // Memoize quoter mode status
  const isQuoterMode = React.useMemo(
    () => (config.TOGGLE_MARGINS.enabled && weAreSeller && catalog?.plan?.addons?.quoterMarginsEnabled) || false,
    [weAreSeller, catalog?.plan?.addons?.quoterMarginsEnabled],
  );

  // Memoize served column visibility
  const showServedColumn = React.useMemo(
    () => servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order)),
    [servedFlowEnabled, editAfterAccept, order],
  );

  // Memoize prepared status
  const isPrepared = React.useMemo(() => !!order?.items.find(i => i.isPrepared), [order?.items]);

  // Memoize pending order status
  const isPendingOrder = React.useMemo(
    () => [ORDER_STATUS.PENDING, ORDER_STATUS.DRAFT].includes(order?.status),
    [order?.status],
  );

  // Memoize item deletable status
  const isItemDeletable = React.useMemo(
    () => isDeletable && amEditor && orderEditable && (isPendingOrder || (canServeOrder && editAfterAccept)),
    [isDeletable, amEditor, orderEditable, isPendingOrder, canServeOrder, editAfterAccept],
  );

  const anyItemWithEmptyPrice = React.useMemo(() => itemsWithEmptyPrices.length > 0, [itemsWithEmptyPrices]);

  const client = React.useMemo(() => clients?.find(c => c.userId === contactId), [clients, contactId]);
  const isBlocked = React.useMemo(
    () => contact?.imBlocked || contact?.imBlocking || client?.blockedOrders,
    [contact, client],
  );

  const hasItems = React.useMemo(() => order?.items.length > 0, [order?.items]);

  const hasIssues = React.useMemo(() => !!order?.issues?.filter(i => i.status === 'pending').length, [order?.issues]);

  const membersAsClient = React.useMemo(
    () => channelMembers?.filter(m => !catalog?.members.find(c => c.userId === m.id)),
    [channelMembers, catalog?.members],
  );

  const sellerWorkspace = React.useMemo(() => catalogs[order?.catalogId], [catalogs, order?.catalogId]);

  const isCatalogInactive = React.useMemo(
    () => (weAreSeller ? sellerWorkspaceService.getMember(sellerWorkspace, me.id)?.status !== 'active' : false),
    [weAreSeller, sellerWorkspace, me.id],
  );

  const pricePrecision = React.useMemo(
    () => (order?.id ? order?.pricePrecision : catalog ? catalog.numberOfDecimalsShowed : constants.PRICE_PRECISION),
    [order?.id, order?.pricePrecision, catalog],
  );

  /**
   * Load addresses for logistics
   */
  const loadLogisticsAddresses = React.useCallback(async (order: IOrder) => {
    try {
      const { pickupAddresses, shippingAddresses } = await api.user.getAddressesBySellerBuyer(
        order?.buyerId,
        order?.sellerId,
        order?.catalogId,
        order?.buyerWorkspaceId,
      );

      setLogisticAddresses(shippingAddresses);
      setPickupAddresses(pickupAddresses);
    } catch (err) {
      console.error('Error loading logistics addresses:', err);
      setError(err as Error);
    }
  }, []);

  /**
   * Load PDF formats for seller
   */
  const loadPdfFormats = React.useCallback(
    async (catalogId: number) => {
      if (!weAreSeller || !catalogId) return;

      try {
        const formats = await api.sellerWorkspace.getSettingsDownloadPdf(me.id, catalogId);
        setPdfFormats(formats || []);
      } catch (err) {
        console.error('Error loading PDF formats:', err);
        // Don't set error as this is not critical
      }
    },
    [weAreSeller, me.id],
  );

  /**
   * Track order render for analytics
   */
  const trackOrderRender = React.useCallback((order: IOrder) => {
    RenderTrack.track('OrderDetails', {
      orderId: order?.id.toString(),
      status: order?.status,
      price: order?.price,
      numItems: order?.items.length,
      sellerId: order?.sellerId.toString(),
      buyerId: order?.buyerId.toString(),
      authorId: order?.authorId.toString(),
      renderTime: Date.now(),
    });
  }, []);

  /**
   * Check if prices need to be fetched
   */
  // const shouldFetchPrices = React.useCallback(() => {
  //   if (prices) return false;

  //   const contactId = weAreBuyer ? order?.sellerId : order?.buyerId;
  //   if (me.id === contactId || !contactId) return false;

  //   return true;
  // }, [prices, weAreBuyer, me.id, order?.sellerId, order?.buyerId]);

  const initializeOrder = React.useCallback(
    async (order?: IOrder) => {
      try {
        setLoading(true);

        // Handle address issues
        const hasAddressIssues = order?.issues?.some(err =>
          ['address-not-found', 'address-code-not-found', 'pickup-not-found', 'pickup-code-not-found'].includes(
            err.type,
          ),
        );

        if (hasAddressIssues) {
          await loadLogisticsAddresses(order);
        }

        // Load PDF formats if seller
        if (weAreSeller) {
          await loadPdfFormats(order?.catalogId);
        }

        // Track order render if not marked as read
        if (order?.id && !notMarkAsRead) {
          if (order?.sellerId === me.id || order?.buyerId === me.id || order?.delegateId === me.id) {
            trackOrderRender(order);
          }
        }

        setInitialized(true);
        setLoading(false);
      } catch (err) {
        setError(err as Error);
        setLoading(false);
      }
    },
    [loadLogisticsAddresses, loadPdfFormats, weAreSeller, me.id, notMarkAsRead, trackOrderRender],
  );

  /**
   * Main initialization effect
   */

  const getVisibilityConfig = React.useCallback(
    (userId: number, workspaceId: number, view: ColumnConfigType) => {
      tableVisibilityConfigGet(userId, workspaceId, view);
    },
    [tableVisibilityConfigGet], // Action creator should be stable across renders if provided by Redux connect
  );

  React.useEffect(
    () => {
      if (workspaceSelected?.id) {
        getVisibilityConfig(me.id, workspaceSelected.id, weAreSeller ? 'order_detail' : 'order_detail_buyer');
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (order) {
      const init = async (): Promise<void> => {
        await initializeOrder(order);
      };
      init().catch(err => console.error('Error initializing order:', err));
    }
  }, [order, initializeOrder]);

  /**
   * Effect to handle client/supplier loading
   */
  React.useEffect(() => {
    if (!catalog || !me.id) return;

    // Load clients if needed
    if (!clients && sellerWorkspaceService.getMember(catalog, me.id)) {
      clientsGet(me.id, catalog.id);
    }

    // Load suppliers if needed
    const buyerWorkspace = workspaces[order?.buyerWorkspaceId];
    if (buyerWorkspace && !suppliersOld && sellerWorkspaceService.getMember(buyerWorkspace, me.id)) {
      suppliersGet(me.id, buyerWorkspace.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect to handle comments channel
   */
  React.useEffect(() => {
    if (order?.commentsChannel && !commentsChannel) {
      orderChannelGet(me.id, order?.commentsChannel);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    error,
    contactId,
    isPro,
    itemsWithEmptyPrices,
    anyItemWithEmptyPrice,
    membersAsClient,
    sellerWorkspace,
    isCatalogInactive,
    pricePrecision,
    client,
    hasItems,
    hasIssues,
    logisticAddresses,
    setLogisticAddresses,
    pickupAddresses,
    setPickupAddresses,
    pdfFormats,
    initialized,
    initializeOrder, // eslint-disable-line @typescript-eslint/no-misused-promises
    isFavoriteChecked,
    setIsFavoriteChecked,
    commentsRibbon,
    setCommentsRibbon,
    updatesRibbon,
    setUpdatesRibbon,
    showBack,
    isBlocked,
    seller,
    buyer,
    orderReferenceId,
    amViewer,
    servedFlowEnabled,
    canEdit,
    canEditDraft,
    canServeOrder,
    orderEditable,
    isBuyerOfferingView,
    isDeletable,
    isQuoterMode,
    showServedColumn,
    isPrepared,
    isPendingOrder,
    isItemDeletable,
    customItemsCount,
    customItems,
    hasInactiveProducts: allInactiveProducts ? false : hasInactiveProducts,
  };
};

export default useOrderInitialization;
