import {
  __,
  LOCALE,
  offerActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceSelectors,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../../constants';
import history from '../../../history';
import getPath from '../../../util/routes';
import { Button } from '../../atoms';
import { EmptyListResource, SearchProductModal, SelectClientModal } from '../../molecules';
import OfferTable from '../../molecules/OfferTable';
import Workspace from '../Workspace/Workspace.component';
import * as S from './WorkspaceOffersListSeller.styled';

import type { IOfferResponsePageable, IPriceGroup } from 'common-services';
import type { RouteComponentProps } from 'react-router-dom';
import type { Dispatch } from 'redux';

export type IRouteProps = RouteComponentProps<{
  workspaceId?: string;
  clientId?: string;
}>;

export type IProps = IRouteProps;

const WorkspaceOffers: React.FC<IProps> = ({
  match: {
    params: { clientId, workspaceId },
  },
}) => {
  const history = useHistory();
  const me = useSelector(userSelectors.getUser);
  const catalog = useSelector(sellerWorkspaceSelectors.getCatalog(Number(workspaceId)));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<Dispatch<any>>();

  const [showClientsModal, setShowClientsModal] = React.useState(false);
  const [showSearchProductModal, setShowSearchProductModal] = React.useState(false);
  const [showWorkspaceOfferEdit, setShowWorkspaceOfferEdit] = React.useState(false);

  const client = useSelector(sellerWorkspaceSelectors.getClient(Number(workspaceId), Number(clientId)));

  const [contactId, setContactId] = React.useState<number>(0);

  const priceGroups: { [priceGroupId: string]: IPriceGroup } = useSelector(
    sellerWorkspaceSelectors.getPriceGroups(Number(workspaceId)),
  );

  React.useEffect(() => {
    dispatch(sellerWorkspaceActions.priceGroupsGet(Number(workspaceId)));
  }, [workspaceId, dispatch]);

  const priceGroupArr = Object.values(priceGroups);
  const contactPriceGroups = priceGroupArr.filter(pg => pg.clients.some(client => client.userId === contactId));
  const priceGroupsIds = contactPriceGroups.map(pg => pg.priceGroupId);
  return (
    <Workspace
      subtitle=""
      title={showWorkspaceOfferEdit ? client?.name : undefined}
      tabSelected="offers"
      workspaceId={Number(workspaceId)}
      isBuyerWorkspace={false}
      parentSections={[
        {
          label: __('Components.ProductsList.Tabs.offers'),
          action: () => {
            history.push(
              getPath({
                path: ROUTE_PATHS.WORKSPACE_OFFERS,
                workspaceId,
              }),
            );
            setShowWorkspaceOfferEdit(false);
          },
        },
      ]}
    >
      {OffersList(Number(workspaceId), setShowClientsModal)}
      {showClientsModal ? openSelectClientModal() : null}
      {showSearchProductModal ? openSearchProductsModal() : null}
    </Workspace>
  );

  function openSelectClientModal(): JSX.Element {
    return (
      <SelectClientModal
        title={__('Components.SellerOffer.modal.title')}
        subtitle={__('Components.SellerOffer.modal.subtitle')}
        onClose={() => setShowClientsModal(false)}
        isPurchase={false}
        selectContact={(contactId: number) => {
          setContactId(contactId);
          setShowClientsModal(false);
          setShowSearchProductModal(true);
        }}
        catalogId={Number(workspaceId)}
        me={me}
      />
    );
  }

  function openSearchProductsModal(): JSX.Element {
    return (
      <SearchProductModal
        amSeller={true}
        cartClean={undefined}
        catalog={catalog}
        close={() => setShowSearchProductModal(false)}
        contactId={Number(clientId)}
        priceGroupIds={priceGroupsIds}
        featured={{}}
        me={me}
        name=""
        originLot={1} // WTF ??????
        onSubmitSelectedProducts={p => {
          history.push(
            getPath({
              path: ROUTE_PATHS.WORKSPACE_OFFER_EDIT,
              workspaceId,
              offerId: 'new',
            }),
            {
              products: p,
              clientId: contactId,
            },
          );
        }}
        priceMode="read"
        selectMode="offers"
        showFeatured={false}
        status="active"
        prices={{}}
      />
    );
  }
};

const OffersList = (
  workspaceId: number,
  setShowClientsModal: React.Dispatch<React.SetStateAction<boolean>>,
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<Dispatch<any>>();

  const me = useSelector(userSelectors.getUser);

  const [t, _] = React.useState(Date.now());
  const [searchState, setSearchState] = React.useState({
    text: '',
    language: me?.settings?.language || LOCALE,
    index: '',
    status: ['active', 'unavailable'],
    sort: 'updatedAt',
    sortOrder: 'desc',
    type: '',
    sellers: [],
    warehouses: [],
    references: [],
  });

  const [debouncedValue, setDebouncedValue] = React.useState(searchState.text);
  // let debounceTimer: ReturnType<typeof setTimeout>;
  const debounceTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const [offers, setOffers] = React.useState<IOfferResponsePageable>({
    elements: [],
    is_first_page: true,
    is_last_page: false,
    page: 1,
    total_pages: 0,
    total_elements: 0,
  });

  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  // const [lastPage, setLastPage] = React.useState(false);

  React.useEffect(() => {
    if (workspaceId) {
      setLoading(true);
      dispatch(
        offerActions.offerListPageable(
          Number(workspaceId),
          (res: IOfferResponsePageable) => {
            setOffers(prevState => ({
              ...res,
              elements: [
                ...prevState.elements,
                ...res.elements.filter(
                  newElement => !prevState.elements.some(existingElement => existingElement.id === newElement.id),
                ),
              ],
            }));
            setLoading(false);
          },
          {
            page,
            size: 50,
            sort_by: 'update_at',
            order: 'desc',
            name: searchState.text,
          },
        ),
      );
    }
  }, [workspaceId, page, searchState.text, dispatch]);

  // Sort and filter offers
  const [filteredOffers, setFilteredOffers] = React.useState<IOfferResponsePageable>();
  React.useEffect(() => {
    const filteredElements = offers.elements
      .filter(offer => {
        return searchState.text.length > 0 ? offer.name.toLowerCase().includes(searchState.text.toLowerCase()) : true;
      })
      .sort((a, b) => {
        if (searchState.sort === 'updatedAt') {
          if (searchState.sortOrder === 'asc') {
            return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
          } else {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
          }
        }
        return 0;
      });

    setFilteredOffers(prevState => ({
      ...prevState,
      elements: filteredElements,
      is_first_page: offers.is_first_page,
      is_last_page: offers.is_last_page,
      page: offers.page,
      total_pages: offers.total_pages,
      total_elements: offers.total_elements,
    }));
  }, [searchState, offers]);

  React.useEffect(() => {
    RenderTrack.track('WorkspaceOffersListSeller', {
      renderTime: t,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = (): void => {
    if (!loading && !offers.is_last_page && page <= offers.total_pages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleInputChange = React.useCallback((t: string) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      setDebouncedValue(t);
    }, 300);
  }, []);

  React.useEffect(() => {
    setSearchState(prevState => ({ ...prevState, text: debouncedValue }));
  }, [debouncedValue]);

  function EmptyOffersList(): JSX.Element {
    return (
      <S.CenterContainer>
        <EmptyListResource
          text={__('Components.SellerOffer.zero_case.title')}
          text2={__('Components.SellerOffer.zero_case.subtitle')}
          buttonText={__('Components.SellerOffer.zero_case.text_cta')}
          buttonType="principal"
          buttonAction={() => setShowClientsModal(true)}
        />
      </S.CenterContainer>
    );
  }

  return (
    <S.Row>
      <S.WsWrapper>
        <S.HeaderDashboards>
          <S.HeaderFilters>
            <S.LeftFiltersRow>
              <S.SimpleSearch
                id="search-offers"
                placeHolder={__('Components.SellerOffer.search')}
                onChange={(t: string) => handleInputChange(t)}
              />
            </S.LeftFiltersRow>
            {filteredOffers?.elements.length > 0 ? (
              <S.RightCtaRow>
                <Button
                  onClick={() =>
                    history.push(
                      getPath({
                        path: ROUTE_PATHS.WORKSPACE_REFERENCE_LIST,
                        workspaceId: workspaceId.toString(),
                      }),
                    )
                  }
                  type="secondary"
                >
                  {__('Components.SellerOffer.listOfReferences')}{' '}
                </Button>
                <Button onClick={() => setShowClientsModal(true)}>{__('Components.SellerOffer.new_offer_cta')}</Button>
              </S.RightCtaRow>
            ) : null}
          </S.HeaderFilters>
        </S.HeaderDashboards>
        {filteredOffers?.elements.length > 0 && filteredOffers.elements ? (
          <OfferTable data={filteredOffers.elements ?? []} onLoadMore={handleLoadMore} />
        ) : (
          EmptyOffersList()
        )}
      </S.WsWrapper>
    </S.Row>
  );
};

export default React.memo(WorkspaceOffers);
