import * as React from 'react';

import { Input } from '../../atoms';
import { InputWithLabel } from '../../molecules';
import * as S from './ProductEdit.styled';

const Traceability = React.lazy(() => import('../Traceability'));

interface NumberInputProps {
  name: string;
  label: string;
  changeProduct: (key: string, value: string | number, error?: string) => void; // Actualizado
  value?: string | number;
  isRequired?: boolean;
  minValue?: number;
  maxValue?: number;
  precision?: number;
  variableTextSingular?: string;
  variableTextPlural?: string;
  editMode: boolean;
  errors: Map<string, string>;
}

export const NumberInput = React.memo(
  ({
    name,
    label,
    changeProduct,
    value,
    isRequired,
    minValue,
    maxValue,
    precision,
    variableTextSingular,
    variableTextPlural,
    editMode,
    errors,
  }: NumberInputProps) => {
    const [displayValue, setDisplayValue] = React.useState('');
    const [isEditing, setIsEditing] = React.useState(false);

    const formatValue = React.useCallback(
      (val: string | number) => {
        if (val === '' || val === null || val === undefined) return '';
        const num = parseFloat(val.toString());
        if (isNaN(num)) return '';
        return num.toFixed(precision);
      },
      [precision],
    );

    React.useEffect(() => {
      if (!isEditing) {
        setDisplayValue(formatValue(value));
      }
    }, [value, formatValue, isEditing]);

    const handleChange = (name: string, newValue: string) => {
      if (newValue === '' || /^\d*[\.,]?\d*$/.test(newValue)) {
        setDisplayValue(newValue);
        setIsEditing(true);
        const normalizedValue = newValue.replace(',', '.');
        const numberValue = parseFloat(normalizedValue);

        if (!isNaN(numberValue)) {
          changeProduct(name, numberValue, undefined);
        } else if (newValue === '') {
          changeProduct(name, '', undefined);
        }
      }
    };

    const handleBlur = () => {
      setIsEditing(false);

      const normalizedValue = displayValue.replace(',', '.');
      let numberValue = parseFloat(normalizedValue);
      if (!isNaN(numberValue)) {
        if (minValue !== undefined && numberValue < minValue) {
          numberValue = minValue;
        }
        if (maxValue !== undefined && numberValue > maxValue) {
          numberValue = maxValue;
        }
        const formattedValue = formatValue(numberValue);
        setDisplayValue(formattedValue);
        changeProduct(name, numberValue, undefined);
      } else {
        setDisplayValue('');
        changeProduct(name, '', undefined);
      }
    };

    return (
      <S.Row>
        <InputWithLabel label={label} isRequired={isRequired} width="100%">
          <Input
            name={name}
            containerMargin={'4px 0'}
            width="100%"
            type="text"
            value={displayValue}
            hasError={!!errors.get(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired={isRequired}
            disabled={!editMode}
            minValue={minValue}
            maxValue={maxValue}
            precision={precision}
            variableTextSingular={variableTextSingular}
            variableTextPlural={variableTextPlural}
          />
        </InputWithLabel>
      </S.Row>
    );
  },
);
