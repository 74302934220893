import { __ } from 'common-services';
import * as React from 'react';

import { AVAILABILITY } from '../../../../../../../../constants';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';

export const column: OrderItemColumnSpec = {
  id: 'sku',
  rank: 1,
  isVisible: true,
  getTitle: () => __('Components.ProductsList.Table.Sku'),
  minWidth: '70px',
  width: '9%',
  columnValue: () => ({
    getValue: item => item.sku || '-',
    getElement: item => {
      const isItemInactive = item?.status && item.status !== AVAILABILITY.ACTIVE;

      return <S.CellContainer showGrey={isItemInactive}>{item.sku || '-'}</S.CellContainer>;
    },
  }),
};
