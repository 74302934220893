import { __, productService, type IOrderItem } from 'common-services';
import * as React from 'react';

import { AVAILABILITY } from '../../../../../../constants';
import { Ribbon } from '../../../../../atoms';

import * as S from './ProductsStatusRibbon.styled';

import type { IProdType } from 'common-services';

interface IProductsStatusRibbonProps {
  items: Array<IOrderItem>;
  weAreSeller: boolean;
  prodTypes: Record<string, IProdType>;
}

const ProductsStatusRibbon: React.FC<IProductsStatusRibbonProps> = ({
  items,
  weAreSeller,
  prodTypes,
}: IProductsStatusRibbonProps) => {
  const productsToShow = items
    .filter(item => item.status !== AVAILABILITY.ACTIVE)
    .map(item => {
      const typeVariety = productService.getProductTypeVarietyDisplay(
        item.type,
        prodTypes[item.type]?.name || '',
        item.title,
      );

      return { title: item.productTitle || `${typeVariety} ${item.size}`, status: item.status, id: item.id };
    });

  const message = getMessage(productsToShow.length, weAreSeller, productsToShow[0]?.title);

  return <Ribbon type="info" text={message} />;
};

const getMessage = (count: number, weAreSeller: boolean, product?: string): React.ReactNode => {
  const keyPrefix = `Components.OrderDetails.ribbon_product_${weAreSeller ? 'seller' : 'buyer'}`;
  const message =
    count > 1 ? __(`${keyPrefix}.inactive_products`, { count }) : __(`${keyPrefix}.inactive_product`, { product });

  return getFormattedMessage(message, count > 1);
};

const getFormattedMessage = (message: string, plural: boolean): React.ReactNode => {
  if (plural) {
    const words = message.split(' ');
    const firstTwoWords = words.slice(0, 2).join(' ');
    const restOfMessage = words.slice(2).join(' ');

    return (
      <S.Text>
        <S.TextStrong>{firstTwoWords}</S.TextStrong>
        {restOfMessage}
      </S.Text>
    );
  }
  return message;
};

export default ProductsStatusRibbon;
