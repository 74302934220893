import { ORDER_STATUS, __, constants, currency, orderService, orderUtils, parsers } from 'common-services';
import { isAllowedSaleUnit } from 'common-services/dist/product/service';
import * as React from 'react';

import config from '../../../../../../../../../bindings/config';
import { AVAILABILITY } from '../../../../../../../../constants';
import { AllProductUnitsArray } from '../../../../../../../../domain/product';
import { priceUnitTranslate } from '../../../../../../../../util/utils';
import { ColumnContainer, Input, Select } from '../../../../../../../atoms';

import * as S from './columns.styled';

import type { OrderItemColumnSpec } from '../hooks/useColumnsProps';
import type { PRODUCT_UNIT, IProduct } from 'common-services';

export const column: OrderItemColumnSpec = {
  id: 'unit-price',
  rank: 1003,
  isVisible: true,
  getTitle: ({ isQuoterMode }) =>
    isQuoterMode ? __('Components.Cart.items.proposal') : __('Components.Cart.items.unit_price'),
  minWidth: '220px',
  width: '12%',
  condition: ({ isPrepare }) => !isPrepare,
  columnValue: ({
    order,
    catalog,
    pricePrecision,
    priceMode,
    canServeOrder,
    amEditor,
    weAreSeller,
    isDeletable,
    servedFlowEnabled,
    editAfterAccept,
    isPendingOrder,
    togglePriceMismatchEnable,
    openPriceModal,
    discardLine,
    updateItemPrice,
    priceToShow,
    onUpdateItem,
    renderArrowPercentage,
    priceEdit,
    renderTooltip,
    setPriceEdit,
  }) => ({
    getElement: item => {
      const issuesRelated = order?.issues.filter(issue => issue.orderItemId === item.id);
      const hasNotFoundIssue = !!issuesRelated?.find(i =>
        ['product-not-found', 'code-not-found', 'many-internal-codes-for-external'].includes(i.type),
      );
      const isItemInactive = item?.status && item.status !== AVAILABILITY.ACTIVE;

      if (isItemInactive) {
        return <S.CellContainer showGrey={false}>-</S.CellContainer>;
      }
      const isDiscarded = item.servedQuantity === 0;
      if (issuesRelated?.find(i => i.type === 'product-no-price')) {
        return (
          <S.ProductPriceRow>
            <S.WarningIcon name="Warning" disableHover={true} />
            <ColumnContainer>
              <S.Text showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                {__('Components.ProductDetails.price_missing')}
              </S.Text>
              {amEditor && weAreSeller && order?.status !== ORDER_STATUS.CANCELED ? (
                <S.Text showGrey={hasNotFoundIssue || isDiscarded}>
                  {__('Components.ProductDetails.price_missing_actions.0') + ' '}
                  <S.TextLink onClick={() => openPriceModal(item)}>
                    {__('Components.ProductDetails.price_missing_actions.1')}
                  </S.TextLink>
                  {isDeletable ? (
                    <>
                      {' ' + __('Components.ProductDetails.price_missing_actions.2') + ' '}
                      <S.TextLink onClick={e => discardLine(e, item)}>
                        {__('Components.ProductDetails.price_missing_actions.3')}
                      </S.TextLink>
                      .
                    </>
                  ) : null}
                </S.Text>
              ) : null}
            </ColumnContainer>
          </S.ProductPriceRow>
        );
      }
      const showServedColumn = servedFlowEnabled && (editAfterAccept || orderService.hasServedChanges(order));

      const hasServedChange = showServedColumn && item.price !== item.servedPrice;
      const priceDisplay = priceToShow(item);
      const price = showServedColumn ? item.servedPrice : item.price;

      const invalidPriceIssue = issuesRelated?.find(i => i.type === 'price-not-valid');
      const invalidAmountIssue = issuesRelated?.find(i => i.type === 'amount-not-valid');

      if (invalidPriceIssue) {
        return (
          <S.UnitPriceColumn>
            <S.ProductPriceRow>
              <S.WarningIcon name="Warning" disableHover={true} />
              {item.childId ? (
                <S.TablePriceInput
                  id={`price-${item.id}`}
                  name={showServedColumn ? 'servedPrice' : 'price'}
                  onBlur={(key, v, error) => updateItemPrice(item, key, v, error)}
                  value={0}
                  minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                  variableTextSingular={currency.getPricePerUnit(
                    item.currency,
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                      ? priceUnitTranslate(item.priceUnit)
                      : item.priceUnit,
                    item.weightUnit,
                  )}
                  precision={pricePrecision}
                  width="120px"
                  type="number"
                  textAlign="right"
                  // autoFocus={index === 0}
                  hasError={true}
                  isIssue={true}
                />
              ) : (
                <S.NoDataText width="120px">{__('Components.ProductDetails.no_data')}</S.NoDataText>
              )}
            </S.ProductPriceRow>
            {item.childId ? (
              <S.Text width="120px" showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                {__('order?.priceUnit')}{' '}
                <S.TextLink
                  onClick={() => onUpdateItem({ ...item, isPor: false, servedPrice: item.price }, invalidPriceIssue)}
                >
                  {currency.getPricePerUnit(
                    item.currency,
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                      ? priceUnitTranslate(item.priceUnit)
                      : item.priceUnit,
                    item.weightUnit,
                    item.price,
                  )}
                </S.TextLink>
              </S.Text>
            ) : null}
          </S.UnitPriceColumn>
        );
      }

      const mismatchPriceIssue = issuesRelated?.find(i => i.type === 'no-pricegroup-matching');
      const pricePriceGroup = mismatchPriceIssue?.name ? Number(mismatchPriceIssue.name) : 0;
      const priceDiff = Number.isNaN(pricePriceGroup) ? 0 : item.price - pricePriceGroup;
      const editable = amEditor && (isPendingOrder || (canServeOrder && editAfterAccept));

      // ToDo: this ñapa highlights the need of some abstract IProduct/IOrderItem properties
      // receiver for isAllowedSaleUnit, which receives a IProduct only. A possible solution can be
      // to pass a command object with 5 needed properties instead of IProduct.
      const itemToProduct: IProduct = item as unknown as IProduct;
      const availableSaleUnits = AllProductUnitsArray.filter(u =>
        isAllowedSaleUnit(itemToProduct, u, catalog?.mandatoryPalletizationByClient),
      );

      if (!editable || priceMode !== 'edit' || !item.servedQuantity) {
        return (
          <S.PriceGroupColumn>
            <S.PriceRow>
              <ColumnContainer>
                <S.PriceRow>
                  {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? renderTooltip() : null}
                  <S.ItemPrice
                    showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}
                    isCancelled={orderUtils.isCanceled(order) || order?.status === ORDER_STATUS.INCOMPLETE}
                    hasServedChange={hasServedChange}
                  >
                    {priceDisplay}
                  </S.ItemPrice>
                </S.PriceRow>
                {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? (
                  <S.TextPriceGroup
                    showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}
                    padding="6px 12px 0 0"
                  >
                    {__('Components.ProductDetails.mismatch_price.price')}
                    <S.TextPriceMismatch showGrey={hasNotFoundIssue || isDiscarded || orderUtils.isCanceled(order)}>
                      {Number(mismatchPriceIssue.name).toFixed(pricePrecision) +
                        currency.getPricePerUnit(
                          item.currency,
                          config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                            ? priceUnitTranslate(item.priceUnit)
                            : item.priceUnit,
                          item.weightUnit,
                        )}
                    </S.TextPriceMismatch>
                  </S.TextPriceGroup>
                ) : null}
              </ColumnContainer>
              {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue
                ? renderArrowPercentage(priceDiff, 'price', item)
                : null}
            </S.PriceRow>
          </S.PriceGroupColumn>
        );
      }
      if (priceEdit || !item.price || showServedColumn) {
        return (
          <S.PriceColumn
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <S.PriceRow>
              {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? renderTooltip() : null}
              {config.TOGGLE_ORDER_PRICE_UNITS.enabled ? (
                <S.QuantityRow>
                  <S.AmountInput
                    id={`price-${item.id}`}
                    name={showServedColumn ? 'servedPrice' : 'price'}
                    onBlur={(key, value, error) => {
                      updateItemPrice(item, key, value, error);
                    }}
                    value={price || 0}
                    minValue={0}
                    precision={pricePrecision}
                    width="100px"
                    type="number"
                    textAlign="right"
                    // autoFocus={false}
                    hasError={showServedColumn ? !item.servedPrice : !item.price}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    containerMargin="0 6px 0 0"
                    isIssue={!!invalidAmountIssue}
                    variableTextSingular={constants.CURRENCIES[item.currency].symbol}
                  />
                  <Select
                    containerMargin="0"
                    value={item.priceUnit}
                    name={`served-sale-unit-${item.id}`}
                    options={availableSaleUnits.map(s => ({
                      value: s,
                      label: parsers.getUnitText(
                        config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                          ? priceUnitTranslate(s)
                          : s,
                        item.weightUnit,
                        0,
                      ),
                    }))}
                    onChange={(n, v) => {
                      onUpdateItem({
                        ...item,
                        priceUnit: v as PRODUCT_UNIT,
                      });
                    }}
                    width="100px"
                  />
                </S.QuantityRow>
              ) : (
                <Input
                  id={`price-${item.id}`}
                  name={showServedColumn ? 'servedPrice' : 'price'}
                  onBlur={(key, value, error) => updateItemPrice(item, key, value, error)}
                  value={price || 0}
                  minValue={currency.getPriceInputMinimumValue(pricePrecision)}
                  variableTextSingular={currency.getPricePerUnit(
                    item.currency,
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                      ? priceUnitTranslate(item.priceUnit)
                      : item.priceUnit,
                    item.weightUnit,
                  )}
                  precision={pricePrecision}
                  width="120px"
                  type="number"
                  textAlign="right"
                  // autoFocus={index === 0}
                  hasError={showServedColumn ? !item.servedPrice : !item.price}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              )}
              {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue
                ? renderArrowPercentage(priceDiff, 'input', item)
                : null}
            </S.PriceRow>
            {togglePriceMismatchEnable && weAreSeller && mismatchPriceIssue ? (
              <S.TextPriceGroup padding="6px 0 0 0">
                {__('Components.ProductDetails.mismatch_price.price')}
                <S.TextLink onClick={() => updateItemPrice(item, 'servedPrice', mismatchPriceIssue.name, '')}>
                  {Number(mismatchPriceIssue.name).toFixed(pricePrecision) +
                    currency.getPricePerUnit(
                      item.currency,
                      config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                        ? priceUnitTranslate(item.priceUnit)
                        : item.priceUnit,
                      item.weightUnit,
                    )}
                </S.TextLink>
              </S.TextPriceGroup>
            ) : null}
          </S.PriceColumn>
        );
      }

      return (
        <S.PriceEditRow
          enabled={priceMode === 'edit'}
          onClick={e => {
            if (priceMode === 'edit') setPriceEdit(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {config.TOGGLE_ORDER_PRICE_UNITS.enabled ? (
            <S.QuantityRow>
              <S.AmountInput
                id={`price-${item.id}`}
                name={showServedColumn ? 'servedPrice' : 'price'}
                onBlur={(key, value, error) => {
                  updateItemPrice(item, key, value, error);
                }}
                value={price || 0}
                minValue={0}
                precision={pricePrecision}
                width="100px"
                type="number"
                textAlign="right"
                // autoFocus={false}
                hasError={showServedColumn ? !item.servedPrice : !item.price}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                containerMargin="0 6px 0 0"
                isIssue={!!invalidAmountIssue}
                variableTextSingular={constants.CURRENCIES[item.currency].symbol}
              />
              <Select
                containerMargin="0"
                value={item.priceUnit}
                name={`served-sale-unit-${item.id}`}
                options={availableSaleUnits.map(s => ({
                  value: s,
                  label: parsers.getUnitText(
                    config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId) ? priceUnitTranslate(s) : s,
                    item.weightUnit,
                    0,
                  ),
                }))}
                onChange={(n, v) => {
                  onUpdateItem({
                    ...item,
                    priceUnit: v as PRODUCT_UNIT,
                  });
                }}
                width="100px"
              />
            </S.QuantityRow>
          ) : (
            <Input
              id={`price-${item.id}`}
              name={showServedColumn ? 'servedPrice' : 'price'}
              onBlur={(key, value, error) => updateItemPrice(item, key, value, error)}
              value={price || 0}
              minValue={currency.getPriceInputMinimumValue(pricePrecision)}
              variableTextSingular={currency.getPricePerUnit(
                item.currency,
                config.TOGGLE_NEW_SALES_UNITS.organizations.includes(order?.catalogId)
                  ? priceUnitTranslate(item.priceUnit)
                  : item.priceUnit,
                item.weightUnit,
              )}
              precision={pricePrecision}
              width="120px"
              type="number"
              textAlign="right"
              // autoFocus={index === 0}
              hasError={showServedColumn ? !item.servedPrice : !item.price}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </S.PriceEditRow>
      );
    },
  }),
};
