import { userSelectors } from 'common-services';
import * as React from 'react';
import { useSelector } from 'react-redux';

import config from '../../../../bindings/config';
import OrderDetails from '../OrderDetails';
import { default as OrderDetailsOld } from '../OrderDetailsOld';

import type { OrderDetailsProps } from '../OrderDetails';

const OrderDetailsSwitch: React.FC<OrderDetailsProps> = props => {
  const toggleRefactoredOrderDetails = useSelector(
    userSelectors.hasToggleEnabled(config.TOGGLE_REFACTORED_ORDER_DETAILS),
  );

  return toggleRefactoredOrderDetails ? <OrderDetails {...props} /> : <OrderDetailsOld {...props} />;
};

export default React.memo(OrderDetailsSwitch);
