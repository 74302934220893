import { __, date, DATE_TYPE, ORDER_STATUS, orderService, utils } from 'common-services';
import { addDays } from 'date-fns';
import * as React from 'react';

import { DatePicker } from '../../../../../../atoms';
import { useOrderContext } from '../../../../context';
import { CommentSectionType } from '../../../../hooks/useOrderComments';
import * as Common from '../../../commons.styled';

import * as S from './OrderInfo.styled';
import { Tag } from './Tag.component';

const OrderInfo: React.FC = () => {
  const {
    me,
    order,
    previewMode,
    buyer,
    seller,
    membersAsClient,
    weAreSeller,
    weAreAuthor,
    orderDateFixIssue,
    isBuyerOfferingView,
    noClientIssue,
    setShowClientsModal,
    commentsScroll: { navigateToSection },
  } = useOrderContext();

  const hourFormat = React.useMemo(() => me?.settings?.hourFormat, [me?.settings?.hourFormat]);

  const numComments = order?.totalComments + order?.totalAttachments;

  const statusLabel = order
    ? orderService.getOrderActionLiterals(order, weAreSeller, weAreAuthor, buyer?.name, seller?.name)
    : {
        color: '',
        short: '',
        action: '',
        ctaCard: '',
        ctaScreen: '',
        status: '',
      };

  const showStatusTag: boolean =
    order?.id !== undefined && order?.id !== 0 && !(previewMode && order?.status === ORDER_STATUS.DRAFT);

  const handleDateChange = React.useCallback(
    (newDate: string, issueId: number) => {
      orderDateFixIssue(me?.id, order?.id, newDate, issueId, false, DATE_TYPE.CREATED);
    },
    [me?.id, order?.id, orderDateFixIssue],
  );

  const handleShowClientsModal = React.useCallback(() => {
    setShowClientsModal(true);
  }, [setShowClientsModal]);

  // TODO:2 Extract to sub-component
  const renderCreatedAtSection = (): JSX.Element | null => {
    if (!order?.createdAt) return null;

    const dateIssue = order?.issues.find(issue => issue.type === 'date-not-valid' && issue.code === 'order_date');

    if (dateIssue) {
      return (
        <S.Text>
          {__('Components.Cart.creationDate')}
          <DatePicker
            id="creationDate"
            initDate={new Date(order?.createdAt)}
            onDateChange={d => handleDateChange(d, dateIssue.id)}
            customInput={
              <Common.IssueText>
                {__('Components.Cart.date_order_issue')} <Common.IssueIcon name="Down" />
              </Common.IssueText>
            }
          />
        </S.Text>
      );
    }

    return (
      <S.Text>
        {__('Components.Cart.creationDate')}
        <S.TextBlack>
          {date.formatCreationDate(order?.createdAt, { showHours: order?.showHours, hourFormat })}
        </S.TextBlack>
      </S.Text>
    );
  };

  const renderEditableUntil = (): JSX.Element | null => {
    if (!order?.servedFlowEnabled || previewMode) return null;

    let dateFormatted = __('Components.Cart.acceptedOrder.noLimitDate');

    if (order?.deliveryEstimatedAt) {
      const editableUntilDate = addDays(new Date(order?.deliveryEstimatedAt), 7).getTime();
      // if editableUntilDate is in the past, return null
      if (editableUntilDate < Date.now()) return null; // TODO:1 check if this is the correct behavior
      dateFormatted = utils.capitalizeWords(date.formatDate(editableUntilDate, date.FORMAT_DATE_FORMAT));
    }

    return (
      <S.Text>
        {__('Components.Cart.acceptedOrder.editableUntil')}
        <S.TextBlack>{dateFormatted}</S.TextBlack>
        <S.Tooltip
          position="right"
          text={__('Components.Cart.acceptedOrder.tooltip')}
          className="tooltip-add-line"
          themeMode="dark"
        >
          <S.InfoIcon name="Info" disableHover={true} />
        </S.Tooltip>
      </S.Text>
    );
  };

  return (
    <>
      <S.Row>
        <S.SectionTitle>{__('Components.Cart.info')}</S.SectionTitle>
      </S.Row>
      <S.Row>
        {/* TODO: improve this!! */}
        {isBuyerOfferingView && order.status === ORDER_STATUS.DRAFT ? (
          <>
            <S.CourseTag label={__('Components.OffersList.states.progress')} disabled={true} />
            <S.Tag label={__('Components.OffersList.viaOffers')} disabled={true} />
          </>
        ) : (
          <Tag showStatusTag={showStatusTag} statusLabel={statusLabel} order={order} weAreSeller={weAreSeller} />
        )}
      </S.Row>
      {renderCreatedAtSection()}
      {renderEditableUntil()}

      <S.Text>
        {__('Components.Cart.buyer')}
        {noClientIssue ? (
          <S.IssueText onClick={handleShowClientsModal}>{__('Components.Cart.errors.no_client_code')}</S.IssueText>
        ) : (
          <S.TextBlack>{buyer?.name ? `${buyer?.name} - ${buyer?.name}` : buyer?.name}</S.TextBlack>
        )}
        {membersAsClient?.length > 1 && (
          <S.EditLink id="edit_transport_button" onClick={handleShowClientsModal} name="Edit" />
        )}
      </S.Text>

      <S.Text>
        {__('Components.Cart.seller')}
        <S.TextBlack>{seller?.name}</S.TextBlack>
      </S.Text>

      {!previewMode && (
        <S.Shortcuts>
          <S.CommentsButton
            type="link"
            iconName="Comment"
            iconSize="16px"
            onClick={() => navigateToSection(CommentSectionType.Messages)}
          >
            {__('Components.Cart.numComments', { count: numComments })}
          </S.CommentsButton>
          {order?.totalUpdates > 0 && (
            <S.UpdatesButton
              type="link"
              iconName="Recent"
              iconSize="16px"
              onClick={navigateToSection(CommentSectionType.Activity)}
            >
              {__('Components.Cart.updates', { count: order?.totalUpdates })}
            </S.UpdatesButton>
          )}
        </S.Shortcuts>
      )}
    </>
  );
};

export default React.memo(OrderInfo);
