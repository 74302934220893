import { __, orderBuyerPreparationActions } from 'common-services';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { EmptyListResource } from '../../../molecules';
import OrderCard from '../../OrderPreparationCarts/OrderPreparationCarts.component';
import { OrderPreparationContext } from '../OrderPreparationContext';

import * as S from './OrderPreparationCard.styled';

import type { IPaginatedOrderBuyerPreparationCart, OrderPreparationLineFilters } from 'common-services';
import type { Dispatch } from 'redux';

interface IProps {
  workspaceId: number;
  deliveryDate: string;
  changeTab: () => void;
  searchState: OrderPreparationLineFilters;
}

const OrderPreparationCart: React.FC<IProps> = ({ workspaceId, deliveryDate, changeTab, searchState }) => {
  const dispatch = useDispatch<Dispatch<any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const { orderBuyerPreparationId } = React.useContext(OrderPreparationContext);
  const [cartLines, setCartLines] = React.useState<IPaginatedOrderBuyerPreparationCart>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const PAGE_SIZE = 8;

  React.useEffect(() => {
    if (workspaceId && orderBuyerPreparationId) {
      setCartLines(null);
      dispatch(
        orderBuyerPreparationActions.orderBuyerPreparationCartList(
          workspaceId,
          orderBuyerPreparationId,
          searchState,
          setCartLines,
          { page: 0, size: PAGE_SIZE },
        ),
      );
    }
  }, [workspaceId, orderBuyerPreparationId, searchState, dispatch]);

  const loadMore = React.useCallback(() => {
    if (!cartLines?.isLastPage && !isLoading) {
      setIsLoading(true);
      dispatch(
        orderBuyerPreparationActions.orderBuyerPreparationCartList(
          workspaceId,
          orderBuyerPreparationId,
          searchState,
          response => {
            setCartLines(prev => {
              const newElements = response.elements.filter(
                newEl =>
                  !prev.elements.some(
                    existingEl =>
                      existingEl.seller.id === newEl.seller.id &&
                      existingEl.buyerWarehouse.id === newEl.buyerWarehouse.id,
                  ),
              );

              return prev
                ? {
                    ...response,
                    elements: [...prev.elements, ...newElements],
                  }
                : response;
            });
            setIsLoading(false);
          },
          {
            page: (cartLines?.page ?? 0) + 1,
            size: PAGE_SIZE,
          },
        ),
      );
    }
  }, [cartLines, isLoading, workspaceId, orderBuyerPreparationId, searchState, dispatch]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      loadMore();
    }
  };

  if (!cartLines) {
    return null;
  }

  return (
    <S.Container ref={containerRef} onScroll={handleScroll}>
      {cartLines.elements.length > 0 &&
      cartLines.elements.some(cartData => cartData.lines.some(line => line.orderedQuantity > 0)) ? (
        cartLines.elements.map(cartData => (
          <S.ItemsPanel key={`${cartData.buyerWarehouse.id}-${cartData.seller.id}`} className="group-row">
            {cartData.lines.some(line => line.orderedQuantity > 0) && (
              <OrderCard workspaceId={workspaceId} cart={cartData} deliveryDate={deliveryDate} changeTab={changeTab} />
            )}
          </S.ItemsPanel>
        ))
      ) : (
        <S.CenterContainer>
          <EmptyListResource
            text={__('Components.OffersList.order_preparation.zero_case_title')}
            text2={__('Components.OffersList.order_preparation.zero_case_text')}
            imageUrl="https://media.consentio.co/image/upload/v1641830463/ilustrations/Wholesale.png"
            showButton={false}
          />
        </S.CenterContainer>
      )}
    </S.Container>
  );
};

export default OrderPreparationCart;
