import {
  __,
  EventTrack,
  orderActions,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  userSelectors,
} from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import config from '../../../../bindings/config';
import * as navActions from '../../../actions/nav';
import { CHANNEL_SECTIONS, ROUTE_PATHS } from '../../../constants';
import getPath from '../../../util/routes';
import { Ribbon } from '../../atoms';

import FormatsList from './FormatsList.component';
import * as S from './OrderMagicImport.styled';
import UploadOrders from './UploadOrders.components';

import type { IOrdersMagicImportProps, IStateOrdersMagicImport } from './TypesAndConstants';
import type { IReduxState } from '../../../reducers';
import type { Dispatch } from 'redux';

const OrdersMagicImport: React.FC<IOrdersMagicImportProps> = ({
  formats,
  customConf: propCustomConf,
  channelId,
  client,
  contact,
  setParentState,
}) => {
  const me = useSelector(userSelectors.getUser);
  const { contactId, clientId, workspaceId } = useParams<{
    contactId?: string;
    clientId?: string;
    workspaceId: string;
  }>();
  const targetId = contactId || clientId; // depending on the context, the targetId is either the contactId or the clientId
  const catalogs = useSelector((state: IReduxState) => state.catalog.catalogs);
  const catalogIdSelected = catalogs[workspaceId as unknown as number].id;
  const history = useHistory();
  const containerRef = React.useRef(null);
  const t = React.useRef(Date.now());
  const [state, setState] = React.useState<IStateOrdersMagicImport>({
    files: [],
    formatSelected: '',
    showAllFormats: true,
    text: '',
  });
  const dispatch = useDispatch<Dispatch<any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any
  const disabled = !sellerWorkspaceService.isActive(catalogs[catalogIdSelected], me.id);
  const importOrders = (): void => {
    const fromChat = window.location.pathname.includes('/contact/');
    dispatch(
      orderActions.importClientOrders(
        catalogIdSelected,
        +targetId,
        state.files,
        state.formatSelected,
        err => {
          if (err) console.error('could not upload file', err);
          setTimeout(() => setParentState({ tabSelected: 'list' }));
        },
        state.text,
        state.customConf,
      ),
    );
    if (fromChat) {
      history.push(
        getPath({
          path: ROUTE_PATHS.CONTACT_MAGIC_ORDERS,
          workspaceId: catalogIdSelected.toString(),
          contactId: targetId,
        }),
      );
    }
  };

  const updateClientInformation = (formatSelected: string) => error => {
    if (!error) {
      const myRole = catalogs[catalogIdSelected].members.find(member => member.userId === me.id).role;
      if (myRole === 'viewer') return;
      setState({ ...state, files: [], text: '' });
      const clients = client
        ? dispatch(
            sellerWorkspaceActions.clientUpdate(me.id, catalogIdSelected, {
              ...client,
              orderIncomingEmail: getEmailAddress(targetId),
              orderIncomingKind: formatSelected,
            }),
          )
        : dispatch(
            sellerWorkspaceActions.clientsAdd(me.id, catalogIdSelected, [
              {
                ...sellerWorkspaceService.contactToClient(contact, catalogIdSelected),
                orderIncomingEmail: getEmailAddress(contact.id),
                orderIncomingKind: formatSelected,
              },
            ]),
          );
      return clients;
    }
  };

  const handleSetFiles = (newFiles: Array<File>): void => {
    if (newFiles.length > 0) {
      setState({ ...state, files: [...state.files, ...newFiles] });
    }
  };

  const getEmailAddress = (contactId): string => {
    return client?.orderIncomingEmail || `${catalogIdSelected}_${contactId}@${config.EMAIL_DOMAIN_ADDRESS}`;
  };

  const setStateOrdersMagicImport = (newState: Partial<IStateOrdersMagicImport>, callback?: () => void): void => {
    setState(prevState => {
      const updatedState = { ...prevState, ...newState };
      if (callback) {
        callback();
      }
      return updatedState;
    });
  };

  React.useEffect(() => {
    RenderTrack.track('OrdersMagicImport', {
      renderTime: t.current,
      contact_id: contact?.id,
      show_all_formats: state.showAllFormats,
    });
    if (propCustomConf) {
      setState({ ...state, customConf: propCustomConf });
    }
    if (client) {
      setState({
        ...state,
        showAllFormats: client?.orderIncomingKind && client?.orderIncomingKind.length > 0 ? false : true,
        formatSelected: client.orderIncomingKind || '',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (state.formatSelected && state.files.length > 0) {
      EventTrack.track('import_orders_select_format', {
        contact_id: contact?.id,
        format: state.formatSelected,
      });
    }
  }, [contact?.id, state.files.length, state.formatSelected]);

  React.useEffect(() => {
    RenderTrack.track('OrdersMagicImport', {
      renderTime: Date.now(),
      contact_id: contact?.id,
      show_all_formats: state.showAllFormats,
    });
  }, [contact?.id, state.showAllFormats]);

  React.useEffect(() => {
    if (propCustomConf !== state.customConf) {
      setState({ ...state, customConf: propCustomConf });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propCustomConf]);

  return (
    <>
      {disabled && (
        <Ribbon
          actionText={__('Components.OrdersImport.inactive_ribbon_action')}
          onClickAction={() =>
            dispatch(
              navActions.navigateChannelBySection(
                CHANNEL_SECTIONS.INFORMATION,
                channelId,
                !!contact,
                p => history.push(getPath({ path: p as any, channelId })), // eslint-disable-line @typescript-eslint/no-explicit-any
              ),
            )
          }
          text={__('Components.OrdersImport.inactive_ribbon', {
            name: catalogs[catalogIdSelected]?.name,
          })}
          type="warning"
        />
      )}
      <S.FormContainer ref={containerRef}>
        <S.TextSubtitleWithMargin>{__('Components.OrdersImport.select_order_type')}</S.TextSubtitleWithMargin>
        {state.showAllFormats ? (
          <FormatsList
            contact={contact}
            container={containerRef}
            formats={formats}
            formatSelected={state.formatSelected}
            navigateToAskFormat={search => setParentState({ tabSelected: 'ask-format', search })}
            setParentState={setStateOrdersMagicImport}
            setGlobalState={setParentState}
            updateClientInformation={updateClientInformation}
          />
        ) : (
          <UploadOrders
            client={client}
            customConf={state.customConf}
            files={state.files}
            formats={formats.all}
            formatSelected={state.formatSelected}
            importOrders={importOrders}
            disabled={disabled}
            setFiles={handleSetFiles}
            parentState={state}
            setParentState={setStateOrdersMagicImport}
            text={state.text}
          />
        )}
      </S.FormContainer>
    </>
  );
};

export default React.memo(OrdersMagicImport);
