import { __ } from 'common-services';
import Carousel from 'nuka-carousel';
import * as React from 'react';

import { IMAGES } from '../../../../assets';
import { LettersAvatar } from '../../../atoms';

import * as S from './LoginLandingCarousel.styled';

interface ITestimonial {
  avatar: string;
  name: string;
  company: string;
  text: string;
}
const LoginLandingCarousel: React.FC = () => {
  const [testimonialToShow, setTestimonialToShow] = React.useState<number>();

  const subtitle = __('LoginLanding.new_title.subTitle');
  const desktopTestimonials: Array<ITestimonial> = [
    {
      name: 'Christophe Janson',
      avatar: IMAGES.login.picvertLogo,
      company: __('LoginLanding.new_title.company_1'),
      text: __('LoginLanding.new_title.testimony_1'),
    },
    {
      name: 'Steven Michel',
      avatar: IMAGES.login.leclercLogo,
      company: __('LoginLanding.new_title.company_2'),
      text: __('LoginLanding.new_title.testimony_2'),
    },
    {
      name: '',
      avatar: IMAGES.login.elpaisLogo,
      company: __('LoginLanding.new_title.company_3'),
      text: __('LoginLanding.new_title.testimony_3'),
    },
  ];

  return (
    <S.BodyContainer>
      <S.TitleContainer>
        <S.TitleLabelContainer>
          <S.TittleText color="#FFCE00">{`${__('LoginLanding.new_title.efficient')} `}</S.TittleText>
          <S.TittleText color="#FF8B00">{`${__('LoginLanding.new_title.reliable')} `}</S.TittleText>
          <S.TittleText color="#A3EA34">{__('LoginLanding.new_title.fresh')}</S.TittleText>
        </S.TitleLabelContainer>
        <S.TextSubtitle>{subtitle}</S.TextSubtitle>
      </S.TitleContainer>

      <S.Testimonials>
        <Carousel
          slideIndex={testimonialToShow}
          autoplay={true}
          withoutControls={false}
          afterSlide={slideIndex => setTestimonialToShow(slideIndex)}
          cellSpacing={12}
          autoplayInterval={6000}
          wrapAround={true}
          renderBottomCenterControls={({ slideCount, currentSlide, goToSlide }) => (
            <CustomPagingDots slideCount={slideCount} currentSlide={currentSlide} goToSlide={goToSlide} />
          )}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
          {desktopTestimonials.map(t => (
            <S.TestimonialCard key={t.name}>
              <S.QouteImage src={IMAGES.login.quotes} />
              <S.TestimonialText>{t.text}</S.TestimonialText>
              <S.TestimonialHeader>
                <LettersAvatar
                  type="comment"
                  avatarColor={{ text: '', background: '' }}
                  img={t.avatar}
                  text={t.name}
                  size={50}
                />
                <S.TestimonialTitle>
                  <S.Name>{t.name}</S.Name>
                  <S.Company>{t.company}</S.Company>
                </S.TestimonialTitle>
              </S.TestimonialHeader>
            </S.TestimonialCard>
          ))}
        </Carousel>
      </S.Testimonials>
    </S.BodyContainer>
  );
};

interface CustomPagingDotsProps {
  slideCount: number;
  currentSlide: number;
  goToSlide: (index: number) => void;
}

const CustomPagingDots: React.FC<CustomPagingDotsProps> = ({ slideCount, currentSlide, goToSlide }) => (
  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
    {Array.from({ length: slideCount }).map((_, index) => (
      <div
        key={`dot-${slideCount}-${Math.random()}`}
        onClick={() => goToSlide(index)}
        onKeyDown={e => {
          if (e.key === 'Enter') goToSlide(index);
        }}
        role="button"
        tabIndex={0}
        style={{
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          backgroundColor: currentSlide === index ? '#A3EA34' : '#FFF',
          cursor: 'pointer',
        }}
      />
    ))}
  </div>
);

export default LoginLandingCarousel;
